import Box from "../Box/Box";
import React, {FC} from "react";

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

const Card: FC<CardProps> = ({children, className}) => {
  return (
    <Box className={`rounded-lg border border-gray-300 bg-white shadow-md transition-all duration-300 ease-in-out hover:border-gray-400 hover:shadow-lg hover:bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-gray-500 active:bg-gray-200 ${className}`}>
      {children}
    </Box>
  );
};

export default Card;