export const calculateGrowthFOrRateFromServiceScore = (
  firstScore: number,
  lastScore: number
): number => {
  if (firstScore !== 0)
    return parseFloat(
      (((lastScore - firstScore) / firstScore) * 100).toFixed(3)
    );
  else return 0;
};
