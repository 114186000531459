import React, {FC, useState} from "react";
import {TrashIcon} from '@heroicons/react/solid'
import Rule from "../../../../models/scorecard/Rule";
import Button from "../../../octo-ui/Button/Button";
import Level from "../../../../models/scorecard/Level";
import EditLevelFormComponent from "../EditLevelForm/EditLevelFormComponent";

interface ScorecardComponentProps {
    rules: Rule[]
    levels : Level[],

    updateLevelDetailsCallBack(level : Level): void,

    creatNewLevelCallBack(level : Level): void,

    deleteLevelByIdCallBack(levelId : string): void
}

const ListOfScoreCardLevels: FC<ScorecardComponentProps> = ({
                                                               levels,
                                                               rules,
                                                               updateLevelDetailsCallBack,
                                                               creatNewLevelCallBack,
                                                               deleteLevelByIdCallBack
                                                           }) => {
    const [expandedRows, setExpandedRows] = useState<{ [levelName: string]: boolean }>({});
    const [editedLevel, setEditedLevel] = useState<Level | undefined>(undefined);
    const [isCreatingNewLevel, setIsCreatingNewLevel] = useState<boolean>(false);
    const [selectedRulesIds, setSelectedRulesIds] = useState<string[]>(levels.flatMap(level => level.ruleIds ?? []));

    const closeEditLevelForm = () => {
        setIsCreatingNewLevel(false);
        setEditedLevel(undefined);
    };
    const toggleLevelAccordion = (levelName: string, open?: boolean) => {
        const newState: { [levelName: string]: boolean } = {};
        newState[levelName] = open ?? !expandedRows[levelName];
        setExpandedRows({
            ...expandedRows,
            ...newState
        });
    };

    // const rulesCategoriesNames = Object.keys(rules);
    return (
      <div id="rulesList" className="space-y-8 ">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Levels</h3>
        <div className={"grid md:grid-cols-2 md:space-x-4"}>
          <div
            className={`shadow-lg rounded p-4 ${
              editedLevel !== undefined ? "md:col-span-1" : "md:col-span-2"
            }`}
          >
            <div id={"rules-by-category-list"} className="bg-white">
              {levels.length === 0 && (
                <p className={"text-center"}> No levels for the moment. </p>
              )}
              {levels.map((level, index) => (
                <div className="shadow  m-auto ">
                  <div
                    key={"accordion-collapse-" + index}
                    id={"accordion-collapse-" + index}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleLevelAccordion(level.name!);
                      ////****** here for editing the level */
                      e.stopPropagation();
                      setIsCreatingNewLevel(false);
                      setEditedLevel(level);
                    }}
                    data-accordion="collapse"
                  >
                    <h2>
                      <div className={`flex justify-between hover:bg-gray-100 ${expandedRows[level.name!] ? "bg-gray-100" : "bg-white"} `}>
                        <button
                          type="button"
                          className="flex items-start p-5 w-full font-medium text-left text-black capitalize rounded-t-xl "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className={`my-1 w-5 h-5 ${
                                !expandedRows[level.name!] ? "" : "rotate-90"
                              } shrink-0 hover:bg-gray-300`}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                            />
                          </svg>

                          <span className={"pl-1"}>{level.name!}</span>
                          <div>
                            <span className="inline-flex items-center mx-5 my-1 px-2 py-0.5 rounded-md text-xs font-medium bg-octo-200 text-gray-800">
                              {level.ruleIds?.length} rules
                            </span>
                          </div>
                        </button>
                        <div
                          className={
                            "flex justify-end rounded  m-4 w-1/4 h-1/2  overflow-hidden"
                          }
                        >
                          <Button
                            props={{
                              id: `${level.name}-delete-rule-${index}-button`,
                            }}
                            size="sm"
                            type="white"
                            variant="solid"
                            onClick={(e: any) => {
                              e.preventDefault();
                              deleteLevelByIdCallBack(level.id!);
                              setSelectedRulesIds([...selectedRulesIds.filter(ruleId => !level.ruleIds?.includes(ruleId))]);
                              e.stopPropagation();
                            }}
                          >
                            <TrashIcon className="text-red-500 h-5 w-5" />
                          </Button>
                        </div>
                      </div>
                    </h2>

                    <div
                      id={`${level.id}-rules-list`}
                      className={`${
                        !expandedRows[level.name!] ? "hidden" : ""
                      }`}
                    >
                      <ul className="space-y-4 ">
                        <div className="block  cursor-pointer">
                          <div className="flex justify-between px-4 sm:px-6 gap-4">
                            <div
                              id={`${level.name}-rules-list-item-${index}`}
                              className="w-3/4 flex-1 flex  flex-col"
                            >
                              <div className={"flex gap-5 items-center "}>
                                <p className="truncate my-2 text-sm text-gray-500">
                                  {level.description}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="">
                          <div className="py-2 bg-octo-100 border-l-4  border-l-octo-200 mx-4 px-2   ">
                            Rules
                          </div>

                          {level.ruleIds?.map((ruleId, index) => (
                            <li
                              className="m-auto sm:mx-6 border-b"
                              key={ruleId}
                            >
                              {/* <div className="block hover:bg-gray-50 cursor-pointer"> */}
                              <div className=" px-4 sm:px-6 gap-4 ">
                                <div
                                  id={`${ruleId}-list-item-${index}`}
                                  className="w-3/4 flex-1 flex  flex-col"
                                >
                                  <div className={"flex gap-5 items-center "}>
                                    <p className="text-sm font-medium text-octo-600 truncate">
                                      {
                                        rules.find((rule) => rule.id == ruleId)
                                          ?.name
                                      }
                                    </p>
                                    {/* <span
                                                                    className=" mx-2 my-1 px-2 py-0.5 rounded text-xs font-medium bg-octo-200 text-gray-800">{rule.score} points</span> */}
                                  </div>
                                  <div className={"flex gap-5 items-center "}>
                                    <p className="truncate mb-2 text-sm text-gray-500">
                                      {
                                        rules.find((rule) => rule.id == ruleId)
                                          ?.description
                                      }
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div
              id="add-new-level-button"
              className="flex justify-center"
              onClick={() => {
                const newLevel: Level = new Level();

                setIsCreatingNewLevel(true);
                setEditedLevel(newLevel);
              }}
            >
              {!isCreatingNewLevel && !editedLevel && (
                <button
                  type="button"
                  className="inline-flex items-center  my-5 px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Add new level
                </button>
              )}
            </div>
          </div>
          <div
            id="edit-rule-form-container"
            className={`shadow-lg rounded p-4 ${
              editedLevel !== undefined ? "md:col-span-1" : "hidden"
            }`}
          >
            {editedLevel !== undefined ? (
              <EditLevelFormComponent
                rules={rules}
                originalLevel={editedLevel}
                isNewLevel={isCreatingNewLevel}
                closeEditLevelComponentCallBack={closeEditLevelForm}
                selectedRulesIds={selectedRulesIds}
                setSelectedRulesIds = {setSelectedRulesIds}
                updateLevelDetailsCallBack={(level: Level) => {
                  updateLevelDetailsCallBack(level);
                  setEditedLevel(undefined);
                }}
                createNewLevelCallBack={(level: Level) => {
                  setIsCreatingNewLevel(false);
                  creatNewLevelCallBack(level);
                  setEditedLevel(undefined);
                }}
              ></EditLevelFormComponent>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    );
}

export default ListOfScoreCardLevels;