import { useEffect, useMemo, useState } from "react";
import ScoreCard from "../../../../models/scorecard/ScoreCard";
import Service from "../../../../models/service/Service";
import { useScoreCardLastEvaluations } from "../../../../hooks/evaluations/useScoreCardLastEvaluations";
import Select from "react-select";
import { LoaderIcon } from "react-hot-toast";
import ServicesByRulesMatrixSkeleton from "./ServicesByRulesMatrixSkeleton";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { Column, TableRow } from "../../../../components/octo-ui/Table/Table";
import Table from "../../../../components/octo-ui/Table/Table";


const getResultColor = (value: boolean) => {
  return value ? 'text-green-500 bg-green-200' : 'text-red-500 bg-red-200';
};


const getEvaluationValue = (ruleResult: {success: boolean, score: number}) => {
  return ruleResult.success
    ? ruleResult.score
    : 'Fail';
};

interface ServicesByRulesMatrixProps {
    scoreCards: ScoreCard[];
    services: Service[];
    isLoadingScoreCards: boolean;
    isLoadingServices: boolean;
}

const ServicesByRulesMatrix = ({
    scoreCards,
    services,
    isLoadingScoreCards,
    isLoadingServices
  }: ServicesByRulesMatrixProps) => {
    const [selectedScoreCard, setSelectedScoreCard] = useState<ScoreCard>();
    const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const handleExpandCollapse = () => {
      setIsExpanded(!isExpanded);
    };

    useEffect(() => {
      if (scoreCards?.length) {
        setSelectedScoreCard(scoreCards[0]);
      }
    }, [scoreCards]);
  
    const scoreCardOptions = scoreCards
      ? scoreCards.map((scoreCard) => ({
          value: scoreCard.id,
          label: scoreCard.name,
        }))
      : [];
  
    let { data: evaluations, isLoading: isLoadingEvaluations, refetch: refetchEvaluations } = useScoreCardLastEvaluations(selectedScoreCard?.id ?? '');

    const isLoading = isLoadingServices || isLoadingScoreCards || isLoadingEvaluations;

    useEffect(() => {
      if (selectedScoreCard) {
        refetchEvaluations().then(() => {
          if (!hasLoadedOnce) {
            setHasLoadedOnce(true);
          }
        });
      }
    }, [selectedScoreCard, refetchEvaluations]);

    const matrixData = useMemo(() => {
      const data: { [key: string]: { [key: string]: {success: boolean, score: number} } } = {};
      if (evaluations && services) {
        evaluations.forEach((evaluation) => {
          evaluation.evaluationResult.ruleEvaluationResults.forEach((ruleResult) => {
            const serviceName = services.find((service) => service.id === evaluation.serviceId)?.name ?? '';
            const ruleName = ruleResult.rule.name;
      
            if (ruleName && serviceName) {
              if (!data[serviceName]) {
                data[serviceName] = {};
              }
              
              data[serviceName][ruleName] = {
                success: ruleResult.success,
                score: ruleResult.rule.score ?? 0,
              };
            }
          });
        });
      }
      return data;
    }, [evaluations, services]);
    
  
    const serviceNames = useMemo(() => Object.keys(matrixData), [matrixData]);
    const ruleNames = useMemo(() => Array.from(new Set(serviceNames.flatMap((serviceName) => Object.keys(matrixData[serviceName])))), [matrixData, serviceNames]);
    

    const columns: Column[] = useMemo(() => [
      {
        title: 'Services/rules',
        dataIndex: 'serviceName',
        className: 'text-md text-center font-medium text-gray-700 sticky left-0 bg-gray-50 border-none'
      },
      ...ruleNames.map((ruleName) => ({
        title: ruleName,
        dataIndex: ruleName,
        className: '',
      }))
    ], [ruleNames]);
  
    const dataSource: TableRow[] = useMemo(() => serviceNames.map((serviceName) => {
      let data: {
        [key: string]: JSX.Element | string;
      } = {};
  
      data['serviceName'] = serviceName;
      ruleNames.forEach((ruleName) => {
        data[ruleName] = (
          <span className={`inline-block px-3 py-1 rounded-full font-bold bg-gray-500 ${getResultColor(matrixData[serviceName][ruleName]?.success)}`}>
            {getEvaluationValue(matrixData[serviceName][ruleName])}
          </span>
        );
      });
  
      return data;
    }), [serviceNames, ruleNames, matrixData]);
  

    const widget = (
      <div>
        <div className="flex p-5 justify-between items-center rounded">
          <div className="font-bold uppercase">Services by rules</div>
          <div className="font-light flex flex-col xl:flex-row gap-4 items-center">
            <div className="relative flex-grow">
              <Select
                value={scoreCardOptions.find(
                  (option) => option.value === selectedScoreCard?.id
                )}
                onChange={(option) => {
                  const newSelectedScoreCard = scoreCards?.find(
                    (scoreCard) => scoreCard.id === option?.value
                  );
                  setSelectedScoreCard(newSelectedScoreCard);
                }}
                options={scoreCardOptions}
              />
            </div>
          </div>
        </div>
        <hr />
        {isLoadingEvaluations ? (
          <div className="h-[300px] overflow-auto mt-2 text-gray-500 flex gap-2 justify-center items-center" >
            <LoaderIcon />
            Loading evaluations data...
          </div>
        ) : (
          <div className={`${isExpanded ? "overflow-auto" : "overflow-x-auto max-h-[400px] overflow-y-auto overflow-hidden"} mt-2`}>
            <Table columns={columns} dataSource={dataSource} />
          </div>
        )}
          <div className="flex justify-center mt-4">
            <button
              className="flex items-center px-4 py-2 bg-slate-300 text-white rounded-full transition-colors duration-300 ease-in-out hover:bg-slate-600"
              onClick={handleExpandCollapse}
            >
              {isExpanded ? (
                <>
                  <ChevronUpIcon className="h-5 w-5" />
                </>
              ) : (
                <>
                  <ChevronDownIcon className="h-5 w-5" />
                </>
              )}
            </button>
          </div>
      </div>
    )

    if (isLoading && !hasLoadedOnce) {
      return <ServicesByRulesMatrixSkeleton />;
    }
  
    return widget;

};
  
export default ServicesByRulesMatrix;

  
  


