import React from "react";
import Card from "../Card";

const TableSkeleton = () => {
  return (
    <Card className="rounded shadow-md">
    <div className="flex  divide-x divide-gray-200 animate-pulse">
      <div className="flex-grow p-4 w-1/5 bg-gray-100">
        <ul className=" space-y-4 text-left text-gray-900 items-center ">
          <li>
            <div className="h-6 bg-gray-300 w-16"></div>
          </li>
          <li><div className="h-6 bg-gray-300 w-16"></div></li>
          <li><div className="h-6 bg-gray-300 w-16"></div></li>
          <li><div className="h-6 bg-gray-300 w-16"></div></li>
          <li><div className="h-6 bg-gray-300 w-16"></div></li>
          <li><div className="h-6 bg-gray-300 w-16"></div></li>

          <li><div className="h-6 bg-gray-300 w-16"></div></li>
          <li><div className="h-6 bg-gray-300 w-16"></div></li>
        </ul>
      </div>
      <div className="flex-grow w-4/5">
        <div className="flex flex-col p-4">
          <div className="flex justify-between mb-4">
            <div className="justify-start">
            <div className="h-6 bg-gray-300 w-16"></div>
            </div>
            <div className="justify-end items-center">
              <div className="relative ">
              <div className="h-6 bg-gray-300 w-16"></div>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-100">
                  <tr>
                    <th scope="col" className="px-6 py-3 ">
                      <div className="h-6 bg-gray-300 w-14"></div>
                    </th>
                    <th scope="col" className="px-6 py-3 ">
                      <div className="h-6 bg-gray-300 w-14"></div>
                    </th>
                    <th scope="col" className="px-6 py-3 ">
                      <div className="h-6 bg-gray-300 w-14"></div>
                    </th>
                    <th scope="col" className="px-6 py-3 ">
                      <div className="h-6 bg-gray-300 w-14"></div>
                    </th>
                    <th scope="col" className="px-6 py-3 ">
                      <div className="h-6 bg-gray-300 w-14"></div>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  <tr className="bg-gray-100">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="h-6 bg-gray-300 w-14"></div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="h-6 bg-gray-300 w-14"></div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="h-6 bg-gray-300 w-14"></div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="rounded-full bg-slate-300 h-10 w-10"></div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="rounded-full bg-slate-300 h-10 w-10"></div>
                    </td>
                  </tr>
                  <tr className="bg-gray-100">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="h-6 bg-gray-300 w-14"></div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="h-6 bg-gray-300 w-14"></div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="h-6 bg-gray-300 w-14"></div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="rounded-full bg-slate-300 h-10 w-10"></div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="rounded-full bg-slate-300 h-10 w-10"></div>
                    </td>
                  </tr>
                  <tr className="bg-gray-100">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="h-6 bg-gray-300 w-14"></div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="h-6 bg-gray-300 w-14"></div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="h-6 bg-gray-300 w-14"></div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="rounded-full bg-slate-300 h-10 w-10"></div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="rounded-full bg-slate-300 h-10 w-10"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Card>
  );
};

export default TableSkeleton;
