import {useQuery} from "react-query";
import { getAllOwnersServicesScorefirstAndLastByTimeRange } from "../../services/services-scores";
import { useAxios } from "../useAxios";
import OwnerServicesScores from "../../models/dashborad/OwnerServicesScores";


export const SERVICES_SCORES_QUERY = "owners_growth_decline_services_scores";

export const useOwnerServicesScoresImporovedDropedDashboard = (timeRange:string,scorecardId:string) => {
    const {axios} = useAxios();
    const query= useQuery<OwnerServicesScores[], Error>([SERVICES_SCORES_QUERY], () => getAllOwnersServicesScorefirstAndLastByTimeRange(axios,timeRange,scorecardId));
    const { refetch } = query;

  return { ...query, refetch };
}