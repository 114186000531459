import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {PrivateRoute} from "./PrivateRoute";
import {ADMIN_BASE_PATH, ADMIN_DASHBOARD_PAGE, ADMIN_SERVICES_PAGE, HOME_BASE_PATH, LOGIN_BASE_PATH} from "./paths";
import {ADMIN_ROUTES, APP_ROUTES, RouteType} from "./routes";
import {HomePage} from "../pages/home";
import {NotFoundPage} from "../pages/404";

const renderRoutes = (routes: RouteType[]) => {
    return routes.map((route, index) => {
        const Component = route.component;
        if (!route.isPrivate) {
            return (
                <Route
                    key={index}
                    path={route.path}
                    element={<Component/>}
                />
            )
        }
        return (
            <Route
                key={`${route.path}-${index}`}
                path={route.path}
                element={
                    <PrivateRoute configLayout={route.configLayout} title={route.name} component={<Component/>} roleHandler={route.role}/>
                }
            />
        );
    });
};

export const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={HOME_BASE_PATH}>
                    {renderRoutes(APP_ROUTES)}
                    <Route path={ADMIN_BASE_PATH}>
                        <Route index element={<Navigate replace to={ADMIN_DASHBOARD_PAGE}/>}/>
                        {renderRoutes(ADMIN_ROUTES)}
                    </Route>
                </Route>
                <Route path={LOGIN_BASE_PATH} element={<HomePage/>}/>
                <Route path="*" element={<NotFoundPage />}/>
            </Routes>
        </BrowserRouter>
    );
};