import React from "react";
import ScorecardComponent from "../../../components/scorecard/ScorecardComponent";
import {useScoreCards} from "../../../hooks/scorcards/useScoreCards";
import Box from "../../../components/octo-ui/Box/Box";
import Button from "../../../components/octo-ui/Button/Button";
import {PlusIcon} from "@heroicons/react/solid";
import {useNavigate} from "react-router-dom";
import {ADMIN_CREATE_NEW_SCORECARD_PAGE} from "../../../routes/paths";
import {LoadingPage} from "../../loading";

export const AdminScoreCardPage = () => {
    const {data, isSuccess, isLoading, isLoadingError} = useScoreCards();
    const navigate = useNavigate();
    return (
        <>
            <header className="bg-white shadow">
                <Box className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">


                    <Box className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8  flex justify-between items-center">
                        <h1 className="text-3xl font-bold text-gray-700">Score templates</h1>
                        <Button size="sm" type="primary" variant="solid" props={{
                            id: "create-new-scorecard-button"
                        }}
                                LeftIcon={<PlusIcon/>}
                                onClick={async (e: any) => {
                                    e.preventDefault();
                                    navigate(ADMIN_CREATE_NEW_SCORECARD_PAGE);
                                }}
                        >
                            Create a new ScoreCard
                        </Button>
                    </Box>

                </Box>
            </header>
            <main>
                {
                    isLoading &&
                    (<LoadingPage/>)
                }
                {
                    isLoadingError &&
                    (<p id={"loading-error-message"} className="text-center text-gray-500">
                        Something went wrong...
                    </p>)
                }
                {isSuccess &&
                    (<div id={"scorecards-list"}>
                        <Box className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                            <Box className="grid p-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                                {data.map((scorecard) => (
                                    <ScorecardComponent key={scorecard.id} scorecard={scorecard}/>
                                ))}
                            </Box>
                        </Box>
                    </div>)
                }
            </main>
        </>
    );
};
