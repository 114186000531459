import React from "react";
import {ADMIN_DASHBOARD_PAGE, ADMIN_SERVICES_PAGE} from "../../routes/paths";
import {Navigate} from "react-router-dom";
import {useKeycloak} from "@react-keycloak/web";
import Navbar from "../../components/Landing/navbar/NavBar";
import Logo from "../../components/octo-ui/Brand/images/sredx.svg";

export const HomePage = () => {
    const {keycloak} = useKeycloak();
    const {authenticated, login} = keycloak;
    if (authenticated) {
        return <Navigate to={ADMIN_DASHBOARD_PAGE}/>
    }
    return (
        <>
            <Navbar transparent login={login}/>
            <main>
                <div className="relative flex items-center justify-center bg-cover"
                     style={{
                         minHeight: "75vh",
                         backgroundImage: "url('/images/gradient-color-image.png')",
                     }}>
                    <div className="container px-6">
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-1/2">
                                <div className="text-center lg:text-left">
                                    <h1 className="text-white font-semibold text-5xl">
                                        Turn tribal knowledge into a shared joyful context.
                                    </h1>
                                    <p className="mt-4 text-lg text-gray-300">
                                        SREDX makes it easy for engineering organizations to gain visibility into their
                                        services and deliver high quality software.
                                    </p>
                                </div>
                            </div>
                            <div className="w-full lg:w-1/2 h-full px-auto">
                                <div className="relative flex justify-center items-center mt-10 h-full md:mt-16 lg:mt-0">
                                    <img className="relative w-2/5 h-auto xl:left-12" src={Logo} alt="SREDX"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
                        style={{height: "70px"}}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-gray-300 fill-current"
                                points="2560 0 2560 100 0 100"
                            ></polygon>
                        </svg>
                    </div>
                </div>

                <section className="pb-20 bg-gray-300 -mt-24">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-wrap">
                            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                                <div
                                    className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div
                                            className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                                            <i className="fas fa-clipboard-check"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Scorecards</h6>
                                        <p className="mt-2 mb-4 text-gray-600">
                                            Scorecards enable teams to drive what matters most to them including
                                            service quality, production readiness standards, and migrations.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full md:w-4/12 px-4 text-center">
                                <div
                                    className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div
                                            className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                                            <i className="fas fa-book-reader"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">
                                            Service catalog
                                        </h6>
                                        <p className="mt-2 mb-4 text-gray-600">
                                            Service catalog integrates with the most popular engineering tools, giving
                                            teams an easy way to understand everything about their architecture.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                                <div
                                    className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div
                                            className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                                            <i className="fas fa-th-list"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">
                                            Gamification
                                        </h6>
                                        <p className="mt-2 mb-4 text-gray-600">
                                            Helps teams innovate in a joyful environment of gamification and tracking
                                            enabling organizations to drive a sense of ownership and pride as they
                                            improve service quality!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};