import { StarIcon } from "@heroicons/react/solid"; // Assuming this is the location of StarIcon
import { getOrdinalSuffixOfPosition } from "../../../utils/formating";

interface RankingDisplayProps {
  position: number;
}

const RankingDisplay: React.FC<
  RankingDisplayProps
> = ({ position }) => {
  let positionDisplay;
  switch (position) {
    case 1:
      positionDisplay = (
        <div className="rounded bg-yellow-200 w-10 h-10 p-2 text-yellow-700 inline-flex items-center justify-center" data-testid="star-icon-container-1">
          <StarIcon aria-label="star icon" className="h-6 w-6" />
        </div>
      );
      break;
    case 2:
      positionDisplay = (
        <div className="rounded bg-gray-200 w-10 h-10 p-2 text-gray-700 inline-flex items-center justify-center" data-testid="star-icon-container-2">
          <StarIcon aria-label="star icon" className="h-6 w-6" />
        </div>
      );
      break;
    case 3:
      positionDisplay = (
        <div className="rounded bg-gray-300 w-10 h-10 p-2 text-gray-700 inline-flex items-center justify-center" data-testid="star-icon-container-3">
          <StarIcon aria-label="star icon" className="h-6 w-6" />
        </div>
      );
      break;
    default:
      positionDisplay = (
        <div className="rounded bg-blue-100 w-10 h-10 p-2 text-blue-700 inline-flex items-center justify-center text-sm font-semibold" data-testid="ordinal-number-container">
          {getOrdinalSuffixOfPosition(position)}
        </div>
      );
  }

  return positionDisplay;
};

export default RankingDisplay;

