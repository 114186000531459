import PropTypes from 'prop-types';
import {Navigate, useLocation} from 'react-router-dom';
import {LOGIN_BASE_PATH, UNAUTHORIZED_BASE_PATH} from './paths';
import {FC} from "react";
import {AdminAppLayout} from "../components/Layout/admin/AdminAppLayout";
import {useKeycloak} from "@react-keycloak/web";

interface Props {
    component: PropTypes.ReactElementLike,
    roleHandler?: Function,
    title?: string;
    configLayout?: boolean
}

export const PrivateRoute: FC<Props> = ({component: Component, title, roleHandler, configLayout}) => {
    const {keycloak, initialized} = useKeycloak();
    const {authenticated, realmAccess} = keycloak;
    const roles = realmAccess?.roles;

    const location = useLocation();

    if(!initialized) {
        return <div>Loading...</div>;
    }

    if (!authenticated) {
        return (
            <Navigate
                to={{pathname: LOGIN_BASE_PATH}} state={{from: location}}
                replace={true}
            />
        );
    }

    if (!roleHandler || !roleHandler(roles)) {
        return (
            <Navigate
                to={{pathname: UNAUTHORIZED_BASE_PATH}} state={{from: location}}
                replace={true}
            />
        );
    }

    return (
        <AdminAppLayout configLayout={configLayout} title={title}>
            {Component}
        </AdminAppLayout>
    );
};
