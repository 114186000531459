import {useMutation, useQueryClient} from "react-query";
import ScoreCard from "../../models/scorecard/ScoreCard";
import {updateScoreCard} from "../../services/scorecards/ScoreCards.service";
import {useAxios} from "../useAxios";
import {SCORE_CARDS_QUERY} from "./useScoreCards";

export const useUpdateScoreCard = () => {

    const queryClient = useQueryClient();
    const {axios} = useAxios();
    return useMutation(async (scoreCard: ScoreCard) => {
        return await updateScoreCard(axios, scoreCard);
    }, {
        onSuccess: async (newScoreCard: ScoreCard) => {
            await Promise
                .all([
                    queryClient.setQueryData([SCORE_CARDS_QUERY, newScoreCard.id], newScoreCard),
                    queryClient.invalidateQueries([SCORE_CARDS_QUERY])
                ]);
        },
        onError: (error) => {
            //TODO handle errors
            console.log(error);
        }
    });
}