import Service from "../models/service/Service";
import {AxiosInstance} from "axios";

export const getAllServices = async (axios: AxiosInstance): Promise<Service[]> => {
    const response = await axios.get<Service[]>("/services");
    return response.data;
};

export const getServiceById = async (axios: AxiosInstance, id: string): Promise<Service | undefined> => {
    const response = await axios.get<Service>(`/services/${id}`);
    return response.data;
};