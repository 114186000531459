import Badge from "../../octo-ui/Badge/Badge";
import Box from "../../octo-ui/Box/Box";
import React, {FC} from "react";
import Owner from "../../../models/service/Owner";

interface ServiceOwnersComponentProps {
    owners: Owner[];
    all?: boolean;
    limit?: number;
}

const ServiceOwnersComponent: FC<ServiceOwnersComponentProps> = ({owners, all, limit}) => {
    const toShow = limit ?? 2;
    if (!all && owners.length > toShow) {
        const rest = owners.length - toShow;
        owners = owners.slice(0, toShow);

        owners.push({
            id: "...",
            name: `+ ${rest} more`,
        })
    }

    return (
        <Box className="p-1 flex-1">
            {owners.map((owner) => (
                <Badge key={owner.id} size="large" team>
                    {owner.name}
                </Badge>
            ))}
        </Box>
    );
};

export default ServiceOwnersComponent;