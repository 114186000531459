import {FC, Fragment} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {ExclamationIcon} from "@heroicons/react/solid";


interface AlertComponentProps {
    isOpen: boolean,
    setIsOpen: Function,
    title: string,
    message: string,
    callBack?: Function,
    onCancel?: Function,
    confirmText?: string,
    closeText?: string
}

const ConfirmModalComponent: FC<AlertComponentProps> = ({callBack, onCancel, confirmText, closeText, isOpen, setIsOpen, title, message}) => {


    function closeModal() {
        setIsOpen(false)
    }

    function handleCancel() {
        if (onCancel) {
            onCancel();
        }
        closeModal();
    }

    return (
        <Transition.Root appear show={isOpen} as={Fragment} >
            <Dialog as="div" data-testid={"confirm-modal-component"} id={"confirm-modal-component"} className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div
                                            className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
                                        </div>
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <Dialog.Title as="h3"
                                                          className="text-lg leading-6 font-medium text-gray-900">
                                                {title}
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    {message}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    {
                                        callBack && (
                                            <button
                                                type="button"
                                                data-testid={"confirm-error-alert-button"}
                                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-800 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                                onClick={async () => {
                                                    closeModal();
                                                    await callBack();
                                                }}
                                            >
                                                {confirmText ?? "Confirm"}
                                            </button>)
                                    }
                                    <button
                                        type="button"
                                        data-testid={"close-error-alert-button"}
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-200 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={handleCancel}
                                    >
                                        {closeText ?? "Cancel"}
                                    </button>


                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ConfirmModalComponent;