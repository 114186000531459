import {ScoreCardFormComponent} from "../../../components/scorecard/ScoreCardForm/ScoreCardFormComponent/ScoreCardFormComponent";
import Box from "../../../components/octo-ui/Box/Box";
import React from "react";

export const CreateNewScoreCardPage = () => {
    return (

        <>
            <header className="bg-white shadow">
                <Box className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8  flex justify-between items-center">
                    <h1 className="text-3xl font-bold text-gray-700">Create a new ScoreCard</h1>
                </Box>
            </header>
            <main>
                <ScoreCardFormComponent/>
            </main>
        </>
    );
}