import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSingleScoreCard } from "../../../hooks/scorcards/useSingleScoreCard";
import {
  generatePath,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import Box from "../../../components/octo-ui/Box/Box";
import Button from "../../../components/octo-ui/Button/Button";
import { useEvaluateScoreCard } from "../../../hooks/scorcards/useEvaluateScoreCard";
import {
  ADMIN_EDIT_SINGLE_SCORECARD_PAGE,
  NOT_FOUND_PATH,
} from "../../../routes/paths";
import { LoadingPage } from "../../loading";
import ConfirmModalComponent from "../../../components/basic/ConfirmModalComponent/ConfirmModalComponent";
import { BeakerIcon, PencilIcon } from "@heroicons/react/outline";
import ScoreCardInfosComponent from "../../../components/scorecard/ScoreCardDetails/ScoreCardInfosComponent";
import ScoreCardOverviewComponent from "../../../components/scorecard/ScoreCardDetails/ScoreCardOverviewComponent";
import ScoreCardServicesListComponent from "../../../components/scorecard/ScoreCardDetails/ScoreCardServicesList/ScoreCardServicesListComponent";
import { RefreshIcon } from "@heroicons/react/solid";
import { getScoreCardOverviewFromEvaluationsHistory } from "../../../services/evaluations/ScoreCardEvaluations.helper";
import { useScoreCardLastEvaluations } from "../../../hooks/evaluations/useScoreCardLastEvaluations";
import { v4 as uuidv4 } from "uuid";
import { useStompWebSocket } from "../../../hooks/useStompWebsocket";
import ProgressTooltipComponent from "../../../components/basic/ProgressTooltipComponent/ProgressTooltipComponent";
import { ProgressInfo } from "../../../types/ProgressInfo";

export const ScoreCardDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const webSocketConnectionId = useMemo(() => uuidv4(), []);

  const { websocketData: progressInfo, connect: connectProgressInfo, disconnect: disconnectProgressInfo } = useStompWebSocket<ProgressInfo>(
    `/queue/evaluation-progress/${webSocketConnectionId}`
  );

  const [showProgressTooltip, setShowProgressTooltip] = useState(false);

  const [showConfirmEvaluateModal, setShowConfirmEvaluateModal] =
    useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const {
    data: scorecard,
    isSuccess,
    isLoading: isLoadingScoreCard,
  } = useSingleScoreCard(id!);
  const { mutateAsync: evaluateScoreCard, isLoading: isEvaluating } =
    useEvaluateScoreCard(id!, webSocketConnectionId);
  const {
    data: scorecardLastEvaluation,
    isSuccess: isLoadingScorecardLastEvaluationSuccessful,
    isLoading: isLoadingScorecardLastEvaluation,
  } = useScoreCardLastEvaluations(id!);

  const handleEvaluateScoreCard = async () => {
    setShowProgressTooltip(true);
    await evaluateScoreCard();
    disconnectProgressInfo();
    setShowProgressTooltip(false);
  };


  const handleCancelEvaluation = useCallback(() => {
    disconnectProgressInfo();
  }, [disconnectProgressInfo]);

  useEffect(() => {
    if (!isEvaluating && !shouldRefresh) {
      setShouldRefresh(true);
      setTimeout(() => {
        setShouldRefresh(false);
      }, 100);
    }
  }, [isEvaluating]);

  if (!id) {
    return <Navigate to={NOT_FOUND_PATH} replace={true} />;
  }

  if (isLoadingScoreCard) {
    return <LoadingPage />;
  }

  if (isSuccess) {
    if (!scorecard) {
      return <Navigate to={NOT_FOUND_PATH} replace={true} />;
    }
    return (
      <>
        <header className="bg-white shadow">
          <Box className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex flex-col sm:flex-row justify-between items-center">
            <h1 className="text-3xl font-bold text-gray-700">
              {scorecard.name}
            </h1>
            <div
              id={"details-page-buttons"}
              className={
                "flex flex-col-reverse sm:flex-row items-center gap-2 px-2 md:px-0"
              }
            >
              <ProgressTooltipComponent
                progressInfo={progressInfo}
                showProgressTooltip={showProgressTooltip}
              >
                <Button
                  size="sm"
                  type="white"
                  variant="solid"
                  data-testid="evaluate-scorecard-button"
                  props={{
                    id: "evaluate-scorecard-button",
                  }}
                  LeftIcon={<BeakerIcon />}
                  isLoading={isEvaluating}
                  onClick={async (e: any) => {
                    e.preventDefault();
                    setShowConfirmEvaluateModal(true);
                    connectProgressInfo();
                  }}
                >
                  Evaluate
                </Button>
              </ProgressTooltipComponent>

              <Button
                size="sm"
                type="white"
                variant="solid"
                data-testid="edit-scorecard-button"
                props={{
                  id: "edit-scorecard-button",
                }}
                LeftIcon={<PencilIcon className={"stroke-red-400"} />}
                isLoading={false}
                onClick={async (e: any) => {
                  e.preventDefault();
                  navigate(
                    generatePath(ADMIN_EDIT_SINGLE_SCORECARD_PAGE, {
                      id: scorecard.id,
                    })
                  );
                }}
              >
                <span className={"font-bold text-red-400"}>Edit</span>
              </Button>
            </div>
          </Box>
        </header>
        <main>
          <Box className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <div className="bg-white overflow-hidden space-y-8 shadow rounded-lg divide-y divide-gray-200 ">
              <ScoreCardInfosComponent scorecard={scorecard} />
              {isLoadingScorecardLastEvaluation && (
                <RefreshIcon
                  id={"loading-last-evaluation-spinner"}
                  className={"animate-spin h-5 w-5 mx-auto"}
                />
              )}
              {isLoadingScorecardLastEvaluationSuccessful && !shouldRefresh && (
                <>
                  <ScoreCardOverviewComponent
                    overviewDetails={getScoreCardOverviewFromEvaluationsHistory(
                      scorecardLastEvaluation
                    )}
                  />
                  <ScoreCardServicesListComponent
                    scoreCardId={id}
                    scoreCardLastEvaluations={scorecardLastEvaluation}
                  />
                </>
              )}
            </div>
          </Box>
        </main>

        <ConfirmModalComponent
          title={"Are you sure?"}
          message={"This action might take time."}
          callBack={handleEvaluateScoreCard}
          onCancel={handleCancelEvaluation}
          isOpen={showConfirmEvaluateModal}
          setIsOpen={setShowConfirmEvaluateModal}
        />
      </>
    );
  } else {
    return <Navigate to={NOT_FOUND_PATH} replace={true} />;
  }
};
