import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Box from "../../../components/octo-ui/Box/Box";
import PositionComponent from "../../../components/leaderboard/position-component/PositionComponent";
import { useServicesScores } from "../../../hooks/useServicesScores";
import { LoadingPage } from "../../loading";
import emptyCanvasSvg from "../../../assets/svg/blank_canvas.svg";
import ServiceScores from "../../../models/leaderboard/ServiceScores";
import Service from "../../../models/service/Service";
import ScoreCard from "../../../models/scorecard/ScoreCard";
import ScorecardsFilterComponent from "./modules/ScorecardsFilterComponent";
import { useStompWebSocket } from "../../../hooks/useStompWebsocket";
import { v4 as uuidv4 } from "uuid";
import { CurrentScoreCard, ProgressInfo } from "../../../types/ProgressInfo";
import Button from "../../../components/octo-ui/Button/Button";
import { BeakerIcon } from "@heroicons/react/outline";
import { useEvaluateAllScoreCards } from "../../../hooks/scorcards/useEvaluateAllScoreCards";
import ConfirmModalComponent from "../../../components/basic/ConfirmModalComponent/ConfirmModalComponent";
import SidebarComponent from "../../../components/basic/SidebarComponent/SidebarComponent";
import SidebarEvaluationContentComponent from "../../../components/basic/SidebarEvaluationContent/SidebarEvaluationContentComponent";
import useAutoScroll from "../../../hooks/useAutoScroll";

interface ServiceRank {
  service: Service;
  points: number;
  percentage: number;
}

const LeaderBoardPage = () => {
  const { isLoading, isSuccess, data } = useServicesScores();
  const [selectedScorecardIds, setSelectedScorecardIds] = React.useState<
    string[]
  >([]);

  const webSocketConnectionId = useMemo(() => uuidv4(), []);
  const { websocketData: progressInfo, connect: connectProgressInfo, disconnect: disconnectProgressInfo } = useStompWebSocket<ProgressInfo>(`/queue/evaluation-progress/${webSocketConnectionId}`);

  const { websocketData: currentScoreCard, connect: connectCurrentScoreCard, disconnect: disconnectCurrentScoreCard } = useStompWebSocket<CurrentScoreCard>(`/queue/evaluation-progress/current-scorecard/${webSocketConnectionId}`);
  

  const [currentScorecardProgress, setCurrentScorecardProgress] = useState<{
    [scorecardName: string]: ProgressInfo;
  }>({});

  useEffect(() => {
    if (progressInfo && currentScoreCard) {
      setCurrentScorecardProgress((prevState) => ({
        ...prevState,
        [currentScoreCard.scorecardName]: progressInfo,
      }));
    }
  }, [progressInfo, currentScoreCard]);

  const scrollContainer = useRef<HTMLDivElement>(null);

  useAutoScroll(scrollContainer, currentScorecardProgress);

  const { mutateAsync: evaluateAllScoreCards, isLoading: isEvaluating } =
    useEvaluateAllScoreCards(webSocketConnectionId);

  const [evaluationStarted, setEvaluationStarted] = useState(false);

  const handleEvaluateAllScoreCards = useCallback(async () => {
    setCurrentScorecardProgress({});
    setEvaluationStarted(true);
    await evaluateAllScoreCards();
    disconnectCurrentScoreCard();
    disconnectProgressInfo();
  }, [evaluateAllScoreCards]);

  const [showConfirmEvaluateModal, setShowConfirmEvaluateModal] =
    useState(false);

  const handleSelectedScorecards = (scorecards: ScoreCard[] | undefined) => {
    if (!!scorecards) {
      setSelectedScorecardIds(scorecards.map((scorecard) => scorecard.id));
    } else {
      setSelectedScorecardIds([]);
    }
  };

  const handleCancelEvaluation = useCallback(() => {
    disconnectCurrentScoreCard();
    disconnectProgressInfo();
  }, [disconnectCurrentScoreCard, disconnectProgressInfo]);


  const generateServicesRanks = (
    servicesScores: ServiceScores[],
    scorecardIds: string[]
  ): ServiceRank[] => {
    const servicesRanks: ServiceRank[] = [];

    servicesScores.forEach((serviceScore) => {
      const service = serviceScore.service;
      const scores = serviceScore.scores.filter(
        (score) =>
          scorecardIds.length === 0 || scorecardIds.includes(score.scorecardId)
      );
      const points = scores.reduce((acc, score) => acc + score.score, 0);
      const percentage = Math.round(
        (100 * points) /
          scores.reduce((acc, score) => acc + score.totalPossible, 0)
      );
      servicesRanks.push({ service, points, percentage });
    });

    // Sort by points and give appropriate rank
    servicesRanks.sort((a, b) => b.points - a.points);

    return servicesRanks;
  };

  const ranks = useMemo(
    () => generateServicesRanks(data ?? [], selectedScorecardIds),
    [data, selectedScorecardIds]
  );

  if (isLoading) return <LoadingPage />;

  let view;

  if (isSuccess) {
    if (!data || data.length === 0) {
      view = (
        <Box className="flex flex-col justify-center items-center w-full">
          <img className="w-1/6" src={emptyCanvasSvg} alt="Empty" />
          <p className="text-center text-gray-500 text-2xl">
            No scores available for the moment
          </p>
        </Box>
      );
    } else {
      view = (
        <div className="h-full w-full">
          <div className="w-auto mx-auto lg:w-2/3 m-4 z-10">
            <ScorecardsFilterComponent onChange={handleSelectedScorecards} />
          </div>
          <div className="w-auto mx-auto lg:w-2/3 m-4 bg-gray-100 rounded shadow-lg text-l sm:text-xl text-center block py-5">
            <Box className="flex h-16">
              <Box className="w-1/4 my-auto font-bold text-sm">Position</Box>
              <div className="flex flex-col items-center w-1/2 my-auto text-sm">
                Service name
              </div>
              <p className="w-1/4 my-auto text-sm">Score</p>
            </Box>
            {ranks.map((rank, index) => (
              <PositionComponent
                key={index}
                position={index + 1}
                service={rank.service}
                score={rank.points}
                percentage={rank.percentage}
              />
            ))}
          </div>
        </div>
      );
    }
  } else {
    view = <p className="text-center text-gray-500">Something went wrong...</p>;
  }

  return (
    <>
      <header className="bg-white shadow">
        <Box className="max-w-7xl flex justify-between mx-auto py-4 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-700">Leaderboard</h1>
          <Button
            size="sm"
            type="white"
            variant="solid"
            props={{
              id: "evaluate-scorecard-button",
            }}
            LeftIcon={<BeakerIcon />}
            isLoading={isEvaluating}
            onClick={async (e: any) => {
              e.preventDefault();
              setShowConfirmEvaluateModal(true);
              connectCurrentScoreCard();
              connectProgressInfo();
            }}
          >
            Evaluate All
          </Button>
        </Box>
      </header>
      <main>
        <Box className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="overflow-x-hidden">
            {evaluationStarted && (
              <SidebarComponent
                hidden={!evaluationStarted}
                className="animate-slideIn"
                onClose={() => setEvaluationStarted(false)}
                scrollContainer={scrollContainer}
                title="Evaluation"
              >
                <SidebarEvaluationContentComponent
                  currentScorecardProgress={currentScorecardProgress}
                />
              </SidebarComponent>
            )}
          </div>
          <div className="overflow-x-hidden">{view}</div>
        </Box>
    </main>


      <ConfirmModalComponent
        title={"Are you sure?"}
        message={"This action might take time."}
        callBack={handleEvaluateAllScoreCards}
        isOpen={showConfirmEvaluateModal}
        setIsOpen={setShowConfirmEvaluateModal}
        onCancel={handleCancelEvaluation}
      />
    </>
  );
};

export default LeaderBoardPage;
