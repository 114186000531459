import {useQuery} from "react-query";
import Service from "../models/service/Service";
import {getAllServices} from "../services/services";
import {useAxios} from "./useAxios";

export const SERVICES_QUERY = "services";

export const useServices = () => {
    const {axios} = useAxios();
    return useQuery<Service[], Error>([SERVICES_QUERY], () => getAllServices(axios));
}