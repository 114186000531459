import markWhiteLogo from './images/sredx_mark_white.svg'
import markPrimaryLogo from './images/sredx.svg'
import defaultPrimaryLogo from './images/octo_mark_primary.svg'
import defaultWhiteLogo from './images/octo_mark_white.svg'
import PropTypes from "prop-types";
import clsx from 'clsx';
import {FC} from "react";

const getLogo = (color?: string, onlyMark?: boolean) => {
  if(color==="white" && onlyMark) return markWhiteLogo
  else if(color==="primary" && onlyMark) return markPrimaryLogo
  else if (color==="primary") return defaultPrimaryLogo
  else return defaultWhiteLogo;
}

interface BrandProps {
  color?: string;
  onlyMark?: boolean;
  size?: string;
}

export const Brand: FC<BrandProps> = ({color,onlyMark,size,...rest}) => {

  const sizeClasses = clsx({
    'h-6 w-6' : size ==="xs" && onlyMark,
    'h-7 w-7' : size ==="sm" && onlyMark,
    'h-9 w-9' : size ==="l" && onlyMark,
    'h-10 w-10' : size ==="xl" && onlyMark,
    'h-6 w-20' : size ==="xs" && !onlyMark,
    'h-7 w-24' : size ==="sm" && !onlyMark,
    'h-9 w-28' : size ==="l" && !onlyMark,
    'h-10 w-32' : size ==="xl" && !onlyMark,
  })
  return <img src={getLogo(color,onlyMark)}
  alt="Brand" className={sizeClasses}/>
};

Brand.propTypes = {
    color :PropTypes.oneOf(['white','primary']),
    size :PropTypes.oneOf(['xs','sm','base','l','xl']),
    onlyMark : PropTypes.bool,

};
Brand.defaultProps = {
    color : 'white',
    onlyMark : false,
    size : 'base',
};
