import Rule from "../../models/scorecard/Rule";
import RuleCategory from "../../models/scorecard/RuleCategory";


export interface RulesByCategoryInterface {
    [ruleCategory: string]: Rule[]
}

export const convertListOfRulesToGroupedByCategory = (listOfRules: Rule[]): RulesByCategoryInterface => {
    const groupedRules: RulesByCategoryInterface = {};

    return listOfRules.reduce(function (map, obj) {

        if (map[obj.ruleCategory!.categoryKey] === undefined) {
            map[obj.ruleCategory!.categoryKey] = [obj]
        } else {
            map[obj.ruleCategory!.categoryKey].push(obj);
        }
        return map;
    }, groupedRules);
}

export const getListOfUniqueCategoriesFromListOfRules = (rules: Rule[]): RuleCategory[] =>
    rules
        .filter(r => !!r.ruleCategory)
        .map(r => r.ruleCategory!)
        .filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.categoryKey === value.categoryKey && t.categoryName === value.categoryName
                ))
        );
