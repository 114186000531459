import {Link, useLocation} from "react-router-dom";
import Box from "../../../octo-ui/Box/Box";
import {NavBar} from "../../../octo-ui/NavBar/NavBar";
import {ADMIN_ROUTES} from "../../../../routes/routes";
import {NavItem} from "../../../octo-ui/NavItem/NavItem";
import React from "react";

const AdminAppMenu = () => {
    const {pathname} = useLocation();
    return (
        <Box className="hidden md:block">
            <NavBar>
                {ADMIN_ROUTES.filter(r => r.isPrivate).map((item) => {
                    return !!item.showInNav && (
                        <NavItem
                            key={item.name}
                            current={item.path === pathname}
                            aria-current={item.path === pathname ? 'page' : undefined}
                            Component={Link}
                            to={item.path}
                        >
                            {item.icon && <item.icon className="w-5 h-5 mr-2" aria-hidden="true" />}
                            {item.name}
                        </NavItem>
                    )
                })}
            </NavBar>
        </Box>
    );
};

export default AdminAppMenu;