import {SearchIcon} from "@heroicons/react/solid";
import React, {FC} from "react";

interface SearchInputComponentProps {
    componentName: string;
    value: string;
    setValue: (value: string) => void;
}

const SearchInputComponent: FC<SearchInputComponentProps> = ({componentName, value, setValue}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    return (
        <div className="sm:px-0 px-1 flex md:flex-1 items-center">
            <div className="flex-1 flex justify-center lg:justify-end">
                <div className="w-full">
                    <label htmlFor="search" className="sr-only">
                        Search {componentName}
                    </label>
                    <div className="relative text-indigo-200 focus-within:text-gray-400">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input
                            id="search"
                            name="search"
                            className="block w-full pl-10 pr-3 py-2 border-1.5 border-gray-300 rounded-md leading-5 bg-white bg-opacity-25 text-gray-400 placeholder-gray-400 focus:outline-none focus:ring-1 focus:placeholder-gray-500 focus:text-gray-500 sm:text-sm"
                            placeholder={`Search ${componentName}`}
                            type="search"
                            value={value}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchInputComponent;