import { useEffect } from 'react';

const useAutoScroll = <T>(
  scrollContainer: React.RefObject<HTMLDivElement> | null,
  dependency: T
) => {
  useEffect(() => {
    if (scrollContainer && scrollContainer.current) {
scrollContainer.current.scrollTo({
        top: scrollContainer.current.scrollHeight,
        behavior: 'smooth',
      });    }
  }, [dependency, scrollContainer]);
};

export default useAutoScroll;