import React, {FC} from "react";
import Select, {StylesConfig} from "react-select";
import Badge from "../../octo-ui/Badge/Badge";

interface SelectSearchInputPropos {
    options: any[];
    onChange: (value: any[]) => void;
    componentName: string;
    team?: boolean;
    Icon?: FC<any>;
}

const SelectSearchInput: FC<SelectSearchInputPropos> = ({options, onChange, componentName, team, Icon}) => {

    const colourStyles: StylesConfig<any[], true> = {
        option: (styles, {isFocused, isSelected}) =>
            ({
                ...styles,
                backgroundColor: isSelected || isFocused ? "#E1E7FD" : "white",
                color: "black",
            }),
        multiValue: (styles) =>
            ({
                ...styles,
                backgroundColor: "white",
                padding: 0,
                margin: 0,
            }),
    };


    return (
        <div className="sm:px-0 px-1 flex md:flex-1 items-center">
            <div className="flex-1 flex justify-center lg:justify-end">
                <div className="w-full">
                    <label htmlFor="search" className="sr-only">
                        Filter by {componentName}
                    </label>
                    <div className="relative text-gray-400 focus-within:text-gray-400">
                        <Select
                            isMulti
                            isSearchable
                            options={options}
                            openMenuOnClick={false}
                            closeMenuOnSelect={true}
                            formatOptionLabel={(option: any) => <Badge size={"small"} team={team} Icon={Icon}>{option.name}</Badge>}
                            placeholder={<span
                                className="text-gray-400 sm:text-sm">{`Filter by ${componentName}`}</span>}
                            getOptionLabel={(option: any) => option.name}
                            getOptionValue={(option: any) => option.name}
                            styles={colourStyles}
                            onChange={(value) => onChange(value as any[])}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectSearchInput;