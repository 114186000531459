import {FC} from "react";
import clsx from "clsx";
import Box from "../../octo-ui/Box/Box";
import Service from "../../../models/service/Service";
import ServiceOwnersComponent from "../../service/owners/ServiceOwnersComponent";
import PercentBadgeComponent from "../PercentBadgeComponent";
import {getOrdinalSuffixOfPosition} from "../../../utils/formating";
import {Link} from "react-router-dom";
import {ADMIN_SERVICES_PAGE} from "../../../routes/paths";

interface PositionComponentProps {
    position: number;
    service: Service;
    score: number;
    percentage: number;
}

const PositionComponent: FC<PositionComponentProps> = ({position, service, score, percentage}) => {
    const rankColourMap = new Map<number, string>([
        [1, "text-yellow-400"],
        [2, "text-gray-400"],
        [3, "text-orange-400"]
    ]);

    const style = clsx(
        "flex",
        {
            "h-16": position !== 1,
            "text-2xl sm:text-3xl flex rounded shadow-md bg-white -mx-3 mb-6 h-24": position === 1
        },
        "hover:bg-gray-200 cursor-pointer"
    );

    const rankStyle = clsx(
        "w-1/4 my-auto font-bold",
        {'text-sm': position !== 1},
        `${rankColourMap.get(position)}`
    );

    return (
        <Link
            to={`${ADMIN_SERVICES_PAGE}/${service.id}`}>
            <Box className={style}>
                <Box className={rankStyle}>
                    {rankColourMap.get(position) && <i className="fas fa-trophy"/>}
                    <p>{getOrdinalSuffixOfPosition(position)}</p>
                </Box>
                <div className="flex flex-col items-center w-1/2 my-auto">
                    {service.name}
                    <ServiceOwnersComponent owners={service.owners} limit={1}/>
                </div>
                <div className="flex flex-col items-center w-1/4 my-auto">
                    <p>{score} pts</p>
                    <PercentBadgeComponent percentage={percentage}/>
                </div>
            </Box>
        </Link>
    );
};

export default PositionComponent;