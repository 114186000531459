import React, { FC, useEffect, useState } from "react";
import {
  RuleStatus,
  RulesStatusByCategory,
} from "../../../../../models/evaluations/ServiceStatsByScoreCard";
import Level from "../../../../../models/scorecard/Level";
import { XIcon } from "@heroicons/react/solid";

interface ServiceLevelsPerScoreCardComponentProps {
  rules: RulesStatusByCategory;
  levels: Level[] | undefined;
}

interface ServiceLevels {
  [levelName: string]: boolean;
}

const ServiceLevelsPerScoreCardComponent: FC<
  ServiceLevelsPerScoreCardComponentProps
> = ({ rules, levels }) => {
  const [serviceStatusInLevels, setServiceStatusInLevels] =
    useState<ServiceLevels>({});
  const [currentLevel, setCurrentLevel] = useState<string | undefined>();
  const [leftRules, setLeftRules] = useState<RuleStatus[]>();

  const getServiceStatusInLevels = (
    levels: Level[],
    rules: RulesStatusByCategory
  ) => {
    const serviceLevels: ServiceLevels = {};
    let lastLevelName = "";
    let index = 0;
    const rulesInScorecard = Object.values(rules).flat();
    do {
      const rulesInLevel = rulesInScorecard.filter((rule) =>
        levels[index].ruleIds?.includes(rule.ruleId)
      );
      if (rulesInLevel.length == 0) return serviceLevels;
      let levelAchieved = true;
      let i = 0;
      while (levelAchieved && i < rulesInLevel.length) {
        if (!rulesInLevel[i].isRuleEvaluationSuccessful) {
          levelAchieved = false;
        }
        i++;
      }
      serviceLevels[levels[index].name!] = levelAchieved;
      lastLevelName = levels[index].name!;
      index++;
    } while (serviceLevels[lastLevelName] && index < levels.length);

    while (index < levels.length) {
      serviceLevels[levels[index].name!] = false;
      index++;
    }
    return serviceLevels;
  };

  const getCurrentLevel = (serviceStatusInLevels: ServiceLevels) => {
    const trueLevels = Object.keys(serviceStatusInLevels).filter(
      (key) => serviceStatusInLevels[key] === true
    );
    return  trueLevels[trueLevels.length - 1] ;
  };

  const getLeftRules = (
    currentLevel: string | undefined,
    rules: RulesStatusByCategory,
    levels: Level[]
  ) => {
    let currentLevelIndex;
    currentLevel
      ? (currentLevelIndex = levels.findIndex(
          (level) => level.name == currentLevel
        ))
      : (currentLevelIndex = -1);
    if (currentLevelIndex + 1 < levels.length) {
      const nextLevel = levels[currentLevelIndex + 1];
      const rulesInLevel = Object.values(rules)
        .flat()
        .filter((rule) => nextLevel?.ruleIds?.includes(rule.ruleId));
      const failingRules = rulesInLevel.filter(
        (rule) => rule.isRuleEvaluationSuccessful == false
      );
      console.log(failingRules);
      return failingRules;
    }
    return [];
  };

  useEffect(() => {
    if (levels && levels.length > 0)
      setServiceStatusInLevels(getServiceStatusInLevels(levels, rules));
  }, [levels, rules]);

  useEffect(() => {
    if (levels && levels.length > 0)
      setCurrentLevel(getCurrentLevel(serviceStatusInLevels));
  }, [serviceStatusInLevels]);

  useEffect(() => {
    if (levels && levels.length > 0)
      setLeftRules(getLeftRules(currentLevel, rules, levels));
  }, [currentLevel, rules]);

  return (
    <div id="service-scorecard-levels-container" className={"space-y-4"}>
      <div className="mx-auto p-4 sm:px-6 lg:px-8">
        <h2 className="text-lg leading-6 font-medium text-gray-900">Levels</h2>
        <div
          id={"service-scorecard-score"}
          className="bg-white hover:bg-gray-100 overflow-hidden shadow rounded-lg"
        >
          {levels && levels.length > 0 ? (
            <div className="p-4">
              {currentLevel ? (
                <>
                  <p className="bg-octo-50 py-1 my-1 pl-1 border-l-4 border-l-octo-100 ">
                    Your are currently in level :{" "}
                  </p>
                  <div className="flex m-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1.4em"
                      viewBox="0 0 512 512"
                      fill="#94aad1"
                      className="ml-8 "
                    >
                      <path d="M4.1 38.2C1.4 34.2 0 29.4 0 24.6C0 11 11 0 24.6 0H133.9c11.2 0 21.7 5.9 27.4 15.5l68.5 114.1c-48.2 6.1-91.3 28.6-123.4 61.9L4.1 38.2zm503.7 0L405.6 191.5c-32.1-33.3-75.2-55.8-123.4-61.9L350.7 15.5C356.5 5.9 366.9 0 378.1 0H487.4C501 0 512 11 512 24.6c0 4.8-1.4 9.6-4.1 13.6zM80 336a176 176 0 1 1 352 0A176 176 0 1 1 80 336zm184.4-94.9c-3.4-7-13.3-7-16.8 0l-22.4 45.4c-1.4 2.8-4 4.7-7 5.1L168 298.9c-7.7 1.1-10.7 10.5-5.2 16l36.3 35.4c2.2 2.2 3.2 5.2 2.7 8.3l-8.6 49.9c-1.3 7.6 6.7 13.5 13.6 9.9l44.8-23.6c2.7-1.4 6-1.4 8.7 0l44.8 23.6c6.9 3.6 14.9-2.2 13.6-9.9l-8.6-49.9c-.5-3 .5-6.1 2.7-8.3l36.3-35.4c5.6-5.4 2.5-14.8-5.2-16l-50.1-7.3c-3-.4-5.7-2.4-7-5.1l-22.4-45.4z" />
                    </svg>
                    <span className="text-octo-600 ml-2 ">{currentLevel}</span>
                  </div>
                </>
              ) : (
                <p className="bg-octo-50 py-1 my-1 pl-1 border-l-4 border-l-octo-100 ">
                  No level
                </p>
              )}
              {leftRules && leftRules.length > 0 && (
                <p className="bg-octo-50 py-1 my-1 pl-1 border-l-4 border-l-octo-100 ">
                  These rules must pass to unlock next level :
                </p>
              )}

              {leftRules && leftRules.length > 0  ? (
                leftRules?.map((leftRule) => (
                  <div className="flex ml-8">
                    <XIcon className={"w-4 h-4 m-1 mr-2"} fill="#94aad1" />
                    <p>{leftRule.ruleName}</p>
                  </div>
                ))
              ) : (
                <p className="bg-octo-50 py-1 my-1 pl-1 border-l-4 border-l-octo-100 ">Congratulations, you've unlocked all achievements</p>
              )}
            </div>
          ) : (
            <p className="p-8 text-center">No levels in this scorecard yet </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceLevelsPerScoreCardComponent;
