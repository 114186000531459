import React from "react";
import { generateGrowthServicesRank } from "../../../utils/generateServicesForDropedImprovedRank";
import DataTable from "./DataTable";
import NoDataMessage from "./NoDataMessage";
import ServiceScores from "../../../models/leaderboard/ServiceScores";

type MostImprovedServicesProps = {
  dataServices: ServiceScores[];
};

const ImprovedServicesDashboard: React.FC<MostImprovedServicesProps> = ({
  dataServices,
}) => {
  const rankServices = generateGrowthServicesRank(dataServices)
    .sort((a, b) => b.changePercentage - a.changePercentage)
    .slice(0, 6);

  const headerColumns = ["Name", "Owner", "First & Last Score", "Growth Rate"];
  const dataRows = rankServices.map((data) => [
    data.service.name,
    data.service.owners[0]?.name || "-",
    <span
      className="inline-block rounded-full px-4 text-sm font-semibold bg-blue-200 text-blue-700"
      key={data.service.name}
    >
      {data.points[0]}-{data.points[1]}
    </span>,
    <span
      className={`inline-block rounded-full px-4 text-sm font-semibold ${
        data.changePercentage >= 0 ? "bg-green-200 text-green-700" : "bg-red-200 text-red-700"
      }`}
      key={data.service.name}
    >
      {data.changePercentage}%
    </span>,
  ]);

  return (
    <div className="flex flex-col ">
      {rankServices.length > 0 ? (
        <DataTable   headerColumns={headerColumns} dataRows={dataRows} />
      ) : (
        <div className="flex justify-center items-center mt-20">
        <NoDataMessage />
      </div>
      )}
    </div>
  );
};

export default ImprovedServicesDashboard;
