import React, {useEffect, useMemo, useState} from "react";
import {useServices} from "../../../hooks/useServices";
import Card from "../../../components/octo-ui/Card/Card";
import Box from "../../../components/octo-ui/Box/Box";
import {Link} from "react-router-dom";
import {ADMIN_SERVICES_PAGE} from "../../../routes/paths";
import emptyCanvasSvg from "../../../assets/svg/blank_canvas.svg";
import ServiceOwnersComponent from "../../../components/service/owners/ServiceOwnersComponent";
import {LoadingPage} from "../../loading";
import SearchInputComponent from "../../../components/basic/SearchInput/SearchInputComponent";
import SelectSearchInput from "../../../components/basic/SelectSearchInput/SelectSearchInput";
import SortFilterComponent, {SortOption} from "../../../components/basic/SortFilterComponent/SortFilterComponent";
import Service from "../../../models/service/Service";
import Owner from "../../../models/service/Owner";

export const AdminServicesPage = () => {
    const {data, isSuccess, isLoading} = useServices();
    const [displayedServices, setDisplayedServices] = useState<Service[] | undefined>(data);
    const [searchValue, setSearchValue] = useState("");
    const [selectedTeams, setSelectedTeams] = useState<Owner[] | undefined>([]);
    const [selectedSortOption, setSelectedSortOption] = useState<SortOption | undefined>(undefined);

    const getAllServiceOwners = () => {
        return data?.map(service => service.owners)
            .reduce((acc, curr) => acc.concat(curr), [])
            .filter((owner, index, self) => self.findIndex(o => o.name === owner.name) === index);
    }

    const applySearchAndFilters = useMemo(() => {
        let filteredServices: Service[] | undefined;
        const oldData = data ? [...data] : [];

        if(!!selectedTeams && selectedTeams.length > 0) {
            filteredServices = oldData.filter(service => {
                return selectedTeams.every(team => service.owners.some(owner => owner.name === team.name));
            });
        } else {
            filteredServices = oldData;
        }

        if (searchValue.length > 0) {
            filteredServices = filteredServices.filter((service: Service) => {
                return service.name.toLowerCase().includes(searchValue.toLowerCase());
            });
        }

        if(!!selectedSortOption) {
            filteredServices = filteredServices.sort((a, b) => {
                let result = a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});

                if(selectedSortOption.order === "desc") {
                    result *= -1;
                }
                return result;
            });
        }

        return filteredServices;
    }, [searchValue, selectedTeams, selectedSortOption, data]);

    useEffect(() => {
        setDisplayedServices(data);
    }, [data]);

    useEffect(() => {
        setDisplayedServices(applySearchAndFilters);
    }, [applySearchAndFilters]);

    const filterOptions : SortOption[] = [
        {
            label: "Name",
            order: "asc",
        },
        {
            label: "Name",
            order: "desc",
        }
    ];


    let view;

    if(!isLoading){
        if (isSuccess) {
            if (displayedServices && displayedServices.length > 0) {
                view = (
                    <Box className="grid grid-cols-1 p-1 gap-4 sm:grid-cols-2">
                        {displayedServices.map((service, index) => (
                            <Card
                                key={`${service.id}-${index}`}
                                className="relative flex items-center space-x-3 px-6 py-5"
                            >
                                <Box className="flex-1 min-w-0">
                                    <Link
                                        to={`${ADMIN_SERVICES_PAGE}/${service.id}`}
                                        className="focus:outline-none"
                                    >
                                        <span className="absolute inset-0" aria-hidden="true"/>
                                        <p className="text-sm font-medium text-gray-900">
                                            {service.name}
                                        </p>
                                        <p className="text-sm text-gray-500 truncate">
                                            {service.description}
                                        </p>
                                    </Link>
                                </Box>
                                <ServiceOwnersComponent owners={service.owners}/>
                            </Card>
                        ))}
                    </Box>
                );
            } else {
                view = (
                    <Box className="flex flex-col justify-center items-center w-full">
                        <img className="w-1/6" src={emptyCanvasSvg} alt="Empty"/>
                        <p className="text-center text-gray-500 text-2xl">
                            No services found
                        </p>
                    </Box>
                );
            }
        } else {
            view = (
                <p className="text-center text-gray-500">
                    Something went wrong...
                </p>
            );
        }
    }
    else {
        view = (
            <LoadingPage/>
        );
    }

    return (
        <>
            <header className="bg-white shadow">
                <Box className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold text-gray-700">Services</h1>
                </Box>
            </header>
            <main>
                <Box className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">

                    <div className="flex flex-col md:flex-row justify-evenly md:justify-center gap-4 md:gap-1 bg-white shadow rounded-lg mb-5 px-5 py-5 mx-1 md:mx-0">

                        <SearchInputComponent componentName={"Services"} setValue={setSearchValue} value={searchValue} />

                        <SelectSearchInput componentName={"Teams"} team options={getAllServiceOwners() as any} onChange={setSelectedTeams} />

                        <SortFilterComponent sortOptions={filterOptions} selectedSort={selectedSortOption} onChange={(option) => setSelectedSortOption(option)} />

                    </div>

                    {view}
                </Box>
            </main>
        </>
    );
};
