import {useMutation, useQueryClient} from "react-query";
import {deleteScoreCard} from "../../services/scorecards/ScoreCards.service";
import {useAxios} from "../useAxios";
import {SCORE_CARDS_QUERY} from "./useScoreCards";

export const useDeleteScoreCard = (scoreCardId: string, onSuccessCallBack: Function) => {

    const queryClient = useQueryClient();
    const {axios} = useAxios();
    return useMutation(async () => {
        return await deleteScoreCard(axios, scoreCardId);
    }, {
        onSuccess: async (deleteResult) => {
            onSuccessCallBack();
            if(deleteResult){
                await Promise
                    .all([
                        queryClient.setQueryData([SCORE_CARDS_QUERY, scoreCardId], undefined),
                        queryClient.invalidateQueries([SCORE_CARDS_QUERY])
                    ]);
            }
        },
        onError: (error) => {
            //TODO handle errors
            console.log(error);
        }
    });
}