import React, {FC} from "react";

interface BoxProps {
    children?: React.ReactNode;
    className?: string;
}

const Box: FC<BoxProps> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export default Box;
