import React, {FC} from "react";
import {AdjustmentsIcon} from "@heroicons/react/solid";

interface FractionComponentProps {
    upperNumber: number;
    lowerNumber: number;
    modelName: string;

}

const FractionComponent: FC<FractionComponentProps> = ({upperNumber, lowerNumber, modelName}) => {
    const ratio = 100 * upperNumber / lowerNumber;
    let theme: "red" | "green" | "gray" | "yellow" | "orange" | "blue" | "indigo" | "purple" | "pink" | undefined = "red"
    if (ratio > 80) theme = "green"
    else if (ratio >= 50) theme = "orange"
    else theme = "red";

    return (<>
        <div className="h-full flex items-center ">
            <div className="flex-shrink-0">
                <AdjustmentsIcon className="h-6 w-6 text-gray-400" aria-hidden="true"/>
            </div>
            <div className="flex flex-col gap-1 ml-5 ">
                <div className={"grid grid-cols-10"}>
                    <span className={"col-span-2 text-xl text-center"}>{upperNumber}</span>
                    <span className={"col-span-8 text-sm font-medium text-gray-500"}>passing {modelName}</span>
                </div>
                <div className="w-full rounded-full h-1">
                    <div id={"fraction-separator"} className={`bg-${theme}-400 h-1 rounded-full`}
                         style={{width: `${ratio}%`}}></div>
                </div>
                <div className={"grid grid-cols-10"}>
                    <span className={"col-span-2 text-xl text-center"}>{lowerNumber}</span>
                    <span className={"col-span-8 text-sm font-medium text-gray-500"}>evaluated {modelName}</span>
                </div>
            </div>
        </div>
    </>);
}

export default FractionComponent;