const LeaderServiceCardSkeleton = () => {
    return (
        <div className="block bg-gray-200 shadow-sm rounded-md overflow-hidden mb-4 animate-pulse">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between p-4 gap-4">
            <div className="flex items-center gap-4">
            <div className="rounded bg-gray-300 w-10 h-10 p-2"></div>
            <div>
                <div className="h-6 bg-gray-300 rounded w-3/4 mb-2"></div>
                <div className="h-4 bg-gray-300 rounded w-1/4"></div>
            </div>
            </div>
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 mt-2 sm:mt-0">
            <span className="inline-block bg-gray-300 rounded px-2 py-1 text-xs sm:text-sm font-medium w-24"></span>
            <span className="inline-block bg-gray-300 rounded px-2 py-1 text-xs sm:text-sm font-medium w-16"></span>
            </div>
        </div>
        </div>
    );
}

export default LeaderServiceCardSkeleton;