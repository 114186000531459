import axios from "axios";
import {useMemo, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";

/**
 * Returns an authorizated axios instance
 *
 * @param {Object} config is the default config to be sent to the axios creator
 *
 * @return {Object} an object containing the axios instance and the initialized prop
 *
 */
export const useAxios = (config: any  = {}) => {
    const {initialized: authInitialized, keycloak} = useKeycloak();
    const { token } = keycloak;

    const [initialized, setInitialized] = useState(false);

    const envSettings = window as any;

    const expensiveAxios = () => {
        const instance = axios.create({
            ...config,
            baseURL: envSettings.REACT_APP_API_URL ? envSettings.REACT_APP_API_URL+"/api/internal/v1" : "http://localhost:8080/api/internal/v1",
            headers: {
                ...(config.headers || {}),
                "Content-Type": "application/json",
                Authorization: authInitialized ? `Bearer ${token}` : undefined,
            },
        });
        setInitialized(true);
        return { instance };
    };
    const axiosInstance = useMemo(
        () => expensiveAxios(),
        [token, authInitialized]
    );

    return { axios: axiosInstance.instance, initialized };
};

export default { useAxios };
