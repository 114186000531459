import {useAxios} from "../useAxios";
import {useQuery} from "react-query";
import {getServiceEvaluationsHistoryByScoreCard} from "../../services/evaluations/evaluations.service";
import {ScoreCardEvaluation} from "../../models/evaluations/ScoreCardEvaluation";

export const EVALUATIONS_QUERY = "evaluations";

export const useServiceEvaluationsHistoryByScoreCard = (scoreCardId: string, serviceId: string) => {
    const {axios} = useAxios();
    return useQuery<ScoreCardEvaluation[], Error>([EVALUATIONS_QUERY, scoreCardId, serviceId], (): Promise<ScoreCardEvaluation[]> => getServiceEvaluationsHistoryByScoreCard(axios, scoreCardId, serviceId));
}