import {useQuery} from "react-query";
import ScoreCard from "../../models/scorecard/ScoreCard";
import {getAllScoreCards} from "../../services/scorecards/ScoreCards.service";
import {useAxios} from "../useAxios";

export const SCORE_CARDS_QUERY = "scoreCards";

export const useScoreCards = () => {
    const {axios} = useAxios();
    return useQuery<ScoreCard[], Error>(SCORE_CARDS_QUERY, () : Promise<ScoreCard[]> => getAllScoreCards(axios));
}