import { useMemo, useState } from "react";
import ServiceScores from "../../../../models/leaderboard/ServiceScores";
import ScoreCard from "../../../../models/scorecard/ScoreCard";
import ScorecardsFilterComponent from "../../leaderboard/modules/ScorecardsFilterComponent";
import LeaderServiceCard from "../../../../components/basic/LeaderServiceCard/LeaderServiceCard";
import Service from "../../../../models/service/Service";
import LeaderServicesWidgetSkeleton from "./LeaderServicesWidgetSkeleton";

interface ServiceRank {
  service: Service;
  points: number;
  percentage: number;
}

interface LeaderServicesWidgetProps {
  servicesScores: ServiceScores[] | undefined;
  isLoadingServicesScores: boolean;
}

export const generateServicesRanks = (
  servicesScores: ServiceScores[],
  scorecardIds: string[]
): ServiceRank[] => {
  const servicesRanks: ServiceRank[] = [];

  servicesScores.forEach((serviceScore) => {
    const service = serviceScore.service;
    const scores = serviceScore.scores.filter(
      (score) =>
        scorecardIds.length === 0 || scorecardIds.includes(score.scorecardId)
    );
    const points = scores.reduce((acc, score) => acc + score.score, 0);
    const totalPossible = scores.reduce(
      (acc, score) => acc + score.totalPossible,
      0
    );
    const percentage =
      totalPossible === 0 ? 0 : Math.round((100 * points) / totalPossible);
    servicesRanks.push({ service, points, percentage });
  });

  servicesRanks.sort((a, b) => b.points - a.points);

  return servicesRanks;
};

export const LeaderServicesWidget: React.FC<LeaderServicesWidgetProps> = ({ servicesScores, isLoadingServicesScores }) => {
  const [selectedScorecards, setSelectedScorecards] = useState<string[]>([]);

  const handleSelectedScorecards = (scorecards: ScoreCard[]) => {
    setSelectedScorecards(scorecards.map((scorecard) => scorecard.id));
  };


  const topServices = useMemo(() => {
    if (servicesScores) {
      const ranks = generateServicesRanks(servicesScores, selectedScorecards);
      ranks.sort((a, b) => b.points - a.points);
      return ranks.slice(0, 4);
    } else {
      return [];
    }
  }, [servicesScores, selectedScorecards]);
  
  if (isLoadingServicesScores) {
    return <LeaderServicesWidgetSkeleton />;
  }

  const widget = (
    <div className="p-2">
     <ScorecardsFilterComponent onChange={handleSelectedScorecards} />
      <div className="mt-2">
        {topServices.map((serviceRank, index) => (
          <LeaderServiceCard
            key={serviceRank.service.id}
            serviceRank={serviceRank}
            index={index}
          />
        ))}
      </div>
    </div>
  );


  return widget;
};

