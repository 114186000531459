import React, {FC, useEffect, useMemo, useState} from "react";
import Select, { StylesConfig} from "react-select";
import Badge from "../../../octo-ui/Badge/Badge";

interface SelectSearchInputPropos {
    options: any[];
    onChange: (value: any[]) => void;
    componentName: string;
    team?: boolean;
    Icon?: FC<any>;
    originalValues : any[];
    selectedRulesIds: string[];
    setSelectedRulesIds(ruleids :  string[]) : void ;
}

const SelectSearchInput: FC<SelectSearchInputPropos> = ({options, onChange, componentName, team, Icon, originalValues,selectedRulesIds, setSelectedRulesIds}) => {

    const [values, setValues] = useState(originalValues);
    const [changed, setChanged] = useState(false);



    useEffect(() => {
          setValues(originalValues);
          setChanged(false);
      }, []);

      useEffect(() => {
        if(!changed)
        setValues(originalValues);
    }, [originalValues]);



    const colourStyles: StylesConfig<any[], true> = {
        option: (styles, {isFocused, isSelected}) =>
            ({
                ...styles,
                backgroundColor: isSelected || isFocused ? "#E1E7FD" : "white",
                color: "black",
            }),
        multiValue: (styles) =>
            ({
                ...styles,
                backgroundColor: "white",
                padding: 0,
                margin: 0,
            }),
    };
    


    return (
        <div className="sm:px-0 px-1 flex md:flex-1 items-center">
            <div className="flex-1 flex justify-center lg:justify-end">
                <div className="w-full">
                    <label htmlFor="rule-ids" className="sr-only">
                        Choose {componentName}
                    </label>
                    <div className="relative text-gray-400 focus-within:text-gray-400">
                        <Select
                            id="rule-ids"
                            isMulti
                            isSearchable
                            options={options}
                            openMenuOnClick={true}
                            closeMenuOnSelect={false}
                            formatOptionLabel={(option: any) => <Badge size={"small"} team={team} Icon={Icon}>{option.name}</Badge>}
                            placeholder={<span
                            className="text-gray-400 sm:text-sm">{`Choose by ${componentName} names`}</span>}
                            getOptionLabel={(option: any) => option.name}
                            getOptionValue={(option: any) => option.id}
                            styles={colourStyles}
                            onChange={(value) => {
                                setChanged(true);
                                const deletedRulesFromLevel = values.filter(rule => !value.includes(rule));
                                setSelectedRulesIds([...selectedRulesIds.filter(rule => !deletedRulesFromLevel.map(rule => rule.id).includes(rule)),  ...value.map(rule => rule.id)])
                                setValues(value as any[]);
                                onChange(value.map((rule: any) => rule.id));}}
                                
                            value={values}
                            
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectSearchInput;