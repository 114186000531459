import {useAxios} from "../useAxios";
import {useQuery} from "react-query";
import {getScoreCardLastEvaluationByEvaluationTime} from "../../services/evaluations/evaluations.service";
import {ScoreCardEvaluation} from "../../models/evaluations/ScoreCardEvaluation";

export const EVALUATIONS_QUERY = "evaluations_time";

export const useScoreCardLastEvaluationsByTime = (scoreCardId: string,time:string) => {
    const {axios} = useAxios();
    return useQuery<ScoreCardEvaluation[], Error>([EVALUATIONS_QUERY, scoreCardId,time, "last"], (): Promise<ScoreCardEvaluation[]> => getScoreCardLastEvaluationByEvaluationTime(axios, scoreCardId,time));
}