import React, {FC} from "react";
import {CheckCircleIcon, RefreshIcon} from "@heroicons/react/solid";

interface FormValidateButtonComponentProps {
    isButtonDisabled: boolean;
    isFormSubmitting: boolean;
    isFormDirty: boolean;
    dirtyButtonText: string;
    formId?: string
}

export const FormValidateButton: FC<FormValidateButtonComponentProps> = ({
                                                                             formId,
                                                                             dirtyButtonText,
                                                                             isFormSubmitting,
                                                                             isFormDirty,
                                                                             isButtonDisabled
                                                                         }) => {
    return (<button
        type={"submit"}
        form={formId}
        data-testid="save-button"
        id="save-button"
        disabled={isButtonDisabled}
        className={`inline-flex items-center px-3 py-2 
                                border border-transparent shadow-sm 
                                text-sm leading-4 font-medium rounded-md text-white 
                                ${isButtonDisabled ? "bg-gray-400" : "bg-indigo-600"}
                                `}>
        {isFormSubmitting ? (
                <RefreshIcon data-testid="submitting-form-icon"
                             className={`animate-spin fill-orange-300 -ml-0.5 mr-2 h-4 w-4`}
                             aria-hidden="true"/>)
            : isButtonDisabled ? (<CheckCircleIcon
                    data-testid="saved-form-icon"
                    className={`animate-short-spin fill-white -ml-0.5 mr-2 h-4 w-4`}/>) :
                (<div/>)}
        {isFormSubmitting ? "Saving Changes"
            : isFormDirty ? dirtyButtonText
                : "Saved"}

    </button>);
}