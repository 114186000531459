export const HOME_BASE_PATH = '/';
export const LOGIN_BASE_PATH = '/login';
export const UNAUTHORIZED_BASE_PATH = '/unauthorized';
export const NOT_FOUND_PATH = '/404';
export const ADMIN_BASE_PATH = '/admin';
export const ADMIN_DASHBOARD_PAGE = '/admin/dashboard';
export const ADMIN_SERVICES_PAGE = '/admin/services';
export const ADMIN_SERVICE_DETAIL_PAGE = '/admin/services/:id';
export const ADMIN_SCORECARDS_PAGE = '/admin/scorecards';
export const ADMIN_LEADERBOARD_PAGE = '/admin/leaderboard';
export const ADMIN_EDIT_SINGLE_SCORECARD_PAGE = '/admin/scorecards/:id/edit';
export const ADMIN_SCORECARD_DETAILS_PAGE = '/admin/scorecards/:id';
export const ADMIN_CREATE_NEW_SCORECARD_PAGE = '/admin/scorecards/create';
export const ADMIN_CONFIG_PAGE_PREFIX = '/admin/config';
export const ADMIN_CONFIG_PAGE = ADMIN_CONFIG_PAGE_PREFIX+'/:templateKey';
