import {AxiosInstance} from "axios";

export const getConfigByTemplateId = async (axios: AxiosInstance, templateKey: string): Promise<any> => {
    if (!templateKey) return Promise.resolve(new Map());

    const response = await axios.get(`/config/${templateKey}`);

    return response.data
}

export const saveTemplateConfig = async (axios: AxiosInstance, templateKey: string, config: any): Promise<any> => {
    if (!templateKey) return Promise.resolve(new Map());

    const response = await axios.post(`/config/${templateKey}`, config);

    return response.data;
}