export const SelectSkeleton = () => {
  return (
    <div className="flex border rounded-md text-sm text-gray-500 ml-4" data-testid="skeleton-select">
      <div className="w-64 h-10 bg-gray-300 rounded" />
    </div>
  )
}

const ScoreCardsProgressWidgetSkeleton = () => (
  <div>
    <div className="flex p-5 justify-between items-center rounded animate-pulse">
      <div className="bg-gray-300 w-48 h-6 rounded" />
      <div className="flex flex-col sm:flex-row gap-4 items-center">
        <div className="relative flex-grow">
          <div className="h-10 bg-gray-300 rounded" />
        </div>
        <div className="relative flex-grow">
          <div className="h-10 bg-gray-300 rounded" />
        </div>
        <SelectSkeleton />
        <SelectSkeleton />
        <SelectSkeleton />
        <SelectSkeleton />
      </div>
    </div>
    <hr />
    <div className="px-4 flex justify-center my-2">
      <div className="min-h-[400px] w-full bg-gray-300 animate-pulse rounded" data-testid="skeleton-scorecard-progress">
      </div>
    </div>
  </div>
);

export default ScoreCardsProgressWidgetSkeleton;
