import {useIntegrationsTemplates} from "../../../hooks/integrations/templates/useIntegrationsTemplates";
import {LoadingPage} from "../../loading";
import React, {useEffect} from "react";
import {Navigate, useParams} from "react-router-dom";
import {ADMIN_CONFIG_PAGE_PREFIX, NOT_FOUND_PATH} from "../../../routes/paths";
import {useConfig} from "../../../hooks/configurations/useConfig";
import LoadingComponent from "../../../components/basic/LoadingComponent/LoadingComponent";
import Badge from "../../../components/octo-ui/Badge/Badge";
import ConfigForm from "./modules/ConfigForm";
import {useSaveConfig} from "../../../hooks/configurations/useSaveConfig";
import {Toaster} from "react-hot-toast";
import {notify} from "../../../utils/toastUtils";
import IntegrationTemplateMenuItem, {
    CHILD_SEPARATOR
} from "./modules/IntegrationTemplateMenuItem/IntegrationTemplateMenuItem";

const ConfigPage = () => {
    const [canRenderForm, setCanRenderForm] = React.useState<boolean>(false);
    const {data, isSuccess, isLoading} = useIntegrationsTemplates();
    let {templateKey} = useParams();
    const {data: config, isSuccess: isConfigSuccess, isLoading: isConfigLoading} = useConfig(templateKey!);

    const {mutateAsync} = useSaveConfig();

    let currentTemplate : any = undefined;

    useEffect(() => {
        setCanRenderForm(false);

        if(!!currentTemplate && isConfigSuccess && !!config) {
            setTimeout(() => {
                setCanRenderForm(true);
            });
        }
    }, [templateKey, config, isConfigSuccess, data])

    if (isLoading) {
        return <LoadingPage/>
    }

    if (!isSuccess || !data) {
        return <Navigate to={NOT_FOUND_PATH} replace={true}/>
    }

    if (!templateKey) {
        let link = ADMIN_CONFIG_PAGE_PREFIX + "/";
        if(data[0].children && data[0].children.length > 0) link += data[0].key + CHILD_SEPARATOR + data[0].children[0].key;
        else link += data[0].key;
        return <Navigate to={link} replace={true}/>
    }

    currentTemplate = data.find(template => template.key === templateKey!.split(CHILD_SEPARATOR)[0]);
    if(templateKey.includes(CHILD_SEPARATOR)){
        currentTemplate = currentTemplate.children!.find((child: any) => child.key === templateKey!.split(CHILD_SEPARATOR)[1])
    }

    const getParamValue = (configParam: any, conf: any) => {
        if (configParam.type === "LIST"){
            const target = Object.entries(configParam.values!).find(([key, value]) => key === conf[configParam.key]);
            if(target) return target[1];
            return undefined;
        }
        return conf[configParam.key];
    }

    const secretStars = "**************";

    let configStateView, formConfig = {...config};

    if (isConfigLoading) configStateView = <LoadingComponent/>;
    else if (!isConfigSuccess) configStateView = <p className="mt-1 text-sm text-gray-500">
        {currentTemplate} is not yet configured. Fill the form bellow to do so.
    </p>
    else {
        currentTemplate.configParams!.forEach((param: any) => {
           if(param.secret) formConfig[`${param.key}`] = "";
        });
        delete formConfig.isConfigured;
        configStateView = (
            <div className="w-full px-4 py-5 sm:px-6 flex flex-col items-center">
                {currentTemplate.configParams!.map((configParam: any) => (
                    <div className="w-full grid grid-cols-2 gap-1 justify-items-end mt-3" key={configParam.key}>
                        <Badge size="small" theme="gray">{configParam.name}</Badge>
                        <p className="text-sm justify-self-start"><span
                            className="text-xl">{config[configParam.key] && configParam.secret && secretStars}</span>{getParamValue(configParam, config) ?? "Not configured"}
                        </p>
                    </div>))}
            </div>
        );
    }


    const saveConfig = async (formData: any) => {
        await mutateAsync([templateKey, formData]).catch(() => {
            notify();
        });
    }

    return (
        <div>
            <main className="relative -mt-32">
                <div className="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
                    <div className="bg-white rounded-lg shadow overflow-hidden">
                        <div
                            className="lg:max-h-[600px] divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                            <aside className="py-6 lg:col-span-2">
                                <nav className="space-y-1 lg:h-[594px] overflow-auto">
                                    {data.map((item) => {
                                        if(item.children && item.children.length > 0){
                                            return item.children.map(child => (
                                                <IntegrationTemplateMenuItem key={child.key} parentKey={item.key} template={child} currentTemplateKey={templateKey!}/>
                                            ));
                                        }
                                        return (
                                            <IntegrationTemplateMenuItem key={item.key} template={item} currentTemplateKey={templateKey!}/>
                                        )
                                    })}
                                </nav>
                            </aside>

                            {canRenderForm &&
                                <ConfigForm config={formConfig} configStateView={configStateView} saveConfig={saveConfig}
                                            currentTemplate={currentTemplate}/>
                            }
                        </div>
                    </div>
                </div>
            </main>
            <Toaster />
        </div>
    )
}

export default ConfigPage;