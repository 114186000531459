import {useAxios} from "../useAxios";
import {useQuery} from "react-query";
import {getScoreCardLastEvaluation} from "../../services/evaluations/evaluations.service";
import {ScoreCardEvaluation} from "../../models/evaluations/ScoreCardEvaluation";

export const EVALUATIONS_QUERY = "evaluations";

export const useScoreCardLastEvaluations = (scoreCardId: string) => {
    const {axios} = useAxios();

    return useQuery<ScoreCardEvaluation[], Error>([EVALUATIONS_QUERY, scoreCardId, "last"], 
    async (): Promise<ScoreCardEvaluation[]> => {
        if (!scoreCardId) {
            return [];
        }
        return getScoreCardLastEvaluation(axios, scoreCardId);
    });
}