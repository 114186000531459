import {useMutation, useQueryClient} from "react-query";
import {useAxios} from "../useAxios";
import {createNewScoreCard} from "../../services/scorecards/ScoreCards.service";
import ScoreCard from "../../models/scorecard/ScoreCard";
import {SCORE_CARDS_QUERY} from "./useScoreCards";

export const useCreateNewScoreCard = () => {

    const queryClient = useQueryClient();
    const {axios} = useAxios();
    return useMutation(async (scoreCard: ScoreCard) => {
        return await createNewScoreCard(axios, scoreCard);
    }, {
        onSuccess: async (newScoreCard: ScoreCard) => {
            await queryClient.invalidateQueries([SCORE_CARDS_QUERY]);
            await queryClient.setQueryData([SCORE_CARDS_QUERY, newScoreCard.id], newScoreCard);
        },
        onError: (error) => {
            //TODO handle errors
            console.log(error);
        }
    });
}