import React, {useState} from "react";
import {useSingleScoreCard} from "../../../hooks/scorcards/useSingleScoreCard";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import Box from "../../../components/octo-ui/Box/Box";
import Button from "../../../components/octo-ui/Button/Button";
import {TrashIcon, XIcon} from "@heroicons/react/solid";
import {
    ScoreCardFormComponent
} from "../../../components/scorecard/ScoreCardForm/ScoreCardFormComponent/ScoreCardFormComponent";
import {ADMIN_SCORECARDS_PAGE, NOT_FOUND_PATH} from "../../../routes/paths";
import {LoadingPage} from "../../loading";
import {useDeleteScoreCard} from "../../../hooks/scorcards/useDeleteScoreCard";
import ConfirmModalComponent from "../../../components/basic/ConfirmModalComponent/ConfirmModalComponent";

export const EditScoreCardPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const {data: scorecard, isSuccess, isLoading: isLoadingScoreCard} = useSingleScoreCard(id!);
    const {mutateAsync: deleteScoreCard, isLoading: isDeleting} = useDeleteScoreCard(id!, () => {
        navigate(ADMIN_SCORECARDS_PAGE);
    });


    if (!id) {
        return <Navigate to={NOT_FOUND_PATH} replace={true}/>
    }

    if (isLoadingScoreCard) {
        return <LoadingPage/>
    }

    if (isSuccess) {
        if (!scorecard) {
            return <Navigate to={NOT_FOUND_PATH} replace={true}/>
        }
        return (
            <>
                <header className="bg-white shadow">
                    <Box className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8  flex justify-between items-center">
                        <h1 className="text-3xl font-bold text-gray-700">{scorecard.name}</h1>
                        <Box className={"flex items-center gap-2"}>
                            <Button size="sm" type="white" variant="solid" data-testid="cancel-scorecard-button"
                                    props={{
                                        id: "cancel-scorecard-button"
                                    }}
                                    LeftIcon={<XIcon/>}
                                    onClick={async (e: any) => {
                                        e.preventDefault();
                                        navigate(`/admin/scorecards/${id}`);
                                    }}
                            >
                                Cancel
                            </Button>
                            <Button size="sm" type="error" variant="solid" props={{
                                id: "delete-scorecard-button"
                            }}
                                    isLoading={isDeleting}
                                    LeftIcon={<TrashIcon/>}
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setShowConfirmDeleteModal(true);
                                    }}
                            >
                                Delete
                            </Button>
                        </Box>
                    </Box>
                </header>
                <main>

                    <ScoreCardFormComponent scoreCard={scorecard}/>
                </main>

                <ConfirmModalComponent
                    title={"Are you sure?"}
                    message={"This will delete all data related to this scorecard (Rules, History of evaluations ...). This data can not be restored after confirming."}
                    callBack={deleteScoreCard}
                    isOpen={showConfirmDeleteModal}
                    setIsOpen={setShowConfirmDeleteModal}/>
            </>
        );
    } else {
        return <Navigate to={NOT_FOUND_PATH} replace={true}/>
    }


};