import React, {FC, MouseEventHandler} from "react";

interface DiscardFormButtonComponentProps {
    isButtonDisabled: boolean;
    buttonText: string;
    onClick?: MouseEventHandler<HTMLButtonElement>
}

export const DiscardFormButton: FC<DiscardFormButtonComponentProps> = ({
                                                                           buttonText,
                                                                           isButtonDisabled,
                                                                           onClick
                                                                       }) => {
    return (<button
        data-testid="form-discard-button"
        id="form-discard-button"
        type="button"
        disabled={isButtonDisabled}
        onClick={onClick}
        className={`inline-flex items-center px-3 py-2
                                border border-transparent shadow-sm
                                text-sm leading-4 font-medium rounded-md text-white
                                bg-red-500  hover:bg-red-300
                                transition-opacity duration-2000 ease-out opacity-0 ${!isButtonDisabled ? "opacity-100" : ""}`}>
        {buttonText}
    </button>);
}