import {FC} from "react";
import Badge from "../octo-ui/Badge/Badge";

interface PercentBadgeProps {
    percentage: number;
}

const PercentBadgeComponent : FC<PercentBadgeProps> = ({percentage}) => {
    let theme : "red" | "green" | "gray" | "yellow" | "orange" | "blue" | "indigo" | "purple" | "pink" | undefined = "red"
    if(percentage > 80) theme = "green"
    else if(percentage >= 50) theme = "orange";

    return (
        <Badge size="small" theme={theme}>{percentage} %</Badge>
    );
};

export default PercentBadgeComponent;