import React from "react";
import Box from "../../../components/octo-ui/Box/Box";
import Card from "../../octo-ui/Card/Card";
import { OverviewCardContent } from "../overview-content/OverviewCardContent";
import { Link } from "react-router-dom";
import { ADMIN_SCORECARDS_PAGE, ADMIN_SERVICES_PAGE } from "../../../routes/paths";
import {
    PuzzleIcon,
    UsersIcon,
    ClipboardCheckIcon,
  } from "@heroicons/react/outline";
import { useOverviewData } from "../../../hooks/dashboard/useOverview";
import { OverviewCardSekeleton } from "../overview-content/OverviewCardSkeleton";

export const OverviewComponent = () => {
   

    const {
      data: overviewData,
      isSuccess: isOverviewDataLoaded,
      isLoading: isLoadingOverviewData,
    } = useOverviewData();



    let view = isLoadingOverviewData ? (
      <>
        <Box className="grid grid-rows-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
          <OverviewCardSekeleton />
          <OverviewCardSekeleton />
          <OverviewCardSekeleton />
        </Box>
      </>
    ) : isOverviewDataLoaded ? (
      <Box className="grid grid-rows-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
        <Card className="border-l-4 border-l-indigo-300 ">
          <Link
            to={`${ADMIN_SERVICES_PAGE}`}
            className="focus:outline-none"
          >
            <OverviewCardContent
              name="Services"
              value={overviewData?.servicesCount ?? 0}
            >
              <PuzzleIcon
                className="h-6 w-6 text-violet-900 m-6 p-4"
                viewBox="-7.5 -6.5 40 40"
              ></PuzzleIcon>
            </OverviewCardContent>
          </Link>
        </Card>

        <Card className="border-l-4 border-l-lime-300 ">
        <Link
            to={`${ADMIN_SERVICES_PAGE}`}
            className="focus:outline-none"
          >
          <OverviewCardContent
            name="Teams"
            value={overviewData?.teamsCount ?? 0}
          >
            <UsersIcon
              className="h-6 w-6 text-violet-900 m-6 p-4"
              viewBox="-7.5 -6.5 40 40"
            ></UsersIcon>
          </OverviewCardContent>
          </Link>
        </Card>

        <Card className="border-l-4 border-l-amber-400 ">
        <Link
            to={`${ADMIN_SCORECARDS_PAGE}`}
            className="focus:outline-none"
          >
          <OverviewCardContent
            name="Scorecards"
            value={overviewData?.scorecardsCount ?? 0}
          >
            <ClipboardCheckIcon
              className="h-6 w-6 text-violet-900 m-6 p-4"
              viewBox="-7.5 -6.5 40 40"
            ></ClipboardCheckIcon>
          </OverviewCardContent>
          </Link>
        </Card>
      </Box>
    ) : (
      <p className="text-center text-gray-500">Something went wrong...</p>
    );



    return (<>{view}</>);
};