import React, {FC} from "react";
import {AcademicCapIcon, ChartPieIcon} from "@heroicons/react/solid";
import FractionComponent from "../../basic/FractionComponent/FractionComponent";
import ServiceOverview from "../../../models/service/ServiceOverview";
import {getOrdinalSuffixOfPosition} from "../../../utils/formating";


interface ServiceOverviewComponentProps {
    overviewDetails: ServiceOverview
}

const ServiceOverviewComponent: FC<ServiceOverviewComponentProps> = ({overviewDetails}) => {
    return (<>
        <div id="service-overview-container" className={"space-y-4"}>
            <div className="max-w-6xl mx-auto p-4 sm:px-6 lg:px-8">
                <h2 className="text-lg leading-6 font-medium text-gray-900">Service Overview</h2>
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">

                    <div id={"service-rank"}
                         className="bg-white hover:bg-gray-100 overflow-hidden shadow rounded-lg">
                        <div className="h-full p-5">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <AcademicCapIcon className="h-6 w-6 text-gray-400" aria-hidden="true"/>
                                </div>

                                <div className="ml-5 w-0 flex-1">
                                    <dl>
                                        <dd>
                                            <div
                                                className="text-lg font-medium text-gray-900">{overviewDetails.totalScore}/{overviewDetails.possibleScore}
                                            </div>
                                        </dd>
                                        <dt className="text-sm font-medium text-gray-500">Average Score.</dt>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id={"service-passing-rules"}
                         className="bg-white  hover:bg-gray-100 overflow-hidden shadow rounded-lg">
                        <div className="h-full p-5 mb-0">
                            <FractionComponent lowerNumber={overviewDetails.totalEvaluatedRules}
                                               upperNumber={overviewDetails.passingRules} modelName={"rules"}/>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </>);
}

export default ServiceOverviewComponent;