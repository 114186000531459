import ScoreCard from "../../models/scorecard/ScoreCard";
import Rule from "../../models/scorecard/Rule";
import { AxiosInstance } from "axios";

export const getAllScoreCards = (
  axios: AxiosInstance
): Promise<ScoreCard[]> => {
  return axios.get(`scorecards/`).then((r) => {
    return r.data;
  });
};

export const getSingleScoreCardById = (
  axios: AxiosInstance,
  scoreCardId: string
): Promise<ScoreCard> => {
  return axios.get(`scorecards/${scoreCardId}`).then((r) => {
    return {
      id: r.data.id,
      tag: r.data.tag,
      name: r.data.name,
      description: r.data.description,
      targetTags: r.data.targetTags,
      rules: r.data.rules,
      levels: r.data.levels
    };
  });
};

export const updateRuleDetails = (
  axios: AxiosInstance,
  scorecardId: string,
  rule: Rule
): Promise<Rule> => {
  return axios
    .put(`scorecards/${scorecardId}/rules/${rule.id}`, rule)
    .then((r: { data: Rule }) => {
      return r.data;
    });
};

export const createNewRule = (
  axios: AxiosInstance,
  scorecardId: string,
  rule: Rule
): Promise<Rule> => {
  return axios
    .post(`scorecards/${scorecardId}/rules/`, rule)
    .then((r: { data: Rule }) => {
      return r.data;
    });
};

export const createNewScoreCard = (
  axios: AxiosInstance,
  scoreCard: ScoreCard
): Promise<ScoreCard> => {
  return axios.post(`scorecards`, scoreCard).then((r: { data: ScoreCard }) => {
    return r.data;
  });
};

export const updateScoreCard = (
  axios: AxiosInstance,
  scoreCard: ScoreCard
): Promise<ScoreCard> => {
  //TODO call converter to convert map of rules to list of rules
  return axios.put(`scorecards`, scoreCard).then((r) => {
    return r.data;
  });
};

export const deleteScoreCard = async (
  axios: AxiosInstance,
  scoreCardId: string
): Promise<number> => {
  return axios.delete(`scorecards/${scoreCardId}`).then((r) => {
    return r.data;
  });
};

export const evaluateScoreCard = async (
  axios: AxiosInstance,
  scoreCardId: string,
  webSocketConnectionId: string
) => {
  await axios.post(
    `evaluations/scorecard/${scoreCardId}/evaluate?webSocketConnectionId=${webSocketConnectionId}`
  );
};

export const evaluateAllScoreCards = async (
  axios: AxiosInstance,
  webSocketConnectionId: string
) => {
  await axios.post(
    `evaluations/scorecards/evaluations?webSocketConnectionId=${webSocketConnectionId}`
  );
};
