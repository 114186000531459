import IntegrationTemplate, {RuleTemplate} from "../../../models/integration/IntegrationTemplate";

export const getIntegrationFromRuleKey = (integrationTemplates: IntegrationTemplate[] | undefined, ruleKey: string): IntegrationTemplate | undefined => {
    return integrationTemplates?.find((template) => {
        return template.ruleTemplates.some((ruleTemplate) => {
            return ruleTemplate.ruleKey === ruleKey;
        })
    });
}

export const getRuleTemplateFromRuleKey = (rulesTemplates: RuleTemplate[] | undefined, ruleKey: string): RuleTemplate | undefined => {

    return rulesTemplates?.find((template) => template.ruleKey === ruleKey);
}