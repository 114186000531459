import { useState, useEffect, useRef } from "react";
import { Client, over, Message, Frame } from "stompjs";
import SockJS from "sockjs-client";

export const useWebsocket = <T>(url: string, subscribePath: string) => {
  const [websocketData, setWebsocketData] = useState<T | null>(null);
  const [connectionStatus, setConnectionStatus] = useState<string>("disconnected");
  
  const socketRef = useRef<any | null>(null);
  const stompClientRef = useRef<Client | null>(null);

  const connect = () => {
    socketRef.current = new SockJS(url);
    stompClientRef.current = over(socketRef.current);
    setConnectionStatus("connecting");

    stompClientRef.current?.connect(
      {},
      () => {
        setConnectionStatus("connected");
        stompClientRef.current?.subscribe(subscribePath, (message: Message) => {
          const parsedData = JSON.parse(message.body);
          setWebsocketData(parsedData);
        });
      },
      (error: String | Frame) => {
        setConnectionStatus("error");
      }
    );
  };

  const disconnect = () => {
    stompClientRef.current?.disconnect(() => {
      setConnectionStatus("disconnected");
    });
  };

  return { websocketData, connectionStatus, connect, disconnect };
};


