import React, {FC, useState} from "react";
import {TrashIcon} from '@heroicons/react/solid'
import Rule from "../../../../models/scorecard/Rule";
import EditRuleFormComponent from "../EditRuleForm/EditRuleFormComponent";
import {RulesByCategoryInterface} from "../../../../services/scorecards/scorecards.helper";
import Button from "../../../octo-ui/Button/Button";
import RuleCategory from "../../../../models/scorecard/RuleCategory";

interface ScorecardComponentProps {
    rules: RulesByCategoryInterface,
    possibleRulesCategories: RuleCategory[],

    saveNewCategoryCallBack(categoryName: RuleCategory): void

    updateRuleDetailsCallBack(rule: Rule): void,

    creatNewRuleCallBack(rule: Rule): void,

    deleteRuleByIdCallBack(ruleId: string): void
}

const ListOfScoreCardRules: FC<ScorecardComponentProps> = ({
                                                               rules,
                                                               possibleRulesCategories,
                                                               saveNewCategoryCallBack,
                                                               updateRuleDetailsCallBack,
                                                               creatNewRuleCallBack,
                                                               deleteRuleByIdCallBack
                                                           }) => {
    // Map of: categoryName:isAccordionExpanded
    const [expandedRows, setExpandedRows] = useState<{ [categoryName: string]: boolean }>({});
    const [editedRule, setEditedRule] = useState<Rule | undefined>(undefined);
    const [isCreatingNewRule, setIsCreatingNewRule] = useState<boolean>(false);

    const closeEditRuleForm = () => {
        setIsCreatingNewRule(false);
        setEditedRule(undefined);
    };
    const toggleRuleCategoryAccordion = (ruleCategory: string, open?: boolean) => {
        const newState: { [categoryName: string]: boolean } = {};
        newState[ruleCategory] = open ?? !expandedRows[ruleCategory];
        setExpandedRows({
            ...expandedRows,
            ...newState
        });
    };

    const rulesCategoriesNames = Object.keys(rules);
    return (

        <div id="rulesList" className="space-y-8 ">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Rules</h3>
            <div className={"grid md:grid-cols-2 md:space-x-4"}>
                <div
                    className={`shadow-lg rounded p-4 ${(editedRule !== undefined) ? "md:col-span-1" : "md:col-span-2"}`}>
                    <div id={"rules-by-category-list"} className="bg-white">
                        {rulesCategoriesNames.length === 0 && (
                            <p className={"text-center"}> No rules for the moment. </p>
                        )}
                        {rulesCategoriesNames.map((category, index) => (
                            <div key={"accordion-collapse-" + index} id={"accordion-collapse-" + index}
                                 onClick={(e) => {
                                     e.preventDefault();
                                     toggleRuleCategoryAccordion(category);
                                 }} data-accordion="collapse">
                                <h2>
                                    <button type="button"
                                            className="flex items-start p-5 w-full font-medium text-left text-black capitalize rounded-t-xl "
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             className={`my-1 w-5 h-5 ${!expandedRows[category] ? "-rotate-90" : ""} shrink-0 hover:bg-gray-300`}
                                             fill="none" viewBox="0 0 24 24"
                                             stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7"/>
                                        </svg>
                                        <span className={"pl-1"}>{category}</span>
                                        <div>
                                            <span
                                                className="inline-flex items-center mx-5 my-1 px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                                            {rules[category]?.length} rules
                                            </span>
                                            <span
                                                className="inline-flex items-center mx-5 my-1 px-2 py-0.5 rounded text-xs font-medium bg-octo-200 text-gray-800">
                                    {/*get total score of category*/}
                                                {rules[category]?.map((rule) => (rule.score ?? 0))
                                                    .reduce((sum, current) => Number(sum) + Number(current), 0)} points
                                            </span>
                                        </div>
                                    </button>
                                </h2>

                                <div id={`${category}-rules-list`}
                                     className={`${!expandedRows[category] ? "hidden" : ""}`}>
                                    <ul className="space-y-4">

                                        {rules[category]?.map((rule, index) => (
                                            <li className="shadow sm:rounded-md m-auto" key={rule.id}>
                                                <div className="block hover:bg-gray-50 cursor-pointer">
                                                    <div className="flex justify-between px-4 sm:px-6 gap-4">
                                                        <div onClick={(e) => {
                                                            e.stopPropagation();
                                                            setIsCreatingNewRule(false);
                                                            setEditedRule(rule);
                                                        }}
                                                             id={`${category}-rules-list-item-${index}`}
                                                             className="w-3/4 flex-1 flex  flex-col">
                                                            <div className={"flex gap-5 items-center "}>
                                                                <p className="text-sm font-medium text-octo-600 truncate">{rule.name}</p>
                                                                <span
                                                                    className=" mx-2 my-1 px-2 py-0.5 rounded text-xs font-medium bg-octo-200 text-gray-800">{rule.score} points</span>
                                                            </div>
                                                            <div className={"flex gap-5 items-center "}>
                                                                <p className="truncate mb-2 text-sm text-gray-500">{rule.description}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className={"flex justify-end py-2 w-1/4 "}>
                                                            <Button
                                                                props={{id: `${category}-delete-rule-${index}-button`}}
                                                                size="sm" type="error" variant="solid"
                                                                LeftIcon={<TrashIcon/>}

                                                                onClick={(e: any) => {
                                                                    e.preventDefault();
                                                                    deleteRuleByIdCallBack(rule.id!);
                                                                    e.stopPropagation();
                                                                }}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>))}

                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div id="add-new-rule-button"
                         className="flex justify-center"
                         onClick={() => {
                             const newRule: Rule = new Rule();

                             setIsCreatingNewRule(true);
                             setEditedRule(newRule);
                         }}
                    >
                        {
                            (!isCreatingNewRule && !editedRule) && (<button
                                type="button"
                                className="inline-flex items-center  my-5 px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Add new rule
                            </button>)
                        }

                    </div>
                </div>
                <div id="edit-rule-form-container"
                     className={`shadow-lg rounded p-4 ${(editedRule !== undefined) ? "md:col-span-1" : "hidden"}`}>
                    {(editedRule !== undefined) ? (<EditRuleFormComponent originalRule={editedRule}
                                                                          isNewRule={isCreatingNewRule}
                                                                          closeEditRuleComponentCallBack={closeEditRuleForm}
                                                                          updateRuleDetailsCallBack={(rule) => {
                                                                              updateRuleDetailsCallBack(rule);
                                                                              setEditedRule(undefined);
                                                                          }}
                                                                          creatNewRuleCallBack={(rule) => {
                                                                              setIsCreatingNewRule(false);
                                                                              creatNewRuleCallBack(rule);
                                                                              setEditedRule(undefined);
                                                                          }}
                                                                          listOfCategories={possibleRulesCategories}
                                                                          addNewCategoryCallBack={saveNewCategoryCallBack}
                        ></EditRuleFormComponent>) :
                        <div/>}
                </div>

            </div>
        </div>
    );
}

export default ListOfScoreCardRules;