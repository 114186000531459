import React, {FC, useEffect, useState} from "react";
import {Controller, useForm, useFormState} from 'react-hook-form';
import Rule from "../../../../models/scorecard/Rule";
import {FormValidateButton} from "../../../basic/FormValidateButton/FormValidateButton";
import {DiscardFormButton} from "../../../basic/FormDiscardButton/DiscardFormButton";
import {FormErrorMessageComponent} from "../../../basic/FormErroMessage/FormErrorMessageComponent";
import {
    levelNameValidator,
    levelDescriptionValidator,
} from "./EditLevelFormValidators";
import Level from "../../../../models/scorecard/Level";
import SelectSearchInput from "./SelectSearchInput";


interface EditRuleComponentProps {
    rules : Rule[];
    originalLevel: Level;
    isNewLevel: boolean;
    closeEditLevelComponentCallBack: Function;
    selectedRulesIds : string[];
    setSelectedRulesIds(rulesIds : string[]) : void
    updateLevelDetailsCallBack(level: Level): void;

    createNewLevelCallBack(level: Level): void;
}

const EditLevelFormComponent: FC<EditRuleComponentProps> = ({
    rules,
    originalLevel,
    isNewLevel,
    selectedRulesIds,
    setSelectedRulesIds,
    closeEditLevelComponentCallBack,
    updateLevelDetailsCallBack,
    createNewLevelCallBack
                                                           }) => {

    const [updatedLevel, setUpdatedLevel] = useState({...originalLevel});

    

    const {
        handleSubmit,
        register,
        setValue,
        reset,
        clearErrors,
        setError,
        control
    } = useForm<Level>();
    const {isSubmitting, isDirty, errors,} = useFormState({
        control
    });

    
    useEffect(() => {
        // Set rule to edit
        setUpdatedLevel(originalLevel);
    }, [originalLevel]);

    useEffect(() => {
        reset(updatedLevel);
    }, [updatedLevel]);


    const saveLevel = async (savedLevel: Level) => {
        // if (!selectedRulesIds) {
            
        //     // @ts-ignore
        //     setError(rulesFieldKey, {
        //         message: "One rule at minimum must be choosen"
        //     });
        //     return;
        // }

        if (isNewLevel) {
            createNewLevelCallBack(savedLevel);
        } else {
            updateLevelDetailsCallBack(savedLevel);
        }
        reset();
    }
    const isValidateButtonDisabled = !isDirty || isSubmitting;
    return (
      <form
        id="edit-level-form"
        className="space-y-8 ml-5 p-5"
        onSubmit={handleSubmit(saveLevel)}
      >
        <div className="space-y-8 ">
          <div className="grid grid-cols-10">
            <div id="editRuleFormFields" className="col-span-9">
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
               
                <div className="sm:col-span-6">
                  <label
                    htmlFor="levelName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Level Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      {...register("name", {
                        required: {
                          value: true,
                          message: "The name is required.",
                        },
                        validate: levelNameValidator,
                      })}
                      id="level-name-field"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      defaultValue={updatedLevel.name}
                      placeholder="Name"
                    />

                    <FormErrorMessageComponent
                      errors={errors}
                      fieldName={`name`}
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label
                    htmlFor="ruleDescription"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Level Description
                  </label>
                  <div className="mt-1">
                    <textarea
                      {...register("description", {
                        required: {
                          value: true,
                          message: "The description is required.",
                        },
                        validate: levelDescriptionValidator,
                      })}
                      id="level-description-field"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      defaultValue={updatedLevel.description}
                      rows={3}
                      placeholder="Description "
                    />
                    <FormErrorMessageComponent
                      errors={errors}
                      fieldName={`description`}
                    />
                  </div>
                </div>

                {
                  <div className="sm:col-span-6">
                  
                    <div className="sm:col-span-6">
                      <label
                        htmlFor={`rules`}
                        className="block text-sm font-medium text-gray-700"
                      >
                        Rules
                      </label>
                      <div className="mt-1">
                        <Controller
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "You must choose one rule at minimum.",
                            }
                          }}
                          name={`ruleIds`}
                          render={({ field: { onChange, value, ref } }) => (
                            <SelectSearchInput
                              //   Icon={ChipIcon}
                              componentName={"Rules"}
                              options={rules.filter(rule => !selectedRulesIds.includes(rule.id!))}
                              onChange={onChange}
                              originalValues={updatedLevel ? rules.filter((rule) => originalLevel.ruleIds?.includes(rule.id!))  : []  }
                              selectedRulesIds={selectedRulesIds}
                              setSelectedRulesIds = {setSelectedRulesIds}
                            />
                          )}
                        />
                        <FormErrorMessageComponent
                          errors={errors}
                          fieldName={`ruleIds`}
                        />
                      </div>
                    </div>
                  
                  </div>
                }
                
              </div>

              <div className="flex justify-between mt-5">
                <div
                  id="discard-button"
                  onClick={() => closeEditLevelComponentCallBack()}
                >
                  <DiscardFormButton
                    buttonText={"Discard"}
                    isButtonDisabled={isValidateButtonDisabled}
                  />
                </div>
                <FormValidateButton
                  isFormSubmitting={isSubmitting}
                  isFormDirty={isDirty}
                  isButtonDisabled={isValidateButtonDisabled}
                  formId={"edit-level-form"}
                  dirtyButtonText={"Save Changes"}
                />
              </div>
            </div>
            <div className={"col-span-1 flex justify-center m-0"}>
              <div
                className={"h-6 w-6"}
                id="close-edit-level-form-button"
                onClick={() => closeEditLevelComponentCallBack()}
              >
                <button className="text-red-700 hover:bg-gray-200 h-6 w-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
}

export default EditLevelFormComponent;