import React,{ useEffect, useRef } from "react";
import * as d3 from "d3";
type ColorLegendProps = {
  height: number;
  width: number;
  colorScale: d3.ScaleLinear<string, string, never>;
};



const COLOR_LEGEND_MARGIN = { top: 38, right: 0, bottom: 38, left: 0 };

export const ColorLegend = ({
  height,
  colorScale,
  width,
}: ColorLegendProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const boundsWidth =
    width - COLOR_LEGEND_MARGIN.right - COLOR_LEGEND_MARGIN.left;
  const boundsHeight =
    height - COLOR_LEGEND_MARGIN.top - COLOR_LEGEND_MARGIN.bottom;

  const domain = colorScale.domain();
  const max = domain[domain.length - 1];
  const xScale = d3.scaleLinear().range([0, boundsWidth]).domain([0, max]);

  const allTicks = xScale.ticks(4).map((tick) => {
    return (
      <React.Fragment key={tick}>
        <line
          x1={xScale(tick)}
          x2={xScale(tick)}
          y1={0}
          y2={boundsHeight + 5}
          stroke="grey"
        />
        <text
          x={xScale(tick)}
          y={boundsHeight + 20}
          fontSize={9}
          textAnchor="middle"
        >
          {tick}
        </text>
      </React.Fragment>
    );
  });

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");

    if (!context) {
      return;
    }

    for (let i = (boundsWidth/6); i <  (boundsWidth*7/6); ++i) {
      let j = i-(boundsWidth/6);
      context.fillStyle = colorScale((max*j)/boundsWidth);
      context.fillRect(i, 0, 1, boundsHeight);
    }
  }, [width, height, colorScale]);

  return (
    <div style={{ width: `${width + boundsWidth*7/6}` , height }}>
      <div
        style={{
          position: "relative",
          transform: `translate(${COLOR_LEGEND_MARGIN.left}px,
            ${COLOR_LEGEND_MARGIN.top}px`,
        }}
      >
        <canvas ref={canvasRef} width={boundsWidth*7/6} height={boundsHeight} />
            
        <svg
          width={boundsWidth*7/6}
          height={boundsHeight}
          style={{ position: "absolute", top: 0, left: 0, overflow: "visible" }}
        >
            
                <rect height={boundsHeight} width={boundsWidth/6} fill="#E5E7EB"/>
                <line
          x1={0}
          x2={0}
          y1={0}
          y2={boundsHeight + 5}
          stroke="grey"
        />
                <text
          x={0}
          y={boundsHeight + 20}
          fontSize={9}
          textAnchor="middle"
        >
            not evaluated
        </text>
                <g transform={`translate(${boundsWidth/6},0)`}>
          {allTicks}
          </g>
        </svg>
      </div>
    </div>
  );
};
