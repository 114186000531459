import Operation from "./Operation";
import RuleCategory from "./RuleCategory";
import { v4 as uuidv4 } from 'uuid';
export default class Rule {
    constructor() {
        this.id = uuidv4();
        this.score = 1;
    }

    id?: string;
    key?: string;
    name?: string;
    description?: string;
    operation?: Operation;

    score?: number;
    ruleCategory?: RuleCategory;
    ruleExpression?: string
}