import React from "react";

interface Option {
  label: string;
  value: string;
}

interface TableOptionsProps {
  selectedTable: string;
  handleTableSelection: (table: string) => void;
}

const TableOptions: React.FC<TableOptionsProps> = ({
  selectedTable,
  handleTableSelection,
}) => {
    const options: Option[] = [
        { label: "Top Services", value: "Top Services" },
        { label: "Services at risk", value: "Services at risk" },
        { label: "Top team", value: "Top team" },
        { label: "Team at risk", value: "Team at risk" },
        { label: "Most improved services", value: "Most improved services" },
        { label: "Worst drop services", value: "Worst drop services" },
        { label: "Most improved teams", value: "Most improved teams" },
        { label: "Worst drop teams", value: "Worst drop teams" },
        { label: "Most moved Rules", value: "Most moved Rules" },
        { label: "Rules at most risk", value: "Rules at most risk" },
      ];
  return (
    <ul className="space-y-4 font-normal text-left text-gray-900 items-center cursor-pointer">
      {options.map((option) => (
        <li
          key={option.value}
          onClick={() => handleTableSelection(option.value)}
          className={
            selectedTable === option.value
              ? "bg-blue-50 rounded border-r-4 border-indigo-500 p-1"
              : "p-1"
          }
        >
          {option.label}
        </li>
      ))}
    </ul>
  );
};

export default TableOptions;
