import React from "react";
interface OverviewCardProps {
  name: String;
  value: Number;
  children?: React.ReactNode;
}

export const OverviewCardContent = ({
  name,
  value,
  children,
}: OverviewCardProps) => {
  return (
    <div className="flex  items-center justify-around px-4 py-2 space-x-4">
      <div className="flex items-center">
        <svg className="w-12 h-12" viewBox="0 0 35 35">
          <g>
            <div className="shadow-xl mask mask-hexagon-2"></div>

            <polygon
              className="bg-purple-100 "
              rx="50"
              points="8 0 27 0 35 16 27 32 8 32 0 16"
              fill="#E9D5FF"
              filter="#goo"
            />
            {children}
          </g>
        </svg>
      </div>
      <div className="mt-6 text-xl font-bold text-black  md:mt-0 dark:text-white">
        {value}
        <p className="mt-2 mb-2 text-sm font-semibold text-gray-700 dark:text-white">
          {name}
        </p>
      </div>
    </div>
  );
};
