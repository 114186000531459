import React, { ReactNode, useEffect, useState } from "react";
import ProgressBar from "../../octo-ui/ProgressBar/ProgressBar";
import EvaluationProgressItem from "../../scorecard/evaluation/EvaluationProgressItem/EvaluationProgressItem";
import { ProgressInfo } from "../../../types/ProgressInfo";

interface Props {
  children: ReactNode;
  progressInfo: ProgressInfo | null;
  showProgressTooltip: boolean;
}

const ProgressTooltipComponent: React.FC<Props> = ({
  children,
  progressInfo,
  showProgressTooltip,
}): JSX.Element => {
  const completedItemNames = progressInfo?.completedItemNames ?? [];
  const percentage = progressInfo
    ? (progressInfo.completedItems / progressInfo.totalItems) * 100
    : 0;

  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (showProgressTooltip && progressInfo && percentage !== 100) {
      if (!visible) setVisible(true);
    } else {
      const timeoutId = setTimeout(() => {
        setVisible(false);
      }, 600);
      return () => clearTimeout(timeoutId);
    }
  }, [showProgressTooltip, progressInfo, percentage, visible]);

  return (
    <div className="group text-center relative flex justify-center">
      {children}
      {visible && (
        <div className="min-w-[250px] flex items-start justify-center before:content-[''] before:absolute before:left-1/2 before:bottom-[100%] before:-translate-x-1/2 before:border-8 before:border-x-transparent before:border-t-transparent before:border-b-gray-300 absolute top-10 rounded bg-white p-2 text-xs text-gray-700 border border-gray-300">
          <div className="w-full">
            {completedItemNames.map((itemName, index) => (
              <EvaluationProgressItem
                key={index}
                itemName={itemName}
                status="done"
              />
            ))}
            {(progressInfo?.completedItems ?? 0) <
              (progressInfo?.totalItems ?? 0) && (
              <EvaluationProgressItem
                itemName={progressInfo?.currentItem ?? ""}
                status="inProgress"
              />
            )}
            <div className="mt-2">
              <ProgressBar percentage={percentage} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgressTooltipComponent;
