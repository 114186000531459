export type CellValue = string | number | JSX.Element;

type Cell = {
    value: CellValue;
    className?: string;
};

export interface Column {
    title: string;
    dataIndex: string;
    className?: string;
}

export interface TableRow {
    [key: string]: CellValue;
}

interface TableProps {
    columns: Column[];
    dataSource: TableRow[];
    rowClassName?: string;
}
const Table: React.FC<TableProps> = ({ columns, dataSource, rowClassName }) => (
    <table className="min-w-full overflow-x-auto shadow-md rounded-lg" data-testid="data-table">
        <thead className="bg-gray-50 sticky top-0">
            <tr>
                {columns.map((column, idx) => (
                    <th
                        key={idx}
                        scope="col"
                        className={`px-8 py-4 text-md font-semibold text-gray-600 tracking-wider ${column.className} bg-slate-50`}
                    >
                        {column.title}
                    </th>
                ))}
            </tr>
        </thead>
        <tbody className="bg-white divide-gray-200 border-none">
            {dataSource.map((data, index) => (
                <tr key={index} className={`bg-blue-50 ${rowClassName}`}>
                    {columns.map((column, idx) => {
                        const cell: Cell = {
                            value: data[column.dataIndex],
                            className: column.className,
                        };
                        return (
                            <td key={idx} className={`px-8 py-4 text-md text-center border-2 border-white rounded-xl ${cell.className}`}>
                                {cell.value}
                            </td>
                        );
                    })}
                </tr>
            ))}
        </tbody>
    </table>
);

export default Table;
