import Keycloak from "keycloak-js";

const envSettings = window as any;

const { REACT_APP_KEYCLOAK_URL, REACT_APP_KEYCLOAK_REALM, REACT_APP_KEYCLOAK_CLIENT_ID } = envSettings ?? {};

const keycloak = Keycloak({
    url: REACT_APP_KEYCLOAK_URL,
    realm: (REACT_APP_KEYCLOAK_REALM)!,
    clientId: (REACT_APP_KEYCLOAK_CLIENT_ID)!,
});

export default keycloak;