import React, {FC} from "react";
import {Control, Controller} from "react-hook-form";
import BinaryOperator from "../../../models/scorecard/BinaryOperator";
import Rule from "../../../models/scorecard/Rule";
import Select from "react-select";
import ValueObject from "../../../models/scorecard/ValueObject";
import {FormErrorMessageComponent} from "../FormErroMessage/FormErrorMessageComponent";

interface GenericRuleOperandFieldComponentProps {
    returnType: "BOOLEAN" | "STRING" | "DOUBLE",
    binaryOperatorInitialValue?: BinaryOperator,
    targetOperandInitialValue?: ValueObject,
    formControl: Control<Rule, any>,
}

const GenericRuleOperandFieldComponent: FC<GenericRuleOperandFieldComponentProps> = ({
                                                                                         returnType,
                                                                                         formControl,
                                                                                         binaryOperatorInitialValue,
                                                                                         targetOperandInitialValue
                                                                                     }) => {

    const shouldRenderBinaryOperatorSelect = (returnType === "DOUBLE");
    const binaryOperatorOptions = Object.keys(BinaryOperator).map(key => {
        return {
            key,
            // @ts-ignore
            value: BinaryOperator[key]
        }
    });
    // @ts-ignore
    const initialValueObject = binaryOperatorOptions.find((o) => o.value === binaryOperatorInitialValue);


    const shouldRenderBooleanSelect = (returnType === "BOOLEAN");
    const booleanSelectOptions = [
        {
            key: "Positive", valueObject: {
                value: "TRUE",
                type: "BOOLEAN"
            }
        },
        {
            key: "Negative", valueObject: {
                value: "FALSE",
                type: "BOOLEAN"
            }
        }];
    const initialBooleanSelectValue = booleanSelectOptions
        .find((option) => option.valueObject.value === targetOperandInitialValue?.value);


    const shouldRenderTargetOperandTextField = (returnType === "STRING") || (returnType === "DOUBLE");
    const getInputFieldProps = () => {
        switch (returnType) {
            case "DOUBLE":
                return {type: "number", step: "any"};
            default:
                return {type: "text"};
        }
    };
    return (
        <>

            {
                shouldRenderBinaryOperatorSelect && (
                    <div className="sm:col-span-6">
                        <label htmlFor={`operation.binaryOperator`} className="block text-sm font-medium text-gray-700">
                            Choose a binary operator
                        </label>
                        <Controller

                            control={formControl}
                            rules={{
                                required: {
                                    value: true,
                                    message: `BinaryOperator is a required parameter for this rule.`
                                }
                            }}
                            name={`operation.binaryOperator`}
                            render={({field: {onChange}, formState: {errors}}) => (
                                <>
                                    <Select
                                        id={"binary-operator-select-field"}
                                        classNamePrefix={"rule_binary_operator_field"}
                                        options={binaryOperatorOptions}
                                        defaultValue={initialValueObject}
                                        onChange={(newValue) => {
                                            onChange(newValue?.value);
                                        }}
                                        getOptionLabel={option => option.key!}
                                        getOptionValue={option => option.value!}
                                    />

                                    <FormErrorMessageComponent errors={errors}
                                                               fieldName={`operation.binaryOperator`}/>
                                </>
                            )}/>

                    </div>
                )
            }
            {
                shouldRenderTargetOperandTextField && (

                    <div className="sm:col-span-6">
                        <label htmlFor={`operation.targetOperand`}
                               className="block text-sm font-medium text-gray-700">
                            Expected result:
                        </label>
                        <div className="mt-1">
                            <Controller

                                control={formControl}
                                rules={{
                                    required: {
                                        value: true,
                                        message: `Expected result is a required parameter for this rule.`
                                    }
                                }}

                                name={`operation.targetOperand`}
                                render={({field: {onChange, ref}, formState: {errors}}) => (
                                    <>

                                        <input
                                            id={`target-operand-input-field`}

                                            defaultValue={targetOperandInitialValue?.value}

                                            ref={ref}
                                            onChange={(e) => {
                                                onChange({
                                                    value: e.target.value, type: returnType
                                                });
                                            }}
                                            {...getInputFieldProps()}
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        />

                                        <FormErrorMessageComponent errors={errors}
                                                                   fieldName={`operation.targetOperand`}/>
                                    </>
                                )}
                            />


                        </div>
                    </div>
                )
            }
            {
                shouldRenderBooleanSelect && (
                    <div className="sm:col-span-6">

                        <label htmlFor={`operation.targetOperand`} className="block text-sm font-medium text-gray-700">
                            Expected result
                        </label>
                        <Controller
                            control={formControl}
                            rules={{
                                required: {
                                    value: true,
                                    message: `Expected result is a required parameter for this rule.`
                                }
                            }}
                            name={`operation.targetOperand`}
                            render={({field: {onChange}, formState: {errors}}) => (
                                <>
                                    <Select
                                        classNamePrefix={"rule_target_operand_field"}
                                        id={`target-operand-input-field`}
                                        options={booleanSelectOptions}
                                        defaultValue={initialBooleanSelectValue}
                                        onChange={(newValue) => {
                                            onChange(newValue?.valueObject);
                                        }}
                                        getOptionLabel={option => option.key!}
                                        getOptionValue={option => option.valueObject!.value}
                                    />

                                    <FormErrorMessageComponent errors={errors}
                                                               fieldName={`operation.targetOperand`}/>
                                </>
                            )}/>


                    </div>
                )
            }

        </>
    );
}

export default GenericRuleOperandFieldComponent;