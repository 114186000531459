import clsx from "clsx";
import React, {FC} from "react";

interface NavItemProps {
  className?: string;
  current?: boolean;
  Component?: React.ElementType;
  to?: string;
}

const NavItem: FC<NavItemProps> = ({ children, current, Component, ...props }) => {
  const style = clsx(
    "text-sm leading-5 font-semibold tracking-tight flex items-center py-2 px-4 justify-center rounded-md transition-colors duration-200 ease-in-out cursor-pointer",
    {
      "text-white bg-gray-900": current,
      "text-gray-300 hover:bg-gray-700 ": !current,
    }
  );
  return (
    <li>
      {!Component ? (
        <a className={style} {...props}>
          {children}
        </a>
      ) : (
        <Component className={style} {...props}>
          {children}
        </Component>
      )}
    </li>
  );
};

NavItem.defaultProps = {
  current: false,
  Component: undefined,
};

export { NavItem };
