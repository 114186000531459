import React, {FC, Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";
import Box from "../../../octo-ui/Box/Box";
import {Avatar} from "../../../octo-ui/Avatar/Avatar";
import {Link} from "react-router-dom";
import clsx from "clsx";
import {ADMIN_USER_NAVIGATION} from "../../../../routes/routes";
import UserAvatarSvg from '../../../../assets/svg/user_avatar.svg';

// random avatar
export const userProfilePicture = 'https://joeschmoe.io/api/v1/random';

const UserMenu: FC<any> = ({logout}) => {
    return (
        <Menu as="div" className="ml-3 relative">
            <Box>
                <Menu.Button
                    data-testid="user-avatar"
                    className="max-w-xs bg-transparent rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span className="sr-only">Open user menu</span>
                    <Avatar size={"xs"}
                            src={UserAvatarSvg}
                            alt=""
                    />
                </Menu.Button>
            </Box>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {ADMIN_USER_NAVIGATION.map((item) => (
                        <Menu.Item key={item.name}>
                            {({active}) => (
                                <Link
                                    to={item.path}
                                    className={clsx(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                >
                                    {item.name}
                                </Link>
                            )}
                        </Menu.Item>
                    ))}
                    <Menu.Item key={"Logout"}>
                        {({active}) => (
                            <Link
                                data-testid="logout-button"
                                to={'#'}
                                className={clsx(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                )}
                                onClick={(e) => {
                                    e.preventDefault();
                                    logout();
                                }}
                            >
                                Logout
                            </Link>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default UserMenu;