import ServiceScores from "../models/leaderboard/ServiceScores";
import Service from "../models/service/Service";

export interface ServiceScoreRank {
  service: Service;
  points: number;
  totalPercentage: number;
}
export const generateServicesRanks = (
  servicesScores: ServiceScores[],
): ServiceScoreRank[] => {
  const servicesRanks: ServiceScoreRank[] = [];

  servicesScores.forEach((serviceScore) => {
    const service = serviceScore.service;
    const scores = serviceScore.scores;
    const points = scores.reduce((acc, score) => acc + score.score, 0);
    const totalPercentage = Math.round(
      (100 * points) /
        scores.reduce((acc, score) => acc + score.totalPossible, 0)
    );

    servicesRanks.push({ service, points, totalPercentage });
  });

  return servicesRanks;
};
