import Box from '../Box/Box';
import React, {FC} from "react";

interface HeaderProps {
  children?: React.ReactNode;
}

const Header: FC<HeaderProps> = ({ children }) => {
  return (
    <Box className="w-full h-16 flex flex-row justify-between text-white items-center px-4 sm:px-6 lg:px-8 bg-gray-800">
      {children}
    </Box>
  );
};

export default Header;