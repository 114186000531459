import OwnerServicesScores from "../models/dashborad/OwnerServicesScores";

export interface OwnerRank {
  owner: string;
  totalService: number;
  points: number;
  scorePercentage: number;
}
export const generateOwnersRanks = (
  ownersServicesScores: OwnerServicesScores[]
): OwnerRank[] => {
  const ownerRank: OwnerRank[] = [];

  ownersServicesScores.forEach((ownerServicesScores) => {
    let points = 0;
    let scorePercentage = 0;
    ownerServicesScores.serviceScores.forEach((serviceScore) => {
      const scores = serviceScore.scores;
      points += scores.reduce((acc, score) => acc + score.score, 0);
      scorePercentage += Math.round(
        (100 * points) /
          scores.reduce((acc, score) => acc + score.totalPossible, 0)
      );
    });

    const totalService = ownerServicesScores.serviceScores
      ? ownerServicesScores.serviceScores.length
      : 0;
    const owner = ownerServicesScores.owner.name;
    scorePercentage = scorePercentage / totalService;
    ownerRank.push({ owner, totalService, points, scorePercentage });
  });

  return ownerRank;
};
