import React, {FC} from "react";
import {Link, useLocation} from "react-router-dom";
import {Disclosure} from "@headlessui/react";
import {ADMIN_ROUTES, ADMIN_USER_NAVIGATION} from "../../../../routes/routes";
import {classNames} from "../../../../utils/classNameUtils";
import {userProfilePicture} from "./UserMenu";

interface Props {
    user: any;
    logout: Function;
}

const AdminMobilePanel: FC<Props> = ({user, logout}) => {
    const {pathname} = useLocation();

    return (
        <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {ADMIN_ROUTES.filter(r => r.isPrivate).map((item) => !!item.showInNav && (
                    <Disclosure.Button
                        key={item.name}
                        className={classNames(
                            item.path === pathname ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-900 hover:text-white',
                            'block px-3 py-2 rounded-md text-base font-medium'
                        )}
                        aria-current={item.path === pathname ? 'page' : undefined}
                    >
                        <Link to={item.path}>
                            {item.name}
                        </Link>
                    </Disclosure.Button>
                ))}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-400">
                <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src={userProfilePicture} alt=""/>
                    </div>
                    <div className="ml-3">
                        <div
                            className="text-base font-medium leading-none text-white">{user?.name}</div>
                        <div
                            className="text-sm font-medium leading-none text-gray-400">{user?.email}</div>
                    </div>
                </div>
                <div className="mt-3 px-2 space-y-1">
                    {ADMIN_USER_NAVIGATION.map((item) => (
                        <Disclosure.Button
                            key={item.name}
                            className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-900"
                        >
                            <Link to={item.path}>
                                {item.name}
                            </Link>
                        </Disclosure.Button>
                    ))}
                    <Disclosure.Button
                        key="Logout"
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-900 w-full"
                    >
                        <div
                            data-testid="mobile-logout-button"
                            onClick={() => {
                                logout();
                            }}
                        >Logout</div>
                    </Disclosure.Button>
                </div>
            </div>
        </Disclosure.Panel>
    );
};

export default AdminMobilePanel;