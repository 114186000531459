import React, {FC} from "react";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {
    useServiceEvaluationsHistoryByScoreCard
} from "../../../../../hooks/evaluations/useServiceEvaluationsHistoryByScoreCard";
import {RefreshIcon} from "@heroicons/react/solid";
import {
    getServiceEvaluationsDataFromServiceEvaluationsHistoryByScoreCard
} from "../../../../../services/evaluations/ScoreCardEvaluations.helper";
import CustomTooltip from "../../../../../pages/admin/scorecards/modules/CustomTooltip";

export interface ServiceScoreProgressPerScoreCardComponentProps {
    scoreCardId: string,
    serviceId: string
}

const ServiceScoreProgressPerScoreCardComponent: FC<ServiceScoreProgressPerScoreCardComponentProps> = ({
                                                                                                           scoreCardId,
                                                                                                           serviceId
                                                                                                       }) => {
    const {
        data: evaluationsHistory,
        isSuccess: isEvaluationsHistory,
        isLoading: isLoadingEvaluationsHistory
    } = useServiceEvaluationsHistoryByScoreCard(scoreCardId, serviceId);
    return (
        <div className="mx-auto p-4 sm:px-6 lg:px-8">
            <h2 className="text-lg leading-6 font-medium text-gray-900 ">Score Progress</h2>
            {
                isLoadingEvaluationsHistory && (<RefreshIcon className={"animate-spin h-5 w-5 mx-auto"}/>)
            }

            {
                isEvaluationsHistory && evaluationsHistory && (
                    <ResponsiveContainer
                        width={"80%"}
                        height={300}
                        className={"mx-auto mt-2"}
                    >

                        <LineChart
                            data={getServiceEvaluationsDataFromServiceEvaluationsHistoryByScoreCard(evaluationsHistory)}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey={"evaluationTime"} hide={true}/>
                            <YAxis/>
                            <Tooltip content={<CustomTooltip />} />
                            <Legend verticalAlign="top" />
                            <Line type="monotone" dataKey="evaluationScore" stroke="#8884d8" activeDot={{r: 8}}/>
                        </LineChart>

                    </ResponsiveContainer>)}
        </div>
    );
}

export default ServiceScoreProgressPerScoreCardComponent;