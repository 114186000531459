import React, {FC, useEffect, useState} from "react";
import Service from "../../../../models/service/Service";
import ServiceOverviewPerScoreCardComponent from "./ServiceOverviewPerScoreCard/ServiceOverviewPerScoreCardComponent";
import ServiceScoreProgressPerScoreCardComponent
    from "./ServiceOverviewPerScoreCard/ServiceScoreProgressPerScoreCardComponent";
import ServiceRulesListPerScoreCardComponent from "./ServiceOverviewPerScoreCard/ServiceRulesListPerScoreCard";
import ServiceScoreCardStats from "../../../../models/evaluations/ServiceStatsByScoreCard";
import {getServiceStatsFromScoreCardListOfLastEvaluations} from "../../../../services/evaluations/ScoreCardEvaluations.helper";
import {ScoreCardEvaluation} from "../../../../models/evaluations/ScoreCardEvaluation";
import {useServices} from "../../../../hooks/useServices";
import {RefreshIcon} from "@heroicons/react/solid";
import ServiceLevelsPerScoreCardComponent from "./ServiceOverviewPerScoreCard/ServiceLevelsPerScorecardComponent";
import { useSingleScoreCard } from "../../../../hooks/scorcards/useSingleScoreCard";


interface ScoreCardServicesListComponentProps {
    scoreCardId: string
    scoreCardLastEvaluations: ScoreCardEvaluation[];
}

const ScoreCardServicesListComponent: FC<ScoreCardServicesListComponentProps> = ({
                                                                                     scoreCardId,
                                                                                     scoreCardLastEvaluations
                                                                                 }) => {

    const evaluatedServicesIdsList: string[] = scoreCardLastEvaluations.map(e => e.serviceId);
    const {
        data: servicesData,
        isSuccess: isServicesDataLoaded,
        isLoading: isLoadingServicesData
    } = useServices();
    const {
        data: currentScoreCard,
        isSuccess: isScoreCardDataLoaded,
        isLoading: isLoadingScoreCardData
    } = useSingleScoreCard(scoreCardId);

    const [shownServicesData, setShownServicesData] = useState<Service[] | undefined>(servicesData?.filter(s => evaluatedServicesIdsList.includes(s.id)) ?? []);
    const [selectedService, setSelectedService] = useState<Service | undefined>();
    const [selectServiceStats, setSelectedServiceStats] = useState<ServiceScoreCardStats | undefined>();

    const [activeTab, setActiveTab] = useState('overview');

    useEffect(() => {
        if (selectedService) {
            setSelectedServiceStats(getServiceStatsFromScoreCardListOfLastEvaluations(selectedService.id, scoreCardLastEvaluations));
        }
    }, [selectedService, scoreCardLastEvaluations]);

    useEffect(() => {
        setShownServicesData(servicesData?.filter(s => evaluatedServicesIdsList.includes(s.id)) ?? []);
    }, [servicesData]);

    useEffect(() => {
        setSelectedService(shownServicesData?.at(0));
    }, [shownServicesData]);
    return (<>
        <div id="scorecard-overview-container" className={"space-y-4"}>
            <div className="mx-auto p-4 sm:px-6 lg:px-8">
                <h2 className="text-lg leading-6 font-medium text-gray-900">Services</h2>
                {
                    (evaluatedServicesIdsList.length == 0) && (
                        <div className={"text-center"}>
                            No services were evaluated by this scorecard.
                        </div>
                    )
                }
                {isLoadingServicesData && isLoadingScoreCardData &&
                    (<RefreshIcon className={"animate-spin h-5 w-5 mx-auto"}/>)}
                {
                    isServicesDataLoaded && isScoreCardDataLoaded &&
                    (<div className="mt-5 lg:grid lg:grid-cols-4 gap-5">

                            <div
                                className="flex justify-center lg:justify-start lg:flex-col overflow-x-auto lg:overflow-y-auto shadow">
                                {
                                    shownServicesData?.map((item, index) => (
                                        <div
                                            key={item.id}
                                            id={`services-list-item-${index}`}
                                            onClick={() => {
                                                setSelectedService(item);
                                            }}
                                            className={
                                                `flex-shrink ${item.id === selectedService?.id
                                                    ? 'bg-indigo-50 border-indigo-600 text-indigo-600'
                                                    : 'border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900'}
                                            'group flex items-center px-3 py-2 text-sm font-medium border-l-4 cursor-pointer`
                                            }
                                        >
                                            {item.name}
                                        </div>
                                    ))}
                            </div>
                            <div className="col-span-3 shadow">
                                <div className="flex mb-4">
                                    <button
                                        className={`px-4 py-2 ${activeTab === 'overview' ? 'text-white bg-blue-500' : ''}`}
                                        onClick={() => setActiveTab('overview')}
                                    >
                                        Overview
                                    </button>
                                    <button
                                        className={`px-4 py-2 ${activeTab === 'rules' ? 'text-white bg-blue-500' : ''}`}
                                        onClick={() => setActiveTab('rules')}
                                    >
                                        Rules
                                    </button>
                                    <button
                                        className={`px-4 py-2 ${activeTab === 'levels' ? 'text-white bg-blue-500' : ''}`}
                                        onClick={() => setActiveTab('levels')}
                                    >
                                        Service Levels
                                    </button>
                                    <button
                                        className={`px-4 py-2 ${activeTab === 'score' ? 'text-white bg-blue-500' : ''}`}
                                        onClick={() => setActiveTab('score')}
                                    >
                                        Trends
                                    </button>
                                </div>

                                {activeTab === 'overview' && selectedService && selectServiceStats && (
                                    <ServiceOverviewPerScoreCardComponent
                                        overViewData={selectServiceStats.overview}
                                    />
                                )}
                                {activeTab === 'levels' && selectedService && selectServiceStats && currentScoreCard && (
                                    <ServiceLevelsPerScoreCardComponent
                                        rules={selectServiceStats.rulesStatus} levels={currentScoreCard.levels}
                                    />
                                )}
                                {activeTab === 'rules' && selectedService && selectServiceStats && (
                                    <ServiceRulesListPerScoreCardComponent
                                        rulesStatusByCategory={selectServiceStats.rulesStatus}
                                    />
                                )}
                                {activeTab === 'score' && selectedService && selectServiceStats && (
                                    <ServiceScoreProgressPerScoreCardComponent
                                        scoreCardId={scoreCardId} serviceId={selectedService.id}
                                    />
                                )}
                            </div>
                        </div>
                    )
                }
            </div>

        </div>
    </>);
}

export default React.memo(ScoreCardServicesListComponent);