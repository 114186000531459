import { useQuery } from "react-query";
import { ScoreCardEvaluation } from "../../models/evaluations/ScoreCardEvaluation";
import { getFilteredScoreCardEvaluations } from "../../services/evaluations/evaluations.service";
import { useAxios } from "../useAxios";

export const useFilteredEvaluations = (
    scoreCardId: string,
    serviceId: string,
    startDate: number,
    endDate: number
  ) => {
    const { axios } = useAxios();
  
    return useQuery<ScoreCardEvaluation[], Error>(
      ["filteredEvaluations", scoreCardId, serviceId, startDate, endDate],
      (): Promise<ScoreCardEvaluation[]> =>
        getFilteredScoreCardEvaluations(
          axios,
          scoreCardId,
          serviceId,
          startDate,
          endDate
        ),
      {
        enabled:
        scoreCardId !== "" &&
        serviceId !== "" &&
        startDate !== undefined &&
        endDate !== undefined,    
      }
    );
  };
  
export default useFilteredEvaluations;