import {ErrorMessage} from "@hookform/error-message";
import React, {FC} from "react";
import {FieldErrors} from "react-hook-form";


interface FormErrorMessageComponentProps {
    errors:  any,
    fieldName: string
}

export const FormErrorMessageComponent: FC<FormErrorMessageComponentProps> = ({errors, fieldName}) => {

    return (<ErrorMessage errors={errors} name={fieldName} render={({message}) => (<span
        className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                        {message}
                                                    </span>)}/>);
}