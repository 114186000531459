import {useQuery} from "react-query";
import ServiceScores from "../../models/leaderboard/ServiceScores";
import { getAllServicesScoresByTimeRange } from "../../services/services-scores";
import { useAxios } from "../useAxios";


export const SERVICES_SCORES_QUERY = "all_services_scores";

export const useServiceScoresDashboard = (timeRange:string,scorecardId:string) => {
    const {axios} = useAxios();
    const query= useQuery<ServiceScores[], Error>([SERVICES_SCORES_QUERY], () => getAllServicesScoresByTimeRange(axios,timeRange,scorecardId));
    const { refetch } = query;

  return { ...query, refetch };
}