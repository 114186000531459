import LeaderServiceCardSkeleton from "../../../../components/basic/LeaderServiceCard/LeaderServiceCardSkeleton";
import ScorecardsFilterComponentSkeleton from "../../leaderboard/modules/ScorecardsFilterComponentSkeleton";

const LeaderServicesWidgetSkeleton = () => {
    return (
      <div className="p-2">
        <ScorecardsFilterComponentSkeleton />
        <div className="mt-2">
          {[...Array(5)].map((_, i) => (
            <LeaderServiceCardSkeleton key={i} />
          ))}
        </div>
      </div>
    );
};

export default LeaderServicesWidgetSkeleton;