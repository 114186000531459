import {AxiosInstance} from "axios";
import ServiceScores from "../models/leaderboard/ServiceScores";
import OwnerServicesScores from "../models/dashborad/OwnerServicesScores";

export const getAllServicesScores = async (axios: AxiosInstance): Promise<ServiceScores[]> => {
    const response = await axios.get<ServiceScores[]>("/score/services");
    return response.data;
};
export const getAllServicesScoresByTimeRange = async (axios: AxiosInstance,rangeTime:string,scorecardId:string): Promise<ServiceScores[]> => {
    const response = await axios.get<ServiceScores[]>(`/score/services/by_time_range?timeRange=${rangeTime}&scorecardId=${scorecardId}`);
    return response.data;
};

export const getAllServicesScorefirstAndLastByTimeRange = async (axios: AxiosInstance,rangeTime:string,scorecardId:string): Promise<ServiceScores[]> => {
    const response = await axios.get<ServiceScores[]>(`/score/services/first_and_last?timeRange=${rangeTime}&scorecardId=${scorecardId}`);
    return response.data;
};
export const getAllOwnersServicesScoresByTimeRange = async (axios: AxiosInstance,rangeTime:string,scorecardId:string): Promise<OwnerServicesScores[]> => {
    const response = await axios.get<OwnerServicesScores[]>(`/score/owners?timeRange=${rangeTime}&scorecardId=${scorecardId}`);
    return response.data;
};
export const getAllOwnersServicesScorefirstAndLastByTimeRange = async (axios: AxiosInstance,rangeTime:string,scorecardId:string): Promise<OwnerServicesScores[]> => {
    const response = await axios.get<OwnerServicesScores[]>(`/score/owners/first_and_last?timeRange=${rangeTime}&scorecardId=${scorecardId}`);
    return response.data;
};