import {useForm, useFormState} from "react-hook-form";
import React, {FC} from "react";
import {DiscardFormButton} from "../../../../components/basic/FormDiscardButton/DiscardFormButton";
import {FormValidateButton} from "../../../../components/basic/FormValidateButton/FormValidateButton";
import IntegrationTemplate from "../../../../models/integration/IntegrationTemplate";
import GenericConfigParamField from "./GenericConfigParamField/GenericConfigParamField";

interface ConfigFormProps {
    config: any,
    currentTemplate: IntegrationTemplate,
    configStateView: any,
    saveConfig: any
}

const ConfigForm: FC<ConfigFormProps> = ({config, configStateView, currentTemplate, saveConfig}) => {
    const {
        handleSubmit,
        register,
        reset,
        control
    } = useForm<any>(config ? {defaultValues: config} : {});
    const {isSubmitting, isDirty, errors} = useFormState({
        control
    });

    const isValidateButtonDisabled = !isDirty || isSubmitting;

    const cancelChanges = () => {
        reset();
    }

    return (
        <form id="config-form" className="relative lg:h-[594px] overflow-auto divide-y divide-gray-200 lg:col-span-10"
              onSubmit={handleSubmit(saveConfig)}>
            <div className="py-6 px-4 sm:p-6 sm:px-24 lg:pb-8">
                <div className="pb-4">
                    <h2 className="text-lg leading-6 font-medium text-gray-900">{currentTemplate.name}</h2>
                    <p className="mt-1 text-sm text-gray-500">
                        {currentTemplate.description}
                    </p>
                    {configStateView}
                </div>

                <div className="mt-6 grid grid-cols-12 gap-6">

                    {currentTemplate.configParams!.map(param => {
                        return (
                            <div className="col-span-12" key={param.key}>
                                <label htmlFor="token" className="block text-sm font-medium text-gray-700">
                                    {param.name}
                                </label>
                                <GenericConfigParamField configParam={param} control={control} initialValue={config ? config[param.key] : undefined} />
                            </div>
                        );
                    })}

                </div>
            </div>

            <div className=" lg:bottom-0 w-full">
                <div className="mt-4 py-4 px-4 flex justify-end sm:px-6 gap-4">
                    <DiscardFormButton buttonText={"Discard"}
                                       isButtonDisabled={isValidateButtonDisabled}
                                       onClick={cancelChanges}/>
                    <FormValidateButton isFormSubmitting={isSubmitting} isFormDirty={isDirty}
                                        isButtonDisabled={isValidateButtonDisabled}
                                        dirtyButtonText={"Save"}
                                        formId={"config-form"}
                    />
                </div>
            </div>
        </form>
    );
}

export default ConfigForm;