import clsx from "clsx";
import {UserGroupIcon, XIcon} from "../Icons/solid";
import React, {FC, useState} from "react";

const BadgeCloseButton: FC<any> = ({className, onClose}) => {
    return (
        <button type="button" className={className} onClick={onClose}>
            <XIcon className="h-3 w-3"/>
        </button>
    );
};

const BadgeDot: FC<any> = ({className}) => {
    return (
        <svg className={className} fill="currentColor" viewBox="0 0 8 8">
            <circle cx={4} cy={4} r={3}/>
        </svg>
    );
};

const BadgeTeam: FC<any> = ({className}) => {
    return (
        <svg className={className} fill="currentColor" viewBox="0 0 8 8">
            <UserGroupIcon/>
        </svg>
    );
};

interface BadgeIconProps {
    Icon: FC<any>;
    className?: string;
}

const BadgeIcon: FC<BadgeIconProps> = ({Icon, className}) => {
    return (
        <svg className={className} fill="currentColor" viewBox="0 0 8 8">
            <Icon/>
        </svg>
    );
};


interface BadgeProps {
    className?: string;
    type?: "basic" | "rounded";
    size: "small" | "large";
    theme?: "gray" | "red" | "yellow" | "green" | "orange" | "blue" | "indigo" | "purple" | "pink";
    onClose?: Function;
    dot?: boolean;
    team?: boolean;
    remove?: boolean;
    Icon?: FC<any>;
}

const Badge: FC<BadgeProps> = ({
                                   children,
                                   type = "rounded",
                                   size,
                                   theme = "indigo",
                                   dot,
                                   team,
                                   Icon,
                                   remove,
                                   onClose,
                                   className
                               }) => {
    const [isClosed, setIsClosed] = useState(false);

    const handleCloseBadge = () => {
        if (!!onClose) {
            onClose();
            setIsClosed(true);
        }
    };

    const spanClasses = clsx(
        `${className} inline-flex items-center font-medium bg-${theme}-100 text-${theme}-800`,
        {
            "px-2.5 py-0.5 m-0.5 text-xs": size === "small",
            "px-3 py-0.5 m-0.5 text-sm": size === "large",
            "rounded-full": type === "basic",
            rounded: type === "rounded",
        }
    );

    const dotClasses = clsx(`mr-1.5 h-2 w-2 text-${theme}-400`, {
        "-ml-0.5": size === "small",
        "-ml-1": size === "large",
    });

    const teamClasses = clsx(`mr-1.5 h-4 w-4 text-${theme}-400`, {
        "-ml-0.5": size === "small",
        "-ml-1": size === "large",
    });

    const removeClasses = clsx(
        `flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex  items-center justify-center focus:outline-none focus:text-white text-${theme}-400 hover:bg-${theme}-200 hover:text-${theme}-500 focus:bg-${theme}-500`
    );

    if (isClosed) {
        return null;
    }

    let iconView;

    if (!!team) iconView = <BadgeTeam className={teamClasses}/>;
    else if (!!Icon) iconView = <BadgeIcon Icon={Icon} className={teamClasses}/>;
    else if (!!dot) iconView = <BadgeDot className={dotClasses}/>;

    return (
        <span className={spanClasses}>
      {iconView}
            {children}
            {remove && (
                <BadgeCloseButton
                    className={removeClasses}
                    onClose={handleCloseBadge}
                />
            )}
    </span>
    );
};

export default Badge;
