import React, {FC} from "react";

interface BadgeProps {
    Icon: FC<any>;
    text?: string;
}

const IconBadge: FC<BadgeProps> = ({Icon, text}) => {
    return (
        <div aria-label="components"
             className="mb-3 border rounded-full px-3 py-1 text-gray-700 border-gray-700 text-xs flex items-center">
            <Icon className="w-4 h-4"/>
            <p className="ml-2">{text}</p>
        </div>
    );
}

export default IconBadge;