import Box from "../octo-ui/Box/Box";
import React, {FC} from "react";

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

const Card: FC<CardProps> = ({children, className}) => {
  return (
    <Box className={`rounded-md border border-gray-200 bg-white shadow-md transition-all duration-300 ease-in-out hover:shadow-xl focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-gray-500  ${className}`}>
      {children}
    </Box>
  );
};

export default Card;