import {ConfigParam} from "../../../../../models/integration/IntegrationTemplate";
import {Control, Controller} from "react-hook-form";
import React, {FC} from "react";
import Select from "react-select";
import {FormErrorMessageComponent} from "../../../../../components/basic/FormErroMessage/FormErrorMessageComponent";

interface GenericConfigParamFieldProps {
    configParam: ConfigParam,
    control?: Control<any>,
    initialValue?: any
}

const GenericConfigParamField: FC<GenericConfigParamFieldProps> = ({
                                                                       configParam,
                                                                       initialValue,
                                                                       control
                                                                   }) => {
    const getInputTypeByArgumentType = (argumentType: "BOOLEAN" | "STRING" | "DOUBLE" | "INTEGER" | undefined): string => {
        switch (argumentType) {
            case "DOUBLE": case "INTEGER":
                return "number";
            default:
                return "text"
        }
    }

    return (
        <>
            <Controller

                control={control}
                rules={{
                    required: {
                        value: !configParam.notRequired,
                        message: `This field is required.`
                    }
                }}

                name={`${configParam.key}`}
                render={({field: {onChange, ref}, formState: {errors}}) => {
                    if(configParam.type === "LIST"){
                        const options = Object.entries(configParam.values).map(([key, value]) => ({value: key, label: value} as any));

                        return (
                            <>
                                <Select
                                    data-testid={`input.${configParam.key}`}
                                    id={`input.${configParam.key}`}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    defaultValue={options.find(o => o.value === initialValue)}
                                    isSearchable={true}
                                    isMulti={configParam.multiple}
                                    ref={ref}
                                    options={options}
                                    onChange={(newValue: any) => {
                                        onChange(newValue.value);
                                    }}
                                />

                                <FormErrorMessageComponent errors={errors}
                                                           fieldName={`${configParam.key}`}/>
                            </>);
                    }
                    return (
                        <>

                            <input
                                data-testid={`input.${configParam.key}`}
                                id={`input.${configParam.key}`}

                                defaultValue={initialValue}
                                ref={ref}
                                onChange={(e) => {
                                    onChange(e.target.value);
                                }}
                                type={configParam.secret ? "password" : getInputTypeByArgumentType(configParam.type)}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />

                            <FormErrorMessageComponent errors={errors}
                                                       fieldName={`${configParam.key}`}/>
                        </>
                    );
                }}
            />
        </>
    );
}

export default GenericConfigParamField;