import {generatePath, Link} from "react-router-dom";
import React, {FC} from "react";
import ScoreCard from "../../models/scorecard/ScoreCard";
import {AdjustmentsIcon, KeyIcon} from "../octo-ui/Icons/outline";

import IconBadge from "./modules/IconBadge";
import Box from "../octo-ui/Box/Box";
import Card from "../octo-ui/Card/Card";
import {ADMIN_SCORECARD_DETAILS_PAGE} from "../../routes/paths";
import {getListOfUniqueCategoriesFromListOfRules} from "../../services/scorecards/scorecards.helper";

interface ScorecardComponentProps {
    scorecard: ScoreCard;
}

const ScorecardComponent: FC<ScorecardComponentProps> = ({scorecard}) => {
    const scoreCardDetailsPath = generatePath(ADMIN_SCORECARD_DETAILS_PAGE, {
        id: scorecard.id,
    });
    const numberOfRules = scorecard.rules?.length ?? 0;
    const numberOfCategories = getListOfUniqueCategoriesFromListOfRules(scorecard.rules ?? []).length;
    return (
        <Link to={{pathname: scoreCardDetailsPath}} aria-label={"scorecard-name"}>
            <Card className="w-full h-full flex flex-col justify-between items-start gap-5 mb-6 py-5 px-4">
                <Box>
                    <h4 className="text-xl text-gray-800 font-bold mb-3">{scorecard.name}</h4>
                    <p className="text-octo-700 text-sm">{scorecard.description}</p>
                </Box>
                {
                    scorecard.rules && (

                        <Box className="w-full flex flex-col items-start">
                            <Box className="w-full flex items-center justify-start gap-2 transition-all duration-200 ease-in-out hover:gap-3">
                                <IconBadge Icon={KeyIcon}
                                           text={numberOfRules + (numberOfRules === 1 ? " Rule" : " Rules")}/>
                                <IconBadge Icon={AdjustmentsIcon}
                                           text={numberOfCategories + (numberOfCategories === 1 ? " Category" : " Categories")}/>
                            </Box>

                        </Box>
                    )
                }
            </Card>

        </Link>
    );
};

export default ScorecardComponent;
