import React from "react";
import Card from "../Card";

export const OverviewCardSekeleton = () => {
  return (
    <div role="Overviewcard-skeleton" className="space-y-2.5 animate-pulse ">
      <Card className="border-l-4 border-l-gray-200 ">
        <div className="flex  items-center justify-around px-4 py-2 space-x-4">
          <div className="flex items-center">
            <svg className="w-12 h-12" viewBox="0 0 35 35">
              <g>
                <div className="shadow-xl mask mask-hexagon-2"></div>

                <polygon
                  className="bg-grey-200 "
                  rx="50"
                  points="8 0 27 0 35 16 27 32 8 32 0 16"
                  fill="#d1d5db"
                />
              </g>
            </svg>
          </div>

         <div className="flex flex-col">
         <div className="flex items-center w-full space-x-2 ">
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-12"></div>
          </div>
          <div className="flex items-center w-full space-x-2  mt-4">
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
         </div>
          <span className="sr-only">Loading...</span>
        </div>
      </Card>
    </div>
  );
};
