import { ScoreCardEvaluation } from "../models/evaluations/ScoreCardEvaluation";
import Rule from "../models/scorecard/Rule";

interface RuleRank {
    ruleName: string;
    percentage: number;
  }
  export const generateFailingRuleRankList = (scorcardEvaluations: ScoreCardEvaluation[]): RuleRank[] => {
    const ruleCounts: RuleCounts = countRuleResults(scorcardEvaluations);
    const totalEvaluations = scorcardEvaluations.length;
    const ruleRank: RuleRank[] = [];
  
    Object.values(ruleCounts).forEach((count) => {
      const { rule, failureCount } = count;
      const ruleName=rule.name||""
      const percentage = (failureCount / totalEvaluations) * 100;
      ruleRank.push({ ruleName, percentage });
    });
  
    return ruleRank;
  }

  export const generateTopRuleRankList = (scorcardEvaluations: ScoreCardEvaluation[]): RuleRank[] => {
    const ruleCounts: RuleCounts = countRuleResults(scorcardEvaluations);
    const totalEvaluations = scorcardEvaluations.length;
    const ruleRank: RuleRank[] = [];
  
    Object.values(ruleCounts).forEach((count) => {
      const { rule, successCount} = count;
      const ruleName=rule.name||""
      const percentage = (successCount / totalEvaluations) * 100;
      ruleRank.push({ ruleName, percentage });
    });
  
    return ruleRank;
  }
  
  interface RuleCounts {
    [ruleId: string]: {
      rule: Rule;
      successCount: number;
      failureCount: number;
    };
  }
  const countRuleResults = (scorcardEvaluations: ScoreCardEvaluation[]): RuleCounts => {
    const ruleCounts: RuleCounts = {};
  
    scorcardEvaluations.forEach((evaluation) => {
      evaluation.evaluationResult.ruleEvaluationResults.forEach((result) => {
        const { rule, success } = result;
  
        if (ruleCounts[rule.id || '']) {
          if (success) {
            ruleCounts[rule.id || ''].successCount++;
          } else {
            ruleCounts[rule.id || ''].failureCount++;
          }
        } else {
          ruleCounts[rule.id || ''] = {
            rule,
            successCount: success ? 1 : 0,
            failureCount: success ? 0 : 1,
          };
        }
      });
    });
  console.log(ruleCounts)
    return ruleCounts;
  };
  

  
