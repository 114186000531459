const ServicesByRulesMatrixSkeleton = () => {
  return (
    <div data-testid="skeleton-div" className="animate-pulse">
      <div className="flex my-4 justify-between items-center rounded">
      <div className="h-8 bg-gray-400 rounded w-1/6"></div>
      <div className="h-8 bg-gray-400 rounded w-1/6"></div>
      </div>
      <hr />
      <div className="h-[400px] overflow-auto mt-2">
        <table className="min-w-full divide-y divide-gray-300 overflow-x-auto shadow-md rounded-lg bg-gray-200 border-none">
          <thead className="bg-gray-300">
            <tr>
              <th scope="col" className="px-8 py-4">
                <div className="h-4 bg-gray-400 rounded w-1/2"></div>
              </th>
              {[...Array(4)].map((_, index) => (
                <th key={index} scope="col" className="px-8 py-4">
                  <div className="h-4 bg-gray-400 rounded w-1/2"></div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-gray-200">
            {[...Array(7)].map((_, rowIndex) => (
              <tr key={rowIndex} className="bg-gray-300">
                <td className="px-8 py-4">
                  <div className="h-4 bg-gray-400 rounded w-1/2"></div>
                </td>
                {[...Array(4)].map((_, cellIndex) => (
                  <td key={cellIndex} className="px-8 py-4">
                    <div className="h-4 bg-gray-400 rounded w-1/2"></div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ServicesByRulesMatrixSkeleton;
