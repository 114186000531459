import React from "react";
import { generateServicesRanks } from "../../../utils/generateServicesRanks";
import DataTable from "./DataTable";
import ServiceScores from "../../../models/leaderboard/ServiceScores";
import NoDataMessage from "./NoDataMessage";

type TopServicesProps = {
  servicesScoresData: ServiceScores[];
};

const TopServicesDashboard: React.FC<TopServicesProps> = ({ servicesScoresData }) => {
  const topServices = generateServicesRanks(servicesScoresData)
    .sort((a, b) => b.points - a.points)
    .slice(0, 6);

  const headerColumns = ["Name", "Owner", "Score", "Total Score Percentage"];
  const dataRows = topServices.map((data) => [
    data.service.name,
    data.service.owners[0].name || "-",
    <span
      className="inline-block rounded-full px-4 text-sm font-semibold bg-blue-200 text-blue-700"
      key={data.service.name}
    >
      {data.points}
    </span>,
    <span
      className="inline-block rounded-full px-4 text-sm font-semibold bg-green-200 text-green-700"
      key={data.service.name}
    >
      {data.totalPercentage}
    </span>,
  ]);

  return (
    <div className="flex flex-col">
      {topServices.length > 0 ? (
        <DataTable  headerColumns={headerColumns} dataRows={dataRows} />
      ) : (
        <div className="flex justify-center items-center mt-20">
        <NoDataMessage />
      </div>
      )}
    </div>
  );
};

export default TopServicesDashboard;
