import {Disclosure} from "@headlessui/react";
import React, {FC, useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {Brand} from "../../octo-ui/Brand/Brand";
import Box from "../../octo-ui/Box/Box";
import Header from "../../octo-ui/Header/Header";
import MobileHamburgerButton from "./modules/MobileHamburgerButton";
import UserMenu from "./modules/UserMenu";
import AdminMobilePanel from "./modules/AdminMobilePanel";
import AdminAppMenu from "./modules/AdminAppMenu";
import {classNames} from "../../../utils/classNameUtils";


interface AdminSettingsHeaderProps {
    title: string;
}

const AdminSettingsHeader: FC<AdminSettingsHeaderProps> = ({title}) => {
    const [user, setUser] = useState<any>(null);
    const {keycloak, initialized} = useKeycloak();
    const {logout} = keycloak;

    useEffect(() => {
        if (!initialized) {
            return;
        }

        const fetchUserinfo = async () => {
            const result = await keycloak.loadUserInfo();
            setUser(result);
        };

        if (keycloak.authenticated) {
            fetchUserinfo();
        }
    }, [keycloak, initialized]);

    return (
        <Disclosure as="nav" className="bg-gray-800 pb-32 relative " data-testid="app-header">
            {({open}) => (
                <>
                    <Box className="z-10 relative border-b border-teal-500 border-opacity-25 lg:bg-transparent lg:border-none">
                        <Header>
                            <Box className="flex gap-2">
                                <Brand onlyMark color="white" size="l"/>
                                <h1 className="text-3xl font-bold text-white mr-4">SREDX</h1>
                                <AdminAppMenu/>
                            </Box>
                            <Box className="flex gap-4">
                                <Box className="hidden md:block">
                                    <Box className="flex items-center">
                                        {/* Profile dropdown */}
                                        <UserMenu logout={logout}/>
                                    </Box>
                                </Box>
                            </Box>
                            {/* Mobile Menu hamburger */}
                            <MobileHamburgerButton open={open}/>
                        </Header>
                        <AdminMobilePanel user={user} logout={logout}/>
                    </Box>

                    <div
                        aria-hidden="true"
                        className={classNames(
                            open ? 'bottom-0' : 'inset-y-0',
                            'absolute inset-x-0 left-1/2 transform -translate-x-1/2 w-full overflow-hidden lg:inset-y-0'
                        )}
                    >
                        <div className="absolute inset-0 flex">
                            <div className="h-full w-1/2" style={{ backgroundColor: '#252D3C' }} />
                            <div className="h-full w-1/2" style={{ backgroundColor: '#272E3E' }} />
                        </div>
                        <div className="relative flex justify-center">
                            <svg
                                className="flex-shrink-0"
                                width={1750}
                                height={308}
                                viewBox="0 0 1750 308"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M284.161 308H1465.84L875.001 182.413 284.161 308z" fill="#212936" />
                                <path d="M1465.84 308L16.816 0H1750v308h-284.16z" fill="#272E3E" />
                                <path d="M1733.19 0L284.161 308H0V0h1733.19z" fill="#252D3C" />
                                <path d="M875.001 182.413L1733.19 0H16.816l858.185 182.413z" fill="#212936" />
                            </svg>
                        </div>
                    </div>
                    <header className="relative py-10">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <h1 className="text-3xl font-bold text-white">{title}</h1>
                        </div>
                    </header>
                </>
            )}
        </Disclosure>
    );
};

export default AdminSettingsHeader;