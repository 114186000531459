import toast from "react-hot-toast";
import clsx from "clsx";

export const notify = () =>
    toast.custom(
        (t) => (
            <div
                className={clsx(t.visible ? "bottom-0" : "-bottom-96", "flex flex-row items-center justify-between w-96 bg-gray-700 px-4 py-6 text-white shadow-2xl hover:shadow-none transform-gpu translate-y-0 hover:translate-y-1 rounded-xl relative transition-all duration-500 ease-in-out")}
            >
                <div className="text-xl text-red-500">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                </div>
                <div className="flex flex-col items-start justify-center ml-1 cursor-default">
                    <h1 className="text-base text-gray-200 font-semibold leading-none tracking-wider">Oups</h1>
                    <p className="text-sm text-gray-400 mt-2 leading-relaxed tracking-wider">
                        Something went wrong, try again later.
                    </p>
                </div>
                <div className="absolute top-2 right-2 cursor-pointer text-lg"
                     data-testid="toast-close-button"
                     onClick={() => toast.dismiss(t.id)}>
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                </div>
            </div>
        ),
        {id: "unique-notification", position: "bottom-center"}
    );