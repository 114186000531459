import { useQuery } from "react-query";
import ServiceScores from "../models/leaderboard/ServiceScores";
import { useAxios } from "./useAxios";
import { getAllServicesScores } from "../services/services-scores";

export const SERVICES_SCORES_QUERY = "services_scores";

export const useServicesScores = () => {
  const { axios } = useAxios();
  return useQuery<ServiceScores[], Error>([SERVICES_SCORES_QUERY], () =>
    getAllServicesScores(axios)
  );
};
