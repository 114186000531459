import { ProgressInfo } from "../../../types/ProgressInfo";
import EvaluationProgressItem from "../../scorecard/evaluation/EvaluationProgressItem/EvaluationProgressItem";

interface Props {
  currentScorecardProgress: {
    [scorecardName: string]: ProgressInfo;
  };
}

const SidebarEvaluationContentComponent: React.FC<Props> = ({
  currentScorecardProgress,
}) => {
  return (
    <>
      {Object.entries(currentScorecardProgress).map(
        ([scorecardName, progressInfo], index) => (
          <div key={index} className="space-y mt-4">
            <div className="flex gap-2 m-2 items-center bg-gray-100 p-2 rounded">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-blue-500">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z" />
              </svg>
              <div className="text-lg font-semibold text-gray-700">{scorecardName}</div>
            </div>
            <div className="ml-2">
              {progressInfo.completedItemNames.map((itemName, idx) => (
                <EvaluationProgressItem
                  key={idx}
                  itemName={itemName}
                  status="done"
                />
              ))}
              {progressInfo.currentItem &&
                progressInfo.completedItems < progressInfo.totalItems && (
                  <EvaluationProgressItem
                    itemName={progressInfo.currentItem}
                    status="inProgress"
                  />
                )}
            </div>
            <hr className="m-2" />
          </div>
        )
      )}
    </>
  );
};

export default SidebarEvaluationContentComponent;
