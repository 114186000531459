import {useMutation, useQueryClient} from "react-query";
import {useAxios} from "../useAxios";
import {evaluateScoreCard} from "../../services/scorecards/ScoreCards.service";
import {SERVICES_SCORES_QUERY} from "../useServicesScores";
import {EVALUATIONS_QUERY} from "../evaluations/useServiceEvaluationsHistoryByScoreCard";

export const useEvaluateScoreCard = (scoreCardId: string, webSocketConnectionId: string) => {
    const {axios} = useAxios();
    const queryClient = useQueryClient();
    return useMutation(["scorecards", scoreCardId, "evaluations"], async () => {
        return await evaluateScoreCard(axios, scoreCardId, webSocketConnectionId);
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([SERVICES_SCORES_QUERY]);
            await queryClient.invalidateQueries([EVALUATIONS_QUERY]);
        }
    });
}
