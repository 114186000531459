import React, { useState, useEffect } from "react";
import { useServiceScoresDashboard } from "../../../../hooks/dashboard/useServiceScoresDashboard";
import { useOwnersServiceScoresDashboard } from "../../../../hooks/dashboard/useOwnersSevricesScores";
import { useOwnerServicesScoresImporovedDropedDashboard } from "../../../../hooks/dashboard/useOwnerServicesScoresImporovedDropedDashboard";
import TableSkeleton from "../../../../components/dashboard/services-performance/TableSkeleton";
import { useServiceScoresImporovedDropedDashboard } from "../../../../hooks/dashboard/useServiceScoresImporovedDropedDashboard";
import ScoreCard from "../../../../models/scorecard/ScoreCard";
import { SelectSkeleton } from "../ScoreCardsProgressWidget/ScoreCardsProgressWidgetSkeleton";
import Select from "react-select";
import { useScoreCardLastEvaluationsByTime } from "../../../../hooks/evaluations/useScoreCardLastEvaluationsByTime";
import TableRenderer from "../../../../components/dashboard/services-performance/TableRendererDashboard";
import TableOptions from "../../../../components/dashboard/services-performance/renderTableOptions";

interface ServicesPerformanceWidgetProps {
  scoreCards: ScoreCard[];
  isLoadingScoreCards: boolean;
}
const ServicesPerformanceWidget: React.FC<ServicesPerformanceWidgetProps> = ({ scoreCards, isLoadingScoreCards }) => {
    const [selectedTimeRange, setSelectedTimeRange] = useState<string>("day");
    const [selectedScoreCard, setSelectedScoreCard] = useState<ScoreCard>();
    const [selectedTable, setSelectedTable] = useState<string>("Top Services");
  
    const handleTableSelection = (table: string) => {
      setSelectedTable(table);
    };
  
    const handleTimeRangeSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedTimeRange(event.target.value);
    };
  
    const scoreCardOptions = scoreCards
      ? scoreCards.map((scoreCard) => ({
          value: scoreCard.id,
          label: scoreCard.name,
        }))
      : [];
    useEffect(() => {
      if (scoreCards?.length) {
        setSelectedScoreCard(scoreCards[0]);
      }
    }, [scoreCards]);  
    const {
      isLoading: isLoadingServiceScores,
      isSuccess: isSuccessServiceScores,
      data: serviceScoresData,
      refetch: refetchServiceScore
    } = useServiceScoresDashboard(selectedTimeRange, selectedScoreCard?.id ?? "");
  
    const {
      isLoading: isLoadingScoresService,
      isSuccess: isSuccesScoresService,
      data: scoresServiceData,
      refetch: refetchScoresService
    } = useServiceScoresImporovedDropedDashboard(selectedTimeRange, selectedScoreCard?.id ?? "");
  
    const {
      isLoading: isLoadingOwnerServiceScores,
      isSuccess: isSuccesOwnerServiceScores,
      data: ownerServiceScoresData,
      refetch: refetchOwnerServiceScore
    } = useOwnersServiceScoresDashboard(selectedTimeRange, selectedScoreCard?.id ?? "");
  
    const {
      isLoading: isLoadingOwnersServiceScores,
      isSuccess: isSuccesOwnersServiceScores,
      data: ownersServiceScoresData,
      refetch: refetchOwnersServiceScore
    } = useOwnerServicesScoresImporovedDropedDashboard(selectedTimeRange, selectedScoreCard?.id ?? "");
  
    const {
      isLoading: isLoadingEvaluation,
      isSuccess: isSuccesEvaluation,
      data: EvaluationData,
      refetch: refetchEvaluation
    } = useScoreCardLastEvaluationsByTime(selectedScoreCard?.id ?? "", selectedTimeRange);
    useEffect(() => {
      if (selectedScoreCard) {
        refetchServiceScore();
        refetchScoresService();
        refetchOwnerServiceScore();
        refetchOwnersServiceScore();
        refetchEvaluation();
      }
    }, [selectedTimeRange, selectedScoreCard,setSelectedTable]);
  
   
    
  
    const isLoading =
      isLoadingServiceScores ||
      isLoadingScoresService ||
      isLoadingOwnerServiceScores ||
      isLoadingOwnersServiceScores ||
      isLoadingEvaluation;
  
    if (isLoading) {
      return <TableSkeleton></TableSkeleton>;
    }
  return (
    <div className="flex  divide-x divide-gray-200 ">
      <div className={`flex-grow p-4 w-1/5"`}>
         <TableOptions
        selectedTable={selectedTable}
        handleTableSelection={handleTableSelection}
      />
      </div>
      <div className="flex-grow w-4/5">
        <div className="flex flex-col p-4">
          <div className="flex justify-between mb-4">
            <div className="justify-start">
              {selectedTable || "No item selected"}
            </div>
            <div className="justify-end items-center flex flex-row">
              <div className="relative mr-5">
              {isLoadingScoreCards ? (
              <SelectSkeleton />
            ) : (
              <Select
                value={scoreCardOptions.find(
                  (option) => option.value === selectedScoreCard?.id
                )}
                onChange={(option) => {
                  const newSelectedScoreCard = scoreCards?.find(
                    (scoreCard) => scoreCard.id === option?.value
                  );
                  setSelectedScoreCard(newSelectedScoreCard);
                }}
                options={scoreCardOptions}
                styles={{ control: (provided) => ({
                  ...provided,
                  backgroundColor: "#eff6ff",
                  borderColor: "#eff6ff",
                }), }}
              />
            )}
              </div>
              <div className="relative  ">
                <select
                  id="rangeFilter"
                  className="block appearance-none w-full bg-blue-50 border border-blue-50 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  defaultValue={selectedTimeRange}
                  onChange={handleTimeRangeSelection}
                >
                  <option value="day">Last 24 hours</option>
                  <option value="week">Last 7 days</option>
                  <option value="month">Last 30 days</option>
                  <option value="year">Last Year</option>
                </select>
              </div>
            </div>
          </div>
          <TableRenderer
            selectedTable={selectedTable}
            serviceScoresData={serviceScoresData}
            ownerServiceScoresData={ownerServiceScoresData}
            scoresServiceData={scoresServiceData}
            ownersServiceScoresData={ownersServiceScoresData}
            EvaluationData={EvaluationData}
            isSuccessServiceScores={isSuccessServiceScores}
            isSuccesOwnerServiceScores={isSuccesOwnerServiceScores}
            isSuccesScoresService={isSuccesScoresService}
            isSuccesOwnersServiceScores={isSuccesOwnersServiceScores}
            isSuccesEvaluation={isSuccesEvaluation}
          />
        </div>
      </div>
    </div>
  );
};

export default ServicesPerformanceWidget;
