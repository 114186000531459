export const formatDate = (date: number) => {
    const dt = new Date(date);
  
    const year = dt.getFullYear();
    const month = padL(dt.getMonth() + 1); 
    const day = padL(dt.getDate());
    const hours = padL(dt.getHours());
    const minutes = padL(dt.getMinutes());
  
    return `${day}/${month}/${year} ${hours}:${minutes}`;
};
  
export const padL = (nr: number, len = 2, chr = '0') => String(nr).padStart(len, chr);