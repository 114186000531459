import React, {FC} from "react";
import ScoreCard from "../../../models/scorecard/ScoreCard";
import Badge from "../../octo-ui/Badge/Badge";

interface ScoreCardInfosComponentProps {
    scorecard: ScoreCard;
}

const ScoreCardInfosComponent: FC<ScoreCardInfosComponentProps> = ({scorecard}) => {
    return (<>
        <div className="flex flex-col items-center md:m-auto  mx-5  ">
            <div className="flex gap-1">
                <h3 className="text-xl leading-6 font-medium text-gray-900">{scorecard.name}</h3>
                <Badge size="small" theme="gray">{scorecard.tag}</Badge>
            </div>
            <p className="text-sm mt-3">Target services:
                {scorecard.targetTags && scorecard.targetTags.length > 0 ?
                    scorecard.targetTags.map((tag, index) => {
                        return <Badge size="small" theme="indigo" key={index}>{tag}</Badge>
                    })
                    : <Badge size="small" theme="indigo">All</Badge>}
            </p>
            <p className="text-sm mt-3">{scorecard.description}</p>
        </div>
    </>);
}

export default ScoreCardInfosComponent;