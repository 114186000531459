import React from 'react';
import {ReactKeycloakProvider} from "@react-keycloak/web";
import {AppRoutes} from "./routes/AppRoutes";
import keycloak from "./config/keycloak";
import {LoadingPage} from "./pages/loading";
import {AuthClientEvent} from "@react-keycloak/core/lib/types";

function App() {
    const handleOnEvent = async (event: AuthClientEvent) => {
        if(event === 'onTokenExpired'){
            keycloak.login({
                redirectUri: window.location.href,
                prompt: 'login'
            });
        }
    }

    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            LoadingComponent={<LoadingPage/>}
            onEvent={handleOnEvent}
            initOptions={{
                onLoad: 'check-sso',
                pkceMethod: "S256",
            }}
        >
            <AppRoutes/>
        </ReactKeycloakProvider>
    );
}

export default App;
