import ServiceRank from "../models/dashborad/ServiceRank";
import ServiceScores from "../models/leaderboard/ServiceScores";
import { calculateDeclineForRateForService } from "./calculateDeclineRateForService";
import { calculateGrowthFOrRateFromServiceScore } from "./calculateGrowthFOrRateFromServiceScore";

export const generateDeclineServicesRank = (
  servicesScores: ServiceScores[]
): ServiceRank[] => {
  const servicesRanks: ServiceRank[] = [];

  servicesScores.forEach((serviceScore) => {
    const service = serviceScore.service;
    const scores = serviceScore.scores;
      console.log(scores)
    const changePercentage = calculateDeclineForRateForService(
      scores[0].score,
      scores[1].score
    );

    const points = [scores[0].score, scores[1].score];

    servicesRanks.push({ service, points, changePercentage });
    
  });

  return servicesRanks;
};
export const generateGrowthServicesRank = (
  servicesScores: ServiceScores[]
): ServiceRank[] => {
  const servicesRanks: ServiceRank[] = [];

  servicesScores.forEach((serviceScore) => {
    const service = serviceScore.service;
    const scores = serviceScore.scores;
  console.log(scores)
    const changePercentage = calculateGrowthFOrRateFromServiceScore(
      scores[0].score,
      scores[1].score
    );

    const points = [scores[0].score, scores[1].score];

    servicesRanks.push({ service, points, changePercentage });
  });

  return servicesRanks;
};
