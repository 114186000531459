interface ProgressBarProps {
    percentage: number;
}
  
const ProgressBar: React.FC<ProgressBarProps> = ({ percentage }) => {
  const validPercentage = Math.min(Math.max(percentage, 0), 100);

  return (
    <div className="w-full bg-gray-300 h-2 rounded" data-testid="progress-bar">
      <div
        className="bg-green-400 h-2 rounded"
        style={{ width: `${validPercentage}%` }}
        data-testid="progress-bar-fill"
      ></div>
    </div>
  );
};

export default ProgressBar;
  