import { v4 as uuidv4 } from 'uuid';

export default class Level {
    constructor() {
        this.id = uuidv4();
    }

    id ?: string;
    name ?: string;
    description? : string;
    ruleIds? : string[];
}