import {useMutation, useQueryClient} from "react-query";
import {useAxios} from "../useAxios";
import {saveTemplateConfig} from "../../services/configurations/configurations.service";
import {CONFIGS_QUERY} from "./useConfig";

export const useSaveConfig = () => {
    const queryClient = useQueryClient();
    const {axios} = useAxios();

    return useMutation(async (data: any[]) => {
        return saveTemplateConfig(axios, data[0], data[1]);
    }, {
        onSuccess: async (resultConfig: any, data) => {
            await queryClient.setQueryData([CONFIGS_QUERY, data[0]], resultConfig);
        },
        onError: (error) => {
            //TODO handle errors
            console.log(error);
        }
    });
}