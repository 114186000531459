import React, {FC, useState} from "react";
import {FormValidateButton} from "../../../basic/FormValidateButton/FormValidateButton";
import {DiscardFormButton} from "../../../basic/FormDiscardButton/DiscardFormButton";
import {Controller, useForm, useFormState} from "react-hook-form";
import ScoreCard from "../../../../models/scorecard/ScoreCard";
import Rule from "../../../../models/scorecard/Rule";
import Level from "../../../../models/scorecard/Level";
import {
    convertListOfRulesToGroupedByCategory,
    getListOfUniqueCategoriesFromListOfRules
} from "../../../../services/scorecards/scorecards.helper";
import ListOfScoreCardRules from "../ListOfRules/ListOfScoreCardRules";
import {FormErrorMessageComponent} from "../../../basic/FormErroMessage/FormErrorMessageComponent";
import {useNavigate} from "react-router-dom";
import {ADMIN_SCORECARDS_PAGE} from "../../../../routes/paths";
import {useCreateNewScoreCard} from "../../../../hooks/scorcards/useCreateNewScoreCard";
import {useUpdateScoreCard} from "../../../../hooks/scorcards/useUpdateScoreCard";
import RuleCategory from "../../../../models/scorecard/RuleCategory";
import ConfirmModalComponent from "../../../basic/ConfirmModalComponent/ConfirmModalComponent";
import CreatableSelect from "react-select/creatable";
import ListOfScoreCardLevels from "../ListOfLevels/ListOfScorecardLevels";


interface ScoreCardFormComponentProps {
    scoreCard?: ScoreCard;
}

export const ScoreCardFormComponent: FC<ScoreCardFormComponentProps> = ({scoreCard}) => {


    const [ruleCategoriesList, setRuleCategoriesList] = useState(getListOfUniqueCategoriesFromListOfRules(scoreCard?.rules ?? []));

    const {
        handleSubmit,
        register,
        reset,
        setValue,
        getValues,
        control
    } = useForm<any>(scoreCard ? {defaultValues: {...scoreCard, targetTags: scoreCard.targetTags?.map(tag =>({label: tag, value: tag}))}} : {});
    const {isSubmitting, isDirty, errors} = useFormState({
        control
    });


    const navigate = useNavigate();
    const [isRulesEmptyErrorAlertShown, setIsRulesEmptyErrorAlertShown] = useState(false);

    const isValidateButtonDisabled = !isDirty || isSubmitting;


    const {mutateAsync: createScoreCard} = useCreateNewScoreCard();
    const {mutateAsync: updateScoreCard} = useUpdateScoreCard()


    const saveScoreCard = async (data: any) => {
        if (!data.rules || data.rules.length === 0) {
            setIsRulesEmptyErrorAlertShown(true);
            return;
        }

        data.targetTags = data.targetTags?.map((tag: any) => tag.value);

        let apiCall;
        if (scoreCard) {
            apiCall = updateScoreCard
        } else {
            apiCall = createScoreCard
        }
        await apiCall(data)
            .then(() => {
                navigate(ADMIN_SCORECARDS_PAGE);
            }).catch((e) => {
                console.log(e);
            })

    }
    
    const cancelChanges = () => {
        reset();
    }

    const addRule = (rule: Rule) => {
        const rules = getValues("rules") ?? [];
        setValue("rules", [...rules, rule], {shouldDirty: true, shouldValidate: true});
    }

    const addlevel = (level: Level) => {
        const levels = getValues("levels") ?? [];
        setValue("levels", [...levels, level], {shouldDirty: true, shouldValidate: true});
    }

    const updateRule = (updatedRule: Rule) => {
        const rules = getValues("rules") ?? [];
        setValue("rules", rules.map((r: any) => {
            if (r.id === updatedRule.id) return updatedRule;
            return r;
        }), {shouldDirty: true, shouldValidate: true});
    }

    const updateLevel = (updatedLevel: Level) => {
        const levels = getValues("levels") ?? [];
        setValue("levels", levels.map((r: any) => {
            if (r.id === updatedLevel.id) return updatedLevel;
            return r;
        }), {shouldDirty: true, shouldValidate: true});
    }

    const deleteRuleById = (deletedId: string) => {
        const rules = getValues("rules") ?? [];
        setValue("rules", rules.filter((r: any) => r.id !== deletedId), {shouldDirty: true, shouldValidate: true});
    }

    const deleteLevelById = (deletedId: string) => {
        const levels = getValues("levels") ?? [];
        setValue("levels", levels.filter((r: any) => r.id !== deletedId), {shouldDirty: true, shouldValidate: true});
    }

    const addRuleCategory = (ruleCategory: RuleCategory) => {
        setRuleCategoriesList([ruleCategory, ...ruleCategoriesList]);
    }

    let targetOptions: any = [];

    if(scoreCard && scoreCard.targetTags){
        targetOptions = scoreCard.targetTags.map((tag) => ({value: tag, label: tag}))
    }

    return (
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">

            <div className="space-y-8 divide-y divide-gray-200 ml-2">
                <div className="space-y-8 ">


                    <form data-testid="scorecard-edit-form" id="scorecard-edit-form" className="grid sm:grid-cols-5"
                          onSubmit={handleSubmit(saveScoreCard)}>
                        <div className="sm:col-span-5">
                            <div id="mainInfosHeader" className={"flex justify-between"}>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Main Infos</h3>

                                <div id="formButtons"
                                     className="flex flex-col-reverse sm:flex-row items-center gap-2 px-2 md:px-0">
                                    <DiscardFormButton buttonText={"Discard"}
                                                       isButtonDisabled={isValidateButtonDisabled}
                                                       onClick={cancelChanges}/>
                                    <FormValidateButton isFormSubmitting={isSubmitting} isFormDirty={isDirty}
                                                        isButtonDisabled={isValidateButtonDisabled}
                                                        dirtyButtonText={"Save"}
                                                        formId={"scorecard-edit-form"}
                                    />
                                </div>
                            </div>
                            <div
                                className="shadow-lg rounded p-4 mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            data-testid="scoreCardName"
                                            id="scoreCardName"
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                            placeholder="Name"
                                            {...register('name', {
                                                required: {
                                                    value: true,
                                                    message: "You must specify a name."
                                                }
                                            })}
                                        />

                                        <FormErrorMessageComponent errors={errors} fieldName={`name`}/>
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            {...register('tag', {
                                                required: {
                                                    value: true,
                                                    message: "You must specify a tag."
                                                }
                                            })}
                                            data-testid="scoreCardTag"
                                            id="scoreCardTag"
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                            placeholder="Tag"
                                        />

                                        <FormErrorMessageComponent errors={errors} fieldName={`tag`}/>
                                    </div>
                                </div>
                                <div className="sm:col-span-6">
                                    <label htmlFor="targetTags" className="block text-sm font-medium text-gray-700">
                                        Target Tags
                                    </label>
                                    <div className="mt-1">
                                        <Controller
                                            control={control}
                                            name={`targetTags`}
                                            render={({field: {onChange, value, ref}}) => (
                                                <CreatableSelect
                                                    id="targetTags-select-field"
                                                    inputId={"targetTags-select-input-field"}
                                                    onChange={onChange}
                                                    defaultValue={targetOptions}
                                                    getNewOptionData={(inputValue: string) => (
                                                        {
                                                            value: inputValue,
                                                            label: `Add "${inputValue}"`
                                                        }
                                                    )}
                                                    isMulti={true}
                                                    ref={ref}
                                                    isClearable={true}
                                                    allowCreateWhileLoading={false}
                                                    value={value}
                                                    options={targetOptions}
                                                    className="basic-multi-select shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                />
                                            )}/>
                                    </div>
                                </div>
                                <div className="sm:col-span-6">
                                    <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                                        About
                                    </label>
                                    <div className="mt-1">
                                        <textarea
                                            data-testid="scoreCardDescription"
                                            id="scoreCardDescription"
                                            {...register('description')}
                                            rows={3}
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                                            placeholder={"Write a few sentences about the scorecard."}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ConfirmModalComponent title={"Ooops!"}
                                               message={"A scoreCard can't be created without any rules."}
                                               closeText={"Got it!"}
                                               isOpen={isRulesEmptyErrorAlertShown}
                                               setIsOpen={setIsRulesEmptyErrorAlertShown}/>
                    </form>


                    <ListOfScoreCardRules
                        possibleRulesCategories={ruleCategoriesList}
                        saveNewCategoryCallBack={addRuleCategory}
                        rules={convertListOfRulesToGroupedByCategory(getValues("rules") ?? [])}
                        deleteRuleByIdCallBack={deleteRuleById}
                        updateRuleDetailsCallBack={updateRule}
                        creatNewRuleCallBack={addRule}/>



                    {/* /////// ****list of levels creation ***** ////// */}
                    <ListOfScoreCardLevels 
                    rules = {getValues("rules") ?? []}
                    levels={getValues("levels")?? []}
                    updateLevelDetailsCallBack={updateLevel}
                    creatNewLevelCallBack={addlevel}
                    deleteLevelByIdCallBack={deleteLevelById}
                    />


                </div>

            </div>
        </div>
    );
}