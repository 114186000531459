import { InformationCircleIcon } from "@heroicons/react/outline";
import {
  AreaChart,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ResponsiveContainer,
} from "recharts";
import { formatDate } from "../../../utils/dateUtils";

interface ChartData {
  evaluationTime: number;
  score: number;
}

interface ScoreCardAreaChartProps {
  data: ChartData[];
}

const CustomTooltip: React.FC<any> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const dateTime = formatDate(payload[0].payload.evaluationTime);

    return (
      <div className="p-3 bg-white shadow rounded">
        <p>{`Date: ${dateTime}`}</p>
        <p>{`Score: ${payload[0].value.toFixed(2)}%`}</p>
      </div>
    );
  }
  return null;
};

export const ScoreCardAreaChart: React.FC<ScoreCardAreaChartProps> = ({
  data,
}) => (
  <ResponsiveContainer className={`my-2`} width="100%" height={400}>
    {data.length > 0 ? (
      <AreaChart data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#3498db" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#3498db" stopOpacity={0} />
          </linearGradient>
        </defs>
        <YAxis tick={{ fontSize: 12, fill: "#6B7280" }} stroke="#E5E7EB" />
        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
        <Tooltip
          content={<CustomTooltip />}
          cursor={{ stroke: "#8884d8", strokeWidth: 2 }}
        />
        <Area
          type="monotone"
          dataKey="score"
          stroke="#8884d8"
          fill="url(#colorUv)"
        />
      </AreaChart>
    ) : (
        <div className="flex flex-col justify-center items-center h-full text-center p-4 text-gray-500">
            <InformationCircleIcon className="w-12 h-12 text-gray-500 mb-4" />
        <p className="uppercase" >No data available for the selected period.</p>
      </div>
    )}
  </ResponsiveContainer>
);
