import React, {FC, useEffect, useState} from "react";
import {RefreshIcon} from "@heroicons/react/solid";
import {useScoreCards} from "../../../hooks/scorcards/useScoreCards";
import ScoreCard from "../../../models/scorecard/ScoreCard";
import ServiceScoreCardStats from "../../../models/evaluations/ServiceStatsByScoreCard";
import {ScoreCardEvaluation} from "../../../models/evaluations/ScoreCardEvaluation";
import ServiceOverviewPerScoreCardComponent
    from "../../scorecard/ScoreCardDetails/ScoreCardServicesList/ServiceOverviewPerScoreCard/ServiceOverviewPerScoreCardComponent";
import ServiceRulesListPerScoreCardComponent
    from "../../scorecard/ScoreCardDetails/ScoreCardServicesList/ServiceOverviewPerScoreCard/ServiceRulesListPerScoreCard";
import ServiceScoreProgressPerScoreCardComponent
    from "../../scorecard/ScoreCardDetails/ScoreCardServicesList/ServiceOverviewPerScoreCard/ServiceScoreProgressPerScoreCardComponent";
import {getServiceStatsFromScoreCardLastEvaluation} from "../../../services/evaluations/ServiceEvaluations.helper";
import ServiceLevelsPerScoreCardComponent from "../../scorecard/ScoreCardDetails/ScoreCardServicesList/ServiceOverviewPerScoreCard/ServiceLevelsPerScorecardComponent";


interface ServiceScoreCardsListComponentProps {
    serviceId: string;
    //List of last evaluation by each scorecard on this service
    serviceLastEvaluations: ScoreCardEvaluation[];
}

const ServiceScoreCardsListComponent: FC<ServiceScoreCardsListComponentProps> = ({
                                                                                     serviceId,
                                                                                     serviceLastEvaluations
                                                                                 }) => {

    const evaluatedScoreCardsIdsList: string[] = serviceLastEvaluations.map(e => e.scoreCardId);
    const {
        data: scoreCardsData,
        isSuccess: isScoreCardsDataLoaded,
        isLoading: isLoadingScoreCardsData
    } = useScoreCards();

    const [selectedScoreCard, setSelectedScoreCard] = useState<ScoreCard | undefined>();
    const [selectedScoreCardStats, setSelectedScoreCardStats] = useState<ServiceScoreCardStats | undefined>();
    const [shownScoreCardsData, setShownScoreCardsData] = useState<ScoreCard[] | undefined>(scoreCardsData?.filter(s => evaluatedScoreCardsIdsList.includes(s.id)) ?? []);
    const [activeTab, setActiveTab] = useState('overview');

    useEffect(() => {
        if (selectedScoreCard) {
            setSelectedScoreCardStats(getServiceStatsFromScoreCardLastEvaluation(serviceLastEvaluations.filter(e => e.scoreCardId == selectedScoreCard.id).at(0)!));
        }
    }, [selectedScoreCard]);

    useEffect(() => {
        setShownScoreCardsData(scoreCardsData?.filter(s => evaluatedScoreCardsIdsList.includes(s.id)) ?? []);
    }, [scoreCardsData]);

    useEffect(() => {
        setSelectedScoreCard(shownScoreCardsData?.at(0));
    }, [shownScoreCardsData]);
    return (<>
        <div id="service-scorecards-list-container" className={"space-y-4"}>
            <div className="mx-auto p-4 sm:px-6 lg:px-8">
                <h2 className="text-lg leading-6 font-medium text-gray-900">ScoreCards</h2>
                {
                    (scoreCardsData?.length == 0) && (
                        <div className={"text-center"}>
                            No scorecards were used to evaluate this service.
                        </div>
                    )
                }
                {isLoadingScoreCardsData &&
                    (<RefreshIcon className={"animate-spin h-5 w-5 mx-auto"}/>)}
                {
                    isScoreCardsDataLoaded &&
                    (<div className="mt-5 lg:grid lg:grid-cols-4 gap-5">

                            <div
                                className="flex justify-center lg:justify-start lg:flex-col overflow-x-auto lg:overflow-y-auto shadow">
                                {
                                    scoreCardsData?.map((item, index) => (
                                        <div
                                            key={item.id}
                                            id={`scorecards-list-item-${index}`}
                                            onClick={() => {
                                                setSelectedScoreCard(item);
                                            }}
                                            className={
                                                `flex-shrink ${item.id === selectedScoreCard?.id
                                                    ? 'bg-indigo-50 border-indigo-600 text-indigo-600'
                                                    : 'border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900'}
                                            'group flex items-center px-3 py-2 text-sm font-medium border-l-4 cursor-pointer`
                                            }
                                        >
                                            {item.name}
                                        </div>
                                    ))}
                            </div>
                            <div className="col-span-3 shadow">
                                <div className="flex mb-4">
                                    <button
                                        className={`px-4 py-2 ${activeTab === 'overview' ? 'text-white bg-blue-500' : ''}`}
                                        onClick={() => setActiveTab('overview')}
                                    >
                                        Overview
                                    </button>
                                    <button
                                        className={`px-4 py-2 ${activeTab === 'rules' ? 'text-white bg-blue-500' : ''}`}
                                        onClick={() => setActiveTab('rules')}
                                    >
                                        Rules
                                    </button>
                                    <button
                                        className={`px-4 py-2 ${activeTab === 'levels' ? 'text-white bg-blue-500' : ''}`}
                                        onClick={() => setActiveTab('levels')}
                                    >
                                        Service Levels
                                    </button>
                                    <button
                                        className={`px-4 py-2 ${activeTab === 'score' ? 'text-white bg-blue-500' : ''}`}
                                        onClick={() => setActiveTab('score')}
                                    >
                                        Trends
                                    </button>
                                </div>

                                {activeTab === 'overview' && selectedScoreCard && selectedScoreCardStats && (
                                    <ServiceOverviewPerScoreCardComponent
                                        overViewData={selectedScoreCardStats.overview}
                                    />
                                )}
                                {activeTab === 'levels' && selectedScoreCard && selectedScoreCardStats && (
                                    <ServiceLevelsPerScoreCardComponent
                                        levels={selectedScoreCard.levels} rules={selectedScoreCardStats.rulesStatus}
                                    />
                                )}
                                {activeTab === 'score' && selectedScoreCard && selectedScoreCardStats && (
                                    <ServiceScoreProgressPerScoreCardComponent
                                        scoreCardId={selectedScoreCard.id} serviceId={serviceId}
                                    />
                                )}
                                {activeTab === 'rules' && selectedScoreCard && selectedScoreCardStats && (
                                    <ServiceRulesListPerScoreCardComponent
                                        rulesStatusByCategory={selectedScoreCardStats.rulesStatus}
                                    />
                                )}
                            </div>
                        </div>
                    )
                }
            </div>

        </div>
    </>);
}

export default ServiceScoreCardsListComponent;