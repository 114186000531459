export const HeatmapSekeleton = ({ width }: { width: number }) => {
  return (
    <div role="status" className="space-y-2.5 animate-pulse w-full ">
      <div className="divide-y">
        <div className="ml-6 mb-4 mt-4 ">
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-24 mb-4 "></div>
        </div>
        <div className="flex flex-row p-8 space-x-12">
          <div className="flex flex-col items center">
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-24 mb-10 mt-10"></div>
            <div className="w-24 h-2.5 mb-10 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-24 mb-10"></div>
            <div className="w-24 h-2.5 mb-10 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-24 mb-10"></div>
          </div>
          <div>
            <div
              style={{ width: `${(width * 2) / 3}px`, height: "280px" }}
              className="m-y-12 p-12 rounded-md border border-gray-300 bg-white shadow-md transition-all duration-300 ease-in-out  bg-gray-200  "
            ></div>
          </div>
        </div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};
