import {
    ADMIN_CONFIG_PAGE, ADMIN_CONFIG_PAGE_PREFIX,
    ADMIN_CREATE_NEW_SCORECARD_PAGE,
    ADMIN_DASHBOARD_PAGE,
    ADMIN_EDIT_SINGLE_SCORECARD_PAGE,
    ADMIN_LEADERBOARD_PAGE, ADMIN_SCORECARD_DETAILS_PAGE,
    ADMIN_SCORECARDS_PAGE,
    ADMIN_SERVICE_DETAIL_PAGE,
    ADMIN_SERVICES_PAGE,
    HOME_BASE_PATH,
    NOT_FOUND_PATH,
    UNAUTHORIZED_BASE_PATH,
} from './paths';
import {ComponentType} from "react";
import {hasAnyRole} from "../utils/authorization";
import {ROLES} from "../config/roles";
import {AdminServicesPage, UnauthorizedPage} from "../pages";
import {HomePage} from "../pages/home";
import AdminServiceDetailPage from "../pages/admin/services/AdminServiceDetailPage";
import {NotFoundPage} from "../pages/404";
import {AdminScoreCardPage} from "../pages/admin/scorecards";
import {EditScoreCardPage} from "../pages/admin/scorecards/EditScoreCardPage";
import LeaderBoardPage from "../pages/admin/leaderboard/LeaderBoardPage";
import {CreateNewScoreCardPage} from "../pages/admin/scorecards/CreateNewScoreCardPage";
import {ScoreCardDetailsPage} from "../pages/admin/scorecards/ScoreCardDetailsPage";
import ConfigPage from "../pages/admin/configurations/ConfigPage";
import { PuzzleIcon, UsersIcon, ClipboardCheckIcon, TableIcon } from '@heroicons/react/outline';
import DashboardPage from '../pages/admin/dashboard/DashboardPage';


export interface RouteType {
    path: string;
    name: string;
    role?: Function;
    component: ComponentType<any>;
    isPrivate: boolean;
    showInNav?: boolean;
    configLayout?: boolean;
    icon?: ComponentType<any>;
}

export interface UserNavigationType {
    name: string;
    path: string;
}

export const APP_ROUTES: RouteType[] = [
    {
        path: HOME_BASE_PATH,
        name: 'Home',
        component: HomePage,
        isPrivate: false,
        showInNav: true
    },
    {
        path: UNAUTHORIZED_BASE_PATH,
        name: 'Unauthorized',
        component: UnauthorizedPage,
        isPrivate: false,
    },
    {
        path: NOT_FOUND_PATH,
        name: 'NotFound',
        component: NotFoundPage,
        isPrivate: false,
    },
];

export const validateAdminRole = (authorities: string[]) => hasAnyRole([ROLES.ADMIN], authorities);
export const validateUserRole = (authorities: string[]) => hasAnyRole([ROLES.USER, ROLES.ADMIN], authorities);

export const ADMIN_ROUTES: RouteType[] = [
    {
        path: ADMIN_DASHBOARD_PAGE,
        name: 'Dashboard',
        role: validateAdminRole,
        component: DashboardPage,
        isPrivate: true,
        showInNav: true,
        icon: TableIcon
    },
    {
        path: ADMIN_SERVICES_PAGE,
        name: 'Services',
        role: validateAdminRole,
        component: AdminServicesPage,
        isPrivate: true,
        showInNav: true,
        icon: PuzzleIcon
    },
    {
        path: ADMIN_SCORECARDS_PAGE,
        name: 'Scorecards',
        role: validateAdminRole,
        component: AdminScoreCardPage,
        isPrivate: true,
        showInNav: true,
        icon: ClipboardCheckIcon
    }, {
        path: ADMIN_LEADERBOARD_PAGE,
        name: 'Leaderboard',
        role: validateUserRole,
        component: LeaderBoardPage,
        isPrivate: true,
        showInNav: true,
        icon: UsersIcon
    },
    {
        path: ADMIN_SERVICE_DETAIL_PAGE,
        name: 'Service Detail',
        role: validateAdminRole,
        component: AdminServiceDetailPage,
        isPrivate: true,
        showInNav: false
    },
    {
        path: ADMIN_EDIT_SINGLE_SCORECARD_PAGE,
        name: 'Edit Scorecard',
        role: validateAdminRole,
        component: EditScoreCardPage,
        isPrivate: true,
        showInNav: false
    },
    {
        path: ADMIN_CREATE_NEW_SCORECARD_PAGE,
        name: 'Create New Scorecard',
        role: validateAdminRole,
        component: CreateNewScoreCardPage,
        isPrivate: true,
        showInNav: false
    },
    {
        path: ADMIN_SCORECARD_DETAILS_PAGE,
        name: 'Scorecard Details',
        role: validateAdminRole,
        component: ScoreCardDetailsPage,
        isPrivate: true,
        showInNav: false
    },
    {
        path: ADMIN_CONFIG_PAGE_PREFIX,
        name: 'Integrations configuration',
        role: validateAdminRole,
        component: ConfigPage,
        isPrivate: true,
        showInNav: false,
        configLayout: true
    },
    {
        path: ADMIN_CONFIG_PAGE,
        name: 'Integrations configuration',
        role: validateAdminRole,
        component: ConfigPage,
        isPrivate: true,
        showInNav: false,
        configLayout: true
    }
];

export const ADMIN_USER_NAVIGATION: UserNavigationType[] = [
    // {name: 'Your Profile', path: '#'},
    {name: 'Configurations', path: ADMIN_CONFIG_PAGE_PREFIX},
];
