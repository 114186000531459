import React, {FC} from "react";
import {RuleArgumentTemplate} from "../../../models/integration/IntegrationTemplate";

import {Control, Controller} from 'react-hook-form';
import Rule from "../../../models/scorecard/Rule";
import {FormErrorMessageComponent} from "../FormErroMessage/FormErrorMessageComponent";
import ValueObject from "../../../models/scorecard/ValueObject";
import Select from "react-select";

interface GenericRuleParamFieldComponentProps {
    argumentTemplate: RuleArgumentTemplate,
    control?: Control<Rule, any>,
    initialValue?: ValueObject

    // handleChange(paramKey: string, value: ValueObject): void
}

const GenericRuleParamFieldComponent: FC<GenericRuleParamFieldComponentProps> = ({
                                                                                     argumentTemplate,
                                                                                     initialValue,
                                                                                     // handleChange,
                                                                                     control
                                                                                 }) => {

    const getInputTypeByArgumentType = (argumentType: "BOOLEAN" | "STRING" | "DOUBLE" | "INTEGER"): string => {
        switch (argumentType) {
            case "INTEGER":
            case "DOUBLE":
                return "number";
            default:
                return "text"
        }
    }
    return (
        <div className="sm:col-span-6">
            <label htmlFor={argumentTemplate.key}
                   className="block text-sm font-medium text-gray-700">
                {argumentTemplate.name ?? argumentTemplate.key}
            </label>
            <div className="mt-1">
                <Controller

                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: `${argumentTemplate.key} is a required parameter for this rule.`
                        }
                    }}

                    name={`operation.params.${argumentTemplate.key}`}
                    render={({field: {onChange, ref}, formState: {errors}}) => {
                        if(argumentTemplate.type == "LIST"){
                            const options = Object.entries(argumentTemplate.values!).map(([key, value]) => ({value: key, label: value} as any));

                            return (
                                <>
                                    <Select
                                        data-testid={`${argumentTemplate.key}-param-input-field`}
                                        id={`${argumentTemplate.key}-param-input-field`}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultValue={options.find(o => o.value === initialValue?.value)}
                                        isSearchable={true}
                                        isMulti={argumentTemplate.multiple}
                                        ref={ref}
                                        getOptionValue={(option: any) => option.value}
                                        getOptionLabel={(option: any) => option.label}
                                        options={options}
                                        onChange={(newValue: any) => {
                                            onChange({
                                                value: newValue.value, type: "STRING"
                                            });
                                        }}
                                    />

                                <FormErrorMessageComponent errors={errors}
                                                           fieldName={`operation.params.${argumentTemplate.key}`}/>
                            </>);
                        }
                        return (
                            <>

                                <input
                                    data-testid={`${argumentTemplate.key}-param-input-field`}
                                    id={`${argumentTemplate.key}-param-input-field`}

                                    defaultValue={initialValue?.value}
                                    ref={ref}
                                    onChange={(e) => {

                                        onChange({
                                            value: e.target.value, type: argumentTemplate.type
                                        });
                                    }}
                                    type={getInputTypeByArgumentType(argumentTemplate.type)}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />

                                <FormErrorMessageComponent errors={errors}
                                                           fieldName={`operation.params.${argumentTemplate.key}`}/>
                            </>
                        );
                    }}
                />


            </div>
        </div>
    );
}

export default GenericRuleParamFieldComponent;