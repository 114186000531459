import ScoreCardsProgressWidget from "./ScoreCardsProgressWidget/ScoreCardsProgressWidget";
import { LeaderServicesWidget } from "./LeaderServicesWidget/LeaderServicesWidget";
import { useScoreCards } from "../../../hooks/scorcards/useScoreCards";
import { useServices } from "../../../hooks/useServices";
import { useServicesScores } from "../../../hooks/useServicesScores";
import { OverviewComponent } from "../../../components/dashboard/overview/OverviewComponent";
import { useEffect, useRef, useState } from "react";
import { Heatmap } from "../../../components/dashboard/heatmap/Heatmap";
import ServicesPerformanceWidget from "./ServicesPerformanceWidget/ServicesPerformanceWidget;";
import Card from "../../../components/dashboard/Card";
import ServicesByRulesMatrix from "./ServicesByRulesMatrix/ServicesByRulesMatrix";


const DashboardPage = () => {
  const { isLoading: isLoadingScoreCards, data: scoreCardsData } = useScoreCards();
  const { isLoading: isLoadingServices, data: servicesData } = useServices();
  const { isLoading: isLoadingServicesScores, data: servicesScoresData } = useServicesScores();



  const scoreCards = scoreCardsData ?? [];
  const services = servicesData ?? [];
  const servicesScores = servicesScoresData ?? [];

  const ref = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState<number>(1200);

  const getCurrentWidth = () => {
    if (ref.current) {
      const newWidth = ref.current.offsetWidth;
      setWidth(newWidth);
    }
  };

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getCurrentWidth);
  }, []);

  return (
    <>
      <header className="max-w-[2000px] mx-auto bg-white shadow-sm w-full">
        <div className="mx-auto py-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold sm:px-2 md:px-4 lg:px-12 text-gray-900">Dashboard</h1>
        </div>
      </header>
      <section className="max-w-[2000px] mx-auto pt-4 sm:px-2 md:px-8 lg:px-16">
        <OverviewComponent />
      </section>
      <main className=" max-w-[2000px] mx-auto md:px-4 lg:px-12">
        <div className="mx-auto py-6 sm:px-2 lg:px-2">
          <div className="md:px-2 py-2  space-y-4">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-4 lg:gap-x-7">
                <Card className="col-span-2 p-6">
              <div ref={ref}  className="mt-2 flex items-center justify-center">
                  <Heatmap width={width - 10} />
                </div> 
                </Card>
                <Card className="col-span-1">
                <div className="p-6">
                  <LeaderServicesWidget
                    servicesScores={servicesScores}
                    isLoadingServicesScores={isLoadingServicesScores}
                  />
                </div>
                </Card>
            </div>
              <Card>
                <div className="p-6" >
                  <ServicesByRulesMatrix
                      scoreCards={scoreCards} 
                      services={services} 
                      isLoadingScoreCards={isLoadingScoreCards} 
                      isLoadingServices={isLoadingServices}
                   />
                </div>
              </Card>
              <Card>
              <div className="p-6" >
                <ServicesPerformanceWidget
                 scoreCards={scoreCards}  
                 isLoadingScoreCards={isLoadingScoreCards}
                />
              </div>
              </Card>
            <div className="mt-6">
                <Card className="overflow-hidden">
                <div className="p-6">
                  <ScoreCardsProgressWidget 
                    scoreCards={scoreCards} 
                    services={services} 
                    isLoadingScoreCards={isLoadingScoreCards} 
                    isLoadingServices={isLoadingServices} 
                  />
                </div>
                </Card>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default DashboardPage;



