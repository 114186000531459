import React, {FC} from "react";
import FractionComponent from "../../../../basic/FractionComponent/FractionComponent";
import {ServiceOverviewData} from "../../../../../models/evaluations/ServiceStatsByScoreCard";
import {getOrdinalSuffixOfPosition} from "../../../../../utils/formating";


interface ServiceOverviewPerScoreCardComponentProps {
    overViewData?: ServiceOverviewData
}

const ServiceOverviewPerScoreCardComponent: FC<ServiceOverviewPerScoreCardComponentProps> = ({overViewData}) => {
    return (
        <div id="service-scorecard-overview-container" className={"space-y-4"}>
            <div className="mx-auto p-4 sm:px-6 lg:px-8">
                <h2 className="text-lg leading-6 font-medium text-gray-900">Service Overview</h2>
                <div
                    className={`mt-2 grid grid-cols-1 gap-5 sm:grid-cols-1 ${overViewData?.rankOnScoreCard ? "lg:grid-cols-3" : "lg:grid-cols-2"}`}>
                    {
                        overViewData?.rankOnScoreCard && (<div id={"service-scorecard-rank"}
                                                               className="bg-white  hover:bg-gray-100 overflow-hidden shadow rounded-lg">
                            <div className="h-full p-5">
                                <div className="flex items-center">

                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            <dd>
                                                <div
                                                    className="text-center text-lg font-medium text-gray-900">{getOrdinalSuffixOfPosition(overViewData?.rankOnScoreCard!)}
                                                </div>
                                            </dd>
                                            <dt className="text-center text-sm font-medium text-gray-500">Rank Based on this
                                                scorecard.
                                            </dt>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    }
                    <div id={"service-scorecard-score"}
                         className="bg-white hover:bg-gray-100 overflow-hidden shadow rounded-lg">
                        <div className="h-full p-5">
                            <div className="flex items-center">

                                <div className="ml-5 w-0 flex-1">
                                    <dl>
                                        <dd>
                                            <div
                                                className="text-center text-lg font-medium text-gray-900">{overViewData?.scoreOnScoreCard}%
                                            </div>
                                        </dd>
                                        <dt className="text-center text-sm font-medium text-gray-500">Score Based on
                                            this
                                            scorecard.
                                        </dt>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id={"service-scorecard-passing-rules"}
                         className="bg-white hover:bg-gray-100 overflow-hidden shadow rounded-lg">
                        <div className="h-full p-5 mb-0">
                            <FractionComponent lowerNumber={overViewData?.totalEvaluatedRules!}
                                               upperNumber={overViewData?.passingRules!} modelName={"rules"}/>
                        </div>
                    </div>

                </div>
            </div>

        </div>);
}

export default ServiceOverviewPerScoreCardComponent;