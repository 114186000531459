import {useQuery} from "react-query";
import { getAllOwnersServicesScoresByTimeRange } from "../../services/services-scores";
import { useAxios } from "../useAxios";
import OwnerServicesScores from "../../models/dashborad/OwnerServicesScores";


export const SERVICES_SCORES_QUERY = "owners_services_scores";

export const useOwnersServiceScoresDashboard = (timeRange:string,scorecardId:string) => {
    const {axios} = useAxios();
    const query= useQuery<OwnerServicesScores[], Error>([SERVICES_SCORES_QUERY], () => getAllOwnersServicesScoresByTimeRange(axios,timeRange,scorecardId));
    const { refetch } = query;

  return { ...query, refetch };
}