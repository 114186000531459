import React, {FC} from "react";
import AdminAppHeader from "./AdminAppHeader";
import AdminSettingsHeader from "./AdminSettingsHeader";

export interface Props {
    children: React.ReactNode;
    title?: string;
    configLayout?: boolean;
}

export const AdminAppLayout: FC<Props> = ({children, title, configLayout}) => {
    return (
        <>
            <div className="min-h-full">
                {configLayout ? <AdminSettingsHeader title={title ?? ""}/> : <AdminAppHeader/>}

                {children}
            </div>
        </>
    );
}