import ServiceScoreCardStats, {
    RulesStatusByCategory,
    RuleStatus,
    ServiceOverviewData,
    ServiceScoreCardEvaluationData
} from "../../models/evaluations/ServiceStatsByScoreCard";
import ScoreCardOverview from "../../models/scorecard/ScoreCardOverview";
import {ScoreCardEvaluation} from "../../models/evaluations/ScoreCardEvaluation";

// Takes a list of non empty last evaluations made by a scorecard to each service (1 evaluation per service)
export const getScoreCardOverviewFromEvaluationsHistory = (evaluationsHistory: ScoreCardEvaluation[]): ScoreCardOverview => {
    return {
        averageScore: getScoreCardAverageScoreFromScoreCardLastEvaluation(evaluationsHistory),
        lastEvaluated: getScoreCardLastEvaluationFromScoreCardLastEvaluation(evaluationsHistory),
        totalServices: evaluationsHistory.length,
        passingServices: getScoreCardPassingServicesFromScoreCardLastEvaluation(evaluationsHistory)
    }
}

export const getScoreCardAverageScoreFromScoreCardLastEvaluation = (evaluationsHistory: ScoreCardEvaluation[]): number => {
    const numberOfEvaluations = evaluationsHistory.length;
    const totalScores = evaluationsHistory.reduce((sum, current) => Number(sum) + Number(current.evaluationResult.score), 0);
    return totalScores / numberOfEvaluations;
}

export const getScoreCardLastEvaluationFromScoreCardLastEvaluation = (evaluationsHistory: ScoreCardEvaluation[]): Date => {
    let last: Date = new Date();
    evaluationsHistory.forEach((e) => {
        let date = new Date(e.evaluationTime);
        if (date.getMilliseconds() <= last.getMilliseconds()) {
            last = date;
        }
    })
    return last;
};

export const getScoreCardPassingServicesFromScoreCardLastEvaluation = (evaluationsHistory: ScoreCardEvaluation[]): number => {
    return evaluationsHistory.filter((e) => e.evaluationResult.score === e.evaluationResult.totalPossible).length;
}

export const getServiceEvaluationsDataFromServiceEvaluationsHistoryByScoreCard = (serviceScoreCardEvaluations: ScoreCardEvaluation[]): ServiceScoreCardEvaluationData[] => {
    return [...serviceScoreCardEvaluations]
        .sort((e1, e2) => {
            if (e1.evaluationTime > e2.evaluationTime) {
                return 1;
            }
            if (e1.evaluationTime < e2.evaluationTime) {
                return -1;
            }
            return 0;
        })
        .map<ServiceScoreCardEvaluationData>(e => ({
            evaluationScore: Math.round(e.evaluationResult.scorePercentage*100)/100,
            evaluationTime: (new Date(e.evaluationTime))
                .toLocaleString('default', {
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    month: 'long'
                })
        }));
}
export const getServiceStatsFromScoreCardListOfLastEvaluations = (serviceId: string, scoreCardLastEvaluation: ScoreCardEvaluation[]): ServiceScoreCardStats => {
    return {
        overview: getServiceOverviewFromScoreCardListOfLastEvaluations(serviceId, scoreCardLastEvaluation),
        rulesStatus: getServiceRulesLatestStatusFromScoreCardListOfLastEvaluations(serviceId, scoreCardLastEvaluation)
    };
}

/**
 * Get rank and score of a service in a list of evaluations
 * @param scoreCardLastEvaluation list of all services evaluations (evaluation per service)
 * @param serviceId id of one of the given services in scoreCardLastEvaluation
 */
export const getServiceOverviewFromScoreCardListOfLastEvaluations = (serviceId: string, scoreCardLastEvaluation: ScoreCardEvaluation[]): ServiceOverviewData => {
    const serviceLastEvaluation = scoreCardLastEvaluation.find(e => e.serviceId == serviceId)!;
    const serviceScore = serviceLastEvaluation.evaluationResult.score;
    let rank = 1;
    scoreCardLastEvaluation.forEach(e => {
        if (e.evaluationResult.score > serviceScore!) {
            rank += 1;
        }
    });

    const totalRules = serviceLastEvaluation.evaluationResult.ruleEvaluationResults.length;
    const passingRules = serviceLastEvaluation.evaluationResult.ruleEvaluationResults.filter(r => r.success).length;
    return {
        rankOnScoreCard: rank,
        scoreOnScoreCard: Math.round(100 * serviceScore / serviceLastEvaluation.evaluationResult.totalPossible),
        passingRules: passingRules,
        totalEvaluatedRules: totalRules
    };
}

//TODO implement getServiceTotalEvaluatedRulesFromScoreCardLastEvaluation
export const getServiceRulesLatestStatusFromScoreCardListOfLastEvaluations = (serviceId: string, scoreCardLastEvaluation: ScoreCardEvaluation[]): RulesStatusByCategory => {

    const serviceLastEvaluation = scoreCardLastEvaluation.find(e => e.serviceId == serviceId)!;
    const rulesStatusByCategory: RulesStatusByCategory = {};
    serviceLastEvaluation.evaluationResult.ruleEvaluationResults.forEach(result => {
        const ruleCategory = result.rule.ruleCategory?.categoryName!;
        const ruleStatus: RuleStatus = {
            ruleId: result.rule.id!,
            ruleScore: result.rule.score!,
            ruleExpression: result.rule.ruleExpression ?? result.rule.key!,
            ruleName: result.rule.name!,
            isRuleEvaluationSuccessful: result.success,
            evaluationErrors: result.errors,
            actualValue: result.actualValue?.value
        }

        if (!rulesStatusByCategory[ruleCategory]) {
            rulesStatusByCategory[ruleCategory] = [ruleStatus];
        } else {
            rulesStatusByCategory[ruleCategory].push(ruleStatus);
        }
    });

    return rulesStatusByCategory;
}
