import { useKeycloak } from "@react-keycloak/web";
import { useWebsocket } from "../hooks/useWebsocket";

export const useStompWebSocket = <T>(subscribePath: string) => {
  const { keycloak } = useKeycloak();
  const token = keycloak.token ?? "";
  const envSettings = window as any;
  const baseUrl = envSettings.REACT_APP_API_URL ? envSettings.REACT_APP_API_URL : "http://localhost:8080";
  const url = `${baseUrl}/evaluation-progress-ws?authToken=${token}`;

  return useWebsocket<T>(url, subscribePath);
};

