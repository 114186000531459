import {Disclosure} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {Brand} from "../../octo-ui/Brand/Brand";
import Box from "../../octo-ui/Box/Box";
import Header from "../../octo-ui/Header/Header";
import MobileHamburgerButton from "./modules/MobileHamburgerButton";
import UserMenu from "./modules/UserMenu";
import AdminMobilePanel from "./modules/AdminMobilePanel";
import AdminAppMenu from "./modules/AdminAppMenu";

const AdminAppHeader = () => {
    const [user, setUser] = useState<any>(null);
    const {keycloak, initialized} = useKeycloak();
    const {logout} = keycloak;

    useEffect(() => {
        if (!initialized) {
            return;
        }

        const fetchUserinfo = async () => {
            const result = await keycloak.loadUserInfo();
            setUser(result);
        };

        if (keycloak.authenticated) {
            fetchUserinfo();
        }
    }, [keycloak, initialized]);

    return (
        <Disclosure as="nav" className="bg-gray-800" data-testid="app-header">
            {({open}) => (
                <>
                    <Header>
                        <Box className="flex gap-2">
                            <Brand onlyMark color="white" size="l"/>
                            <h1 className="text-3xl font-bold text-white mr-4">SREDX</h1>
                            <AdminAppMenu/>
                        </Box>
                        <Box className="flex gap-4">
                            <Box className="hidden md:block">
                                <Box className="flex items-center">
                                    {/* Profile dropdown */}
                                    <UserMenu logout={logout}/>
                                </Box>
                            </Box>
                        </Box>
                        {/* Mobile Menu hamburger */}
                        <MobileHamburgerButton open={open}/>
                    </Header>
                    <AdminMobilePanel user={user} logout={logout}/>
                </>
            )}
        </Disclosure>
    );
};

export default AdminAppHeader;