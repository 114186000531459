import {useQuery} from "react-query";
import Overview from "../../models/dashboard/Overview";
import { getOverviewData } from "../../services/overview/Overview.service";
import {useAxios} from "../useAxios";

export const OVERVIEW_QUERY = "overiewData";

export const useOverviewData = () => {
    const {axios} = useAxios();
    return useQuery<Overview, Error>(OVERVIEW_QUERY, () : Promise<Overview> => getOverviewData(axios));
}