import IntegrationTemplate, {IntegrationTemplateChild} from "../../../../../models/integration/IntegrationTemplate";
import React, {FC} from "react";
import {ADMIN_CONFIG_PAGE_PREFIX} from "../../../../../routes/paths";
import clsx from "clsx";
import {Link} from "react-router-dom";

interface IntegrationTemplateMenuItemProps {
    template: IntegrationTemplate | IntegrationTemplateChild,
    parentKey?: string,
    currentTemplateKey: string
}

export const CHILD_SEPARATOR = "~";

const IntegrationTemplateMenuItem: FC<IntegrationTemplateMenuItemProps> = ({template, currentTemplateKey, parentKey}) => {
    let link = ADMIN_CONFIG_PAGE_PREFIX + "/";
    if(parentKey) link += parentKey + CHILD_SEPARATOR;
    link += template.key;
    return (
        <Link
            key={template.key}
            to={link}
            className={clsx(
                template.key === currentTemplateKey
                    ? 'bg-gray-50 border-gray-500 text-gray-700 hover:bg-gray-50 hover:text-gray-700'
                    : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
            )}
            aria-current={template.key === currentTemplateKey ? 'page' : undefined}
        >
            <svg className={clsx(
                template.key === currentTemplateKey
                    ? 'text-gray-500 group-hover:text-gray-500'
                    : 'text-gray-400 group-hover:text-gray-500',
                'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
            )} fill="none" stroke="currentColor" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                      d="M14 5l7 7m0 0l-7 7m7-7H3"/>
            </svg>

            <span className="truncate">{template.name}</span>
        </Link>
    );
}

export default IntegrationTemplateMenuItem;