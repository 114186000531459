import React from "react";
import { generateOwnersRanks } from "../../../utils/generateOwnerRank";
import DataTable from "./DataTable";
import NoDataMessage from "./NoDataMessage";
import OwnerServicesScores from "../../../models/dashborad/OwnerServicesScores";

type TopTeamDashboardProps = {
  ownersData: OwnerServicesScores[];
};

const TopTeamDashboard: React.FC<TopTeamDashboardProps> = ({ ownersData }) => {
  const rankOwners = generateOwnersRanks(ownersData)
    .sort((a, b) => b.points - a.points)
    .slice(0, 6);

  const headerColumns = ["Team", "Total Services", "Score", "Total Score Percentage"];
  const dataRows = rankOwners.map((data) => [
    data.owner || "-",
    data.totalService,
    <span
      className="inline-block rounded-full px-4 text-sm font-semibold bg-blue-200 text-blue-700"
      key={data.owner || "-"}
    >
      {data.points}
    </span>,
    <span
      className="inline-block rounded-full px-4 text-sm font-semibold bg-green-200 text-green-700"
      key={data.owner || "-"}
    >
      {data.scorePercentage}
    </span>,
  ]);

  return (
    <div className="flex flex-col">
      {rankOwners.length > 0 ? (
        <DataTable headerColumns={headerColumns} dataRows={dataRows} />
      ) : (
        <div className="flex justify-center items-center mt-20">
        <NoDataMessage />
      </div>
      )}
    </div>
  );
};

export default TopTeamDashboard;
