interface EvaluationProgressItemProps {
  itemName: string;
  status: 'done' | 'inProgress';
}

const EvaluationProgressItem = ({ itemName, status }: EvaluationProgressItemProps) => {
  const statusClass =
    status === 'done' ? 'bg-green-500' : 'bg-gray-500';

  return (
    <div className="flex justify-between items-center px-2 py-1 hover:bg-gray-100">
      <span>{itemName}</span>
      <span className={`${statusClass} text-white rounded px-2 py-1`}>
        {status === 'done' ? 'Done' : 'In Progress...'}
      </span>
    </div>
  );
};

export default EvaluationProgressItem;