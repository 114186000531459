import { useState } from "react";
import { Renderer } from "../renderer/Renderer";
import { Tooltip } from "../tooltip/Tooltip";
import { ColorLegend } from "../color-legend/ColorLegend";
import emptyCanvasSvg from "../../../assets/svg/blank_canvas.svg";
import * as d3 from "d3";
import { useServicesScores } from "../../../hooks/useServicesScores";
import { HeatmapSekeleton } from "./HeatmapSkeleton";

type HeatmapProps = {
  width: number;
};

export type InteractionData = {
  xLabel: string;
  yLabel: string;
  xPos: number;
  yPos: number;
  value: string;
};

export const Heatmap = ({ width  }: HeatmapProps) => {
  const { isLoading, isSuccess, data } = useServicesScores();
  const [hoveredCell, setHoveredCell] = useState<InteractionData | null>(null);
  const [height, setHeight] = useState<number>(400);


  var colorScale = d3
    .scaleLinear<string>()
    .domain([0, 100])
    .interpolate(d3.interpolateHsl)
    .range(["red", "lightgreen"]);

  let view;
  if (isSuccess) {
    if (!data || data.length === 0) {
      view = (
        <div className="flex flex-col justify-center items-center w-full">
          <img className="w-1/6" src={emptyCanvasSvg} alt="Empty" />
          <p className="text-center text-gray-500 text-2xl">
            No Data available for the moment
          </p>
        </div>
      );
    } else {
      view = (
        <div className="divide-y">
          <div className="ml-6 mb-2 text-lg text-black  md:mt-0 dark:text-white">
            Service per scorecard
          </div>
          <div className="flex flex-col items-center justify-center p-2">
            <div style={{ position: "relative" }}>
              <Renderer
                width={width}
                height={height}
                data={data}
                setHeight={setHeight}
                setHoveredCell={setHoveredCell}
                colorScale={colorScale}
              />
              <Tooltip interactionData={hoveredCell} width={width} height={height} />
            </div>
            <div className="ml-2">
              <ColorLegend
                width={(width * 2) / 3}
                height={80}
                colorScale={colorScale}
              />
            </div>
          </div>
        </div>
      );
    }
  } else if (isLoading) {
    view = <HeatmapSekeleton width={width} />;
  } else {
    view = <p className="text-center text-gray-500">Something went wrong...</p>;
  }

  return <div className="grid grid-cols-1 justify-items-center">{view}</div>;
};
