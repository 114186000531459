import React, {FC} from "react";
import {CalendarIcon, ChartPieIcon} from "@heroicons/react/solid";
import FractionComponent from "../../basic/FractionComponent/FractionComponent";
import ScoreCardOverview from "../../../models/scorecard/ScoreCardOverview";


interface ScoreCardOverviewComponentProps {
    overviewDetails: ScoreCardOverview
}

const ScoreCardOverviewComponent: FC<ScoreCardOverviewComponentProps> = ({overviewDetails}) => {
    return (<>
        <div id="scorecard-overview-container" className={"space-y-4"}>
            <div className="max-w-6xl mx-auto p-4 sm:px-6 lg:px-8">
                <h2 className="text-lg leading-6 font-medium text-gray-900">ScoreCard Overview</h2>
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    {/* Card */}
                    <div id={"scorecard-average-score"}
                         className="bg-white hover:bg-gray-100 overflow-hidden shadow rounded-lg">
                        <div className="h-full p-5">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <ChartPieIcon className="h-6 w-6 text-gray-400" aria-hidden="true"/>
                                </div>
                                <div className="ml-5 w-0 flex-1">
                                    <dl>
                                        <dd>
                                            <div
                                                className="text-lg font-medium text-gray-900">{overviewDetails.averageScore.toFixed(2)}</div>
                                        </dd>
                                        <dt className="text-sm font-medium text-gray-500">Average Score</dt>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id={"scorecard-passing-services"}
                         className="bg-white  hover:bg-gray-100 overflow-hidden shadow rounded-lg">
                        <div className="h-full p-5 mb-0">
                            <FractionComponent lowerNumber={overviewDetails.totalServices}
                                               upperNumber={overviewDetails.passingServices} modelName={"services"}/>
                        </div>
                    </div>
                    <div id={"scorecard-last-evaluation"}
                         className="bg-white hover:bg-gray-100 overflow-hidden shadow rounded-lg">
                        <div className="h-full p-5">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <CalendarIcon className="h-6 w-6 text-gray-400" aria-hidden="true"/>
                                </div>

                                <div className="ml-5 w-0 flex-1">
                                    <dl>
                                        <dd>
                                            <div
                                                className="text-lg font-medium text-gray-900">{
                                                overviewDetails.lastEvaluated.toLocaleString('en-US', {
                                                    day: "2-digit",
                                                    month: 'long',
                                                })
                                                + ", " + overviewDetails.lastEvaluated.toLocaleString('en-US', {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    second: "2-digit",
                                                })
                                            }
                                            </div>
                                        </dd>
                                        <dt className="text-sm font-medium text-gray-500">Last Evaluated</dt>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </>);
}

export default React.memo(ScoreCardOverviewComponent);